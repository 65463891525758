import * as React from 'react';
import { TextField, MaskedTextField } from 'office-ui-fabric-react';
import { substitute } from '../../services/stringsubstitutions';
import { buildClassName, deepCopy } from './formFunctions';
import { decodeDisabled, parseBool } from '../../services/globalMethods';
import { SearchService } from '../../services/classes/SearchService';

export class FormTextBox extends React.Component {
    constructor(props) {
        super(props);

        this.state = { runLoad: false, loadRun: false };
        this.previousValue = null;

        var { filterOn, filterTo, searchKey, filterRequest } = props.field.settings;
        this.searchKey = searchKey; // Used for backend filtering
        if (filterOn) {
            this.isLocalSearch = true;
            this.searchService = new SearchService(props.controller, filterOn, filterTo, filterRequest);
            this.onLocalSearch = (value) => {
                this.searchService.search(value);
            };
        }
    }

    componentDidUpdate(a, b) {
        var { formGenerator } = this.props;

        if (formGenerator.props.eventType === 'ALL_DATA_LOADED') {
            if (this.state.runLoad && !this.state.loadRun) {
                this.setState({ runLoad: true, loadRun: true });
            } else if (!this.state.runLoad) {
                this.setState({ runLoad: true, loadRun: false });
            }
        }

        if (this.isLocalSearch) {
            var fd = formGenerator.getFormData();
            var val = fd[this.props.field.property];

            var { filterOn, filterTo } = this.props.field.settings;
            // TODO: fix this hack to force refresh of filtered items
            if (formGenerator.props.eventType === 'HIDE_MODAL_SPINNER') {
                this.searchService = new SearchService(this.props.controller, filterOn, filterTo);
            }

            this.searchService.search(val);
        }
    }
    render() {
        var { field, additional, formGenerator, collectionStack, controller } = this.props;

        var isLoading = false;

        var { onChange, decode, validation } = additional;

        var self = this;
        var formData = formGenerator.getFormData();

        var copyStack = deepCopy(collectionStack);
        copyStack.reverse();

        var defaultValue = field.defaultValue || '';
        if (defaultValue != null) defaultValue = substitute(defaultValue, controller, [formData, ...copyStack]);

        field.property = substitute(field.property, controller, [formData, ...copyStack]);
        const header = substitute(field.header, controller, [formData, ...copyStack]);
        if (field.onChange) {
            field.onChange.forEach((item) => {
                if (item.settings) {
                    Object.keys(item.settings).forEach((key) => {
                        if (item.settings[key] == null) return;

                        item.settings[key] = substitute(item.settings[key], controller, copyStack);
                    });
                }
            });
        }

        var onBlur = (val) => {
            if (formData[field.property] == null) {
                onChange(field, '', copyStack);
            }
        };

        var onChangeWithSearch = (value) => {
            if (this.isLocalSearch) {
                this.searchService.search(value);
            }

            onChange(field, value, copyStack);
        };
        var onKeyPress = (val) => {
            var keyPressed = {
                charCode: val.charCode,
                altKey: val.altKey,
                ctrlKey: val.ctrlKey,
            };

            if (this.searchKey && keyPressed.charCode === 13) {
                var searchText = getValue();
                formGenerator.updateSearchResults(this.searchKey, { search: searchText });
            }

            if (field.onKeyPress == null) return;

            var formData = { ...formGenerator.getFormData() };
            formGenerator.runActions(field, field.onKeyPress, { keyPressed }, formData, copyStack);
        };

        var getValue = (isLoading) => {
            var fd = formGenerator.getFormData();
            var val = fd[field.property];
            if (val != null) {
                val = val.toString();
            }

            if (!isLoading && field.onUpdate && val != self.previousValue) {
                formGenerator.runActions(field, field.onUpdate, {}, fd, copyStack);
                self.previousValue = val;
            }

            if (isLoading) self.previousValue = val;

            if (val == null && field.defaultValue != null) {
                var defaultVal = substitute(defaultValue, controller, [formData, ...copyStack]);
                if (defaultVal !== '') {
                    val = defaultVal;
                    fd[field.property] = val;
                }
            }
            return val;
        };

        if (!this.state.loadRun && this.state.runLoad && field.onLoad != null) {
            var val = getValue() || defaultValue;
            var copyField = deepCopy(field);
            copyField.onChange = field.onLoad;
            onChange(copyField, val, copyStack);

            isLoading = true;
            if (field.settings && field.settings.onChangeDebounce) {
                formGenerator.onChange(field, val, formGenerator.getFormData(), copyStack);
            }
        }

        var rootStyle = { width: '100%', ...field.styles };

        if (field.visible != null) {
            var result = decodeDisabled(
                field,
                field.visible,
                this.props.controller,
                this.props.controller.props.dataStore,
                formData
            );

            if (!result) {
                return <></>;
            }
        }

        if (field.settings.mask != null) {
            var maskFormat = {
                9: /[0-9]/,
                a: /[a-zA-Z]/,
                '*': /[a-zA-Z0-9]/,
            };

            return (
                <MaskedTextField
                    id={field.id || field.property}
                    className={buildClassName(
                        formGenerator,
                        field,
                        'form-textbox form-maskedtextbox',
                        field.class,
                        collectionStack
                    )}
                    styles={{
                        root: rootStyle,
                        // fieldGroup: [
                        //     {
                        //         borderColor: themeColor(theme)('textFieldBorder'),
                        //     },
                        // ],
                    }}
                    label={header}
                    prefix={field.settings.prefix}
                    disabled={decode(field, field.disabled)}
                    type={field.subType != null ? field.subType : null}
                    onBlur={(e) => onBlur(e.target.value)}
                    multiline={false}
                    mask={field.settings.mask}
                    maskFormat={maskFormat}
                    maskChar={field.settings.maskChar || ''}
                    onGetErrorMessage={validation}
                    onKeyPress={(e) => onKeyPress(e)}
                    required={parseBool(field.required)}
                    readOnly={parseBool(field.readonly)}
                    deferredValidationTime={field.settings.deferredValidationTime || 750}
                    validateOnFocusOut={field.settings.validateOnFocusOut || false}
                    validateOnFocusIn={field.settings.validateOnFocusIn}
                    placeholder={substitute(field.placeholder, controller, [formData, field.settings])}
                    value={getValue(isLoading) || defaultValue}
                    onChange={(e, val) => {
                        onChangeWithSearch(val);

                        // if (field.settings && field.settings.onChangeDebounce) {
                        //     var timeout = parseInt(field.settings.onChangeDebounce);
                        //     this.debounce(timeout, () =>
                        //         formGenerator.onChange(field, val, formGenerator.getFormData(), copyStack)
                        //     )();
                        // }
                    }}
                    autocomplete="off"
                />
            );
        }

        return (
            <TextField
                id={field.id}
                className={buildClassName(formGenerator, field, 'form-textbox', field.class, collectionStack)}
                styles={{ root: rootStyle }}
                label={header}
                prefix={field.settings.prefix}
                suffix={field.settings.suffix}
                disabled={decode(field, field.disabled)}
                type={field.settings.subType != null ? field.settings.subType : null}
                onBlur={(e) => onBlur(e.target.value)}
                multiline={false}
                onGetErrorMessage={validation}
                required={parseBool(field.required)}
                readOnly={parseBool(field.readonly)}
                // canRevealPassword={parseBool(field.settings.canRevealPassword)} //not working
                onKeyPress={(e) => {
                    onKeyPress(e);
                }}
                deferredValidationTime={field.settings.deferredValidationTime || 750}
                validateOnFocusOut={field.settings.validateOnFocusOut || false}
                validateOnFocusIn={field.settings.validateOnFocusIn}
                placeholder={substitute(field.placeholder, controller, [formData, field.settings])}
                value={getValue(isLoading)}
                {...field.attributes}
                onChange={(e, val) => {
                    onChangeWithSearch(val);

                    // if (field.settings && field.settings.onChangeDebounce) {
                    //     var timeout = parseInt(field.settings.onChangeDebounce);
                    //     this.debounce(timeout, () =>
                    //         formGenerator.onChange(field, val, formGenerator.getFormData(), copyStack)
                    //     )();
                    // }
                }}
                //     autocomplete="off"
            />
        );
    }

    // debounce = function (timeout, func) {
    //     return () => {
    //         clearTimeout(this.timer);
    //         this.timer = setTimeout(() => {
    //             func();
    //         }, timeout);
    //     };
    // };
}
