import React from 'react';
import { FilePond } from 'react-filepond';
import 'filepond/dist/filepond.min.css';
import { buildClassName } from './formFunctions';

export const FormFileUploader = ({ field, additional, formGenerator, collectionStack }) => {
    var { onChange } = additional || {};

    var settings = field.settings || {};
    var maxFiles = settings.maxFiles || 1;

    return (
        <FilePond
            allowMultiple={maxFiles > 1}
            maxFiles={maxFiles}
            id={field.id}
            className={buildClassName(formGenerator, field, 'form-fileuploader', field.class, collectionStack)}
            style={field.styles}
            files={formGenerator.state.files}
            onupdatefiles={(fileItems) => {
                formGenerator.setState({ ...formGenerator.state, files: fileItems });
                onChange(field, fileItems);
            }}
        />
    );
};
