class Json2Yaml {
    getType(obj) {
        var type = typeof obj;
        if (obj instanceof Array) {
            return 'array';
        } else if (type == 'string') {
            return 'string';
        } else if (type == 'boolean') {
            return 'boolean';
        } else if (type == 'number') {
            return 'number';
        } else if (type == 'undefined' || obj === null) {
            return 'null';
        } else {
            return 'hash';
        }
    }

    convert(obj, ret) {
        var type = this.getType(obj);

        switch (type) {
            case 'array':
                this.convertArray(obj, ret);
                break;
            case 'hash':
                this.convertHash(obj, ret);
                break;
            case 'string':
                this.convertString(obj, ret);
                break;
            case 'null':
                ret.push('null');
                break;
            case 'number':
                ret.push(obj.toString());
                break;
            case 'boolean':
                ret.push(obj ? 'true' : 'false');
                break;
            default:
                break;
        }
    }

    convertArray(obj, ret) {
        var spacing = '  ';

        if (obj.length === 0) {
            ret.push('[]');
        }
        for (var i = 0; i < obj.length; i++) {
            var ele = obj[i];
            var recurse = [];
            this.convert(ele, recurse);

            for (var j = 0; j < recurse.length; j++) {
                ret.push((j == 0 ? '- ' : spacing) + recurse[j]);
            }
        }
    }

    convertHash(obj, ret) {
        var spacing = '  ';

        for (var k in obj) {
            var recurse = [];
            if (obj.hasOwnProperty(k)) {
                var ele = obj[k];
                this.convert(ele, recurse);
                var type = this.getType(ele);
                if (type == 'string' || type == 'null' || type === 'number' || type === 'boolean') {
                    ret.push(this.normalizeString(k) + ': ' + recurse[0]);
                } else {
                    ret.push(this.normalizeString(k) + ': ');
                    for (var i = 0; i < recurse.length; i++) {
                        ret.push(spacing + recurse[i]);
                    }
                }
            }
        }
    }

    normalizeString(str) {
        if (str.match(/^[\w]+$/)) {
            return str;
        } else {
            return '"' + escape(str).replace(/%u/g, '\\u').replace(/%U/g, '\\U').replace(/%/g, '\\x') + '"';
        }
    }

    convertString(obj, ret) {
        ret.push(this.normalizeString(obj));
    }

    convertJson(obj) {
        if (typeof obj == 'string') {
            obj = JSON.parse(obj);
        }

        var ret = [];
        this.convert(obj, ret);
        return ret.join('\n');
    }
}

export default Json2Yaml;
