import React from 'react';
import { NavLink, withRouter } from 'react-router-dom';
import { substituteImage, substitute } from '../../services/stringsubstitutions';
import { pageChanged, renderPage, setSelectedItems } from '../../redux/actions';
import queryString from 'query-string';
import { connect } from 'react-redux';
import _ from 'lodash';
import { ColorClassNames } from '@uifabric/styling';
import { deepCopy } from '../forms/formFunctions';

class HubNavigationLink extends React.Component {
    // eslint-disable-next-line no-useless-constructor
    constructor(props) {
        super(props);
    }

    replaceParams(obj, str, substitutionStack) {
        var queries = queryString.parse(this.props.location.search);
        var retVal = substitute(str, this.props.controller, [
            ...substitutionStack,
            this.props.controller.page.area,
            obj,
            queries,
        ]);
        return retVal;
    }

    render() {
        var self = this;
        var { controller, config, layoutItem, collectionStack, settings } = this.props;

        if (settings && settings.debugger) debugger;

        var substitutionStack = deepCopy(collectionStack);
        substitutionStack.reverse();

        var item = {};
        if (layoutItem.key) {
            item = typeof layoutItem.key === 'string' ? controller.props.dataStore.get(layoutItem.key) : layoutItem.key;
        } else {
            item = substitutionStack[0];
        }

        const url = '/' + this.replaceParams(item, item.path, substitutionStack);
        const isCurrentPage = url === '/' ? window.location.pathname === '/' : window.location.href.includes(url);

        var func = null;
        if (item.request != null) {
            func = () => {
                self.props.controller.makeRequest(item, false, {}, (data) => {
                    var originalItem = Object.assign(item, data);
                    self.props.dispatch(setSelectedItems([originalItem]));
                    self.props.dispatch(renderPage());
                });
            };
        } else if (item.methodName != null) {
            func = () => self.props.page[item.methodName](self.props.controller, substitutionStack[1], item);
        }

        var icon = item.icon ? (
            <img
                style={{ width: '24px', height: '24px', marginTop: '-3px' }}
                src={substituteImage(item.icon, this.props.controller.props.areas.portal)}
                alt={item.alt}
            />
        ) : (
            ''
        );

        var styles = item.icon && item.label ? { paddingLeft: '10px' } : {};

        return (
            <span
                id={layoutItem.id}
                key={item.label}
                title={item.label}
                className={`${isCurrentPage ? 'navigation-link active' : 'navigation-link'}`}>
                {item.methodName == null ? (
                    <NavLink onClick={() => this.props.dispatch(pageChanged(url))} to={url}>
                        {icon}
                        <span className={ColorClassNames.themePrimary + '  ' + item.class} style={styles}>
                            {item.label}{' '}
                        </span>
                    </NavLink>
                ) : (
                    <a onClick={func} href="#">
                        <span className={ColorClassNames.themePrimary + ' ' + item.class}>{item.label}</span>
                    </a>
                )}
            </span>
        );
    }
}

const mapStateToProps = (state) => ({
    event: state.event,
    accessPolicies: state.accessPolicies,
    area: state.area,
});

export default withRouter(connect(mapStateToProps, null)(HubNavigationLink));
