import * as React from 'react';
import styled from 'styled-components';
import { addFormEvent } from '../../services/globalMethods';
import { buildClassName, buildHeader, deepCopy } from './formFunctions';
import { substitute, substituteImage } from '../../services/stringsubstitutions';

export const FormLink = ({ formGenerator, field, additional, collectionStack }) => {
    const { url, value } = field.settings;
    // var { header } = field;
    // if (text != null) header = text;

    var val = field.value;
    if (value != null) val = value;

    if (val === 'null') val = null;

    var stack = collectionStack || [];
    var substitutionStack = deepCopy(stack);
    substitutionStack.reverse();

    const onClick = () => {
        // TODO Add collection stack yo all on click events everywhere
        var formData = { ...formGenerator.getFormData() }; //, __collectionStack: stack };
        if (field.onClick) {
            var copyStack = deepCopy(collectionStack);
            copyStack.reverse();

            formGenerator.runActions(field, field.onClick, val, formData, copyStack);

            formGenerator.updateFormData(formData, () => {
                formData['formIsValid'] = formGenerator.validateForm(formData);
                formGenerator.updateFormData(formData);
            });

            // formGenerator.setState({ ...formGenerator.state, formData }, () => {
            //     formData['formIsValid'] = formGenerator.validateForm(formData);
            //     formGenerator.setState({ formData });
            // });
            return;
        }

        formData[field.property] = val;

        var eventData = { property: field.property, type: field.type, value: val };
        addFormEvent(formGenerator.state.form, formData, 'setFormData', eventData, field);

        if (field.onChange != null) {
            formGenerator.onChange(field, val, formData);
        }

        formGenerator.updateFormData(formData, () => {
            formData['formIsValid'] = formGenerator.validateForm(formData);
            formGenerator.updateFormData(formData);
        });

        // formGenerator.setState({ ...formGenerator.state, formData }, () => {
        //     formData['formIsValid'] = formGenerator.validateForm(formData);
        //     formGenerator.setState({ formData });
        // });
    };

    var styles = {
        cursor: 'pointer',
        textDecoration: 'underline',
        fontSize: '15px',
        width: '100%',
        ...field.styles,
    };

    substitutionStack.reverse();
    var substitutions = [...substitutionStack, field, field.settings];
    var contents = buildHeader(formGenerator, field, collectionStack, substitutions);

    // header = substitute(header, formGenerator.props.controller, [...[formGenerator.getFormData()], ...substitutionStack]);

    // if (header != null && header.length > 0 && header[0] === '_') header = null;
    // var settings = field.settings || {};

    // var prefix;
    // if (settings.prefix)
    //     prefix =
    //         substitute(settings.prefix, formGenerator.props.controller, [
    //             ...[formGenerator.getFormData()],
    //             ...substitutionStack,
    //         ]) + ' ';

    // var suffix;
    // if (settings.suffix)
    //     suffix =
    //         ' ' +
    //         substitute(settings.suffix, formGenerator.props.controller, [
    //             ...[formGenerator.getFormData()],
    //             ...substitutionStack,
    //         ]);

    if (url) {
        var subUrl = substitute(url, formGenerator.props.controller, [...[formGenerator.getFormData()], ...substitutionStack]);
        return (
            <Anchor
                style={{ styles }}
                href={subUrl}
                className={buildClassName(formGenerator, field, 'form-link', field.class, collectionStack)}>
                {contents}
            </Anchor>
        );
    }

    // if (icon && header) {
    //     var src = substituteImage(icon, additional.controller.props.areas.portal);
    //     var image = (
    //         <img
    //             alt={field.settings.alt}
    //             src={src}
    //             width="16"
    //             height="16"
    //             style={{ ...field.styles, ...{ marginTop: '-2px', marginRight: '8px' } }}
    //         />
    //     );

    //     return (
    //         <ButtonLink
    //             id={field.id}
    //             styles={field.styles}
    //             className={buildClassName(formGenerator, field, 'form-link', field.class, collectionStack)}
    //             onClick={onClick}>
    //             <>
    //                 {image}
    //                 {text}
    //             </>
    //         </ButtonLink>
    //     );
    // }

    // if (icon) {
    //     var src1 = substituteImage(icon, additional.controller.props.areas.portal);
    //     var image2 = (
    //         <img
    //             alt={field.settings.alt}
    //             src={src1}
    //             width="16"
    //             height="16"
    //             style={{ ...field.styles, ...{ marginTop: '-2px', marginRight: '8px' } }}
    //         />
    //     );

    //     return (
    //         <span
    //             id={field.id}
    //             styles={field.styles}
    //             className={buildClassName(formGenerator, field, 'form-link', field.class, collectionStack)}
    //             onClick={onClick}>
    //             {image2}
    //         </span>
    //     );
    // }

    return (
        <span
            className={buildClassName(formGenerator, field, 'form-link', field.class, collectionStack)}
            style={field.styles}
            onClick={onClick}>
            {/* {prefix ? <span class="prefix">{settings.prefix}</span> : null} */}
            <span id={field.id}>{contents}</span>
            {/* {suffix ? <span class="suffix">{settings.suffix}</span> : null} */}
        </span>
    );
};

const Anchor = styled.a`
    cursor: pointer;
    text-decoration: underline;
`;

// font-size: 15px;
const ButtonLink = styled.span`
    cursor: pointer;
    // text-decoration: underline;
    width: 100%;
`;
