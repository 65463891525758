// import { environments_applications } from './environments-applications';
// import { environments_hosts } from './environments-hosts';
import { Diagnostics, LOGGING_LEVEL } from '../../services/services';
import { PageBase, AreaBase } from './page-base';

class default_page extends PageBase {
    constructor(name, config, area, controller) {
        super(name, config, area, controller);
    }
}

class defaultArea extends AreaBase {
    constructor(areaInfo, info, state) {
        super(areaInfo, info, state);
    }
}

(function (window) {
    // You can enable the strict mode commenting the following line
    // 'use strict';

    // This function will contain all our code
    function bluePearEnvironments() {
        var _myLibraryObject = {};

        _myLibraryObject.buildArea = function (areaName, config, info, dataStore) {
            Diagnostics.log(LOGGING_LEVEL.DEBUG, `Factory using default area: Area: ${areaName}`);

            return new defaultArea(config, info, dataStore);
        };

        _myLibraryObject.buildPage = function (name, config, area, controller) {
            // if (name === 'environments_applications') return new environments_applications(name, config, area, controller);

            // if (name === 'environments_hosts') return new environments_hosts(name, config, area, controller);

            Diagnostics.log(`Factory using default page: Page: ${name}`);
            return new default_page(name, config, area, controller);
        };

        return _myLibraryObject;
    }

    // We need that our library is globally accesible, then we save in the window
    if (typeof window.factory === 'undefined') {
        window.factory = bluePearEnvironments();
    }
})(window); // We send the window variable withing our function
