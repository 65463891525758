import React from 'react';

export const ContextPanel = (props) => {
    var { form, contextPanel, closeContextPanel } = props;

    var { isOpen, open, title, description, html, settings } = contextPanel || {};
    settings = settings || {};
    var id = settings ? settings.id : null;
    const position = settings ? settings.position : 'right';

    if (isOpen || open) {
        return (
            <div class="context-panel-container">
                <div
                    id={id}
                    className={`context-panel context-panel-visible dialog ${position} ${contextPanel.class}`}
                    style={contextPanel.styles}>
                    <span className="close" onClick={closeContextPanel}>
                        X
                    </span>
                    <div class="flex-column">
                        {title ? (
                            <div className="context-panel-header  dialog-header">
                                <div className="context-panel-title dialog-title">{title}</div>
                                {description ? (
                                    <div className="context-panel-subTitle dialog-description">{description}</div>
                                ) : null}
                            </div>
                        ) : null}
                        <div className="context-panel-body  dialog-body">{html}</div>
                    </div>
                </div>
            </div>
        );
    }
    return null;
};
