import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { fileIconSelect } from '../FileIconSelect';
import { decodeDisabled, parseBool } from '../../services/globalMethods';
import { BpSpinner } from '../BpSpinner';
import { deepCopy } from '../forms/formFunctions';
import { substitute } from '../../services/stringsubstitutions';

const FileIcon = (props) => {
    const { file } = props;
    return (
        <span className={`hub-file-explorer-file-icon file-icon-${file}`}>
            {fileIconSelect(file)}
        </span>
    )
}

const CollapsibleSection = ({ children, open }) => {
    return (
        <div className={`hub-file-explorer-collapsable ${open ? 'is-open' : ''}`} open={open}>
            <div className="hub-file-explorer__inner">{children}</div>
        </div>
    );
};


const PopupMenu = ({ x, y, onClose, onAction, disabledItems,popupRef }) => {
    const isDisabled = (action) => disabledItems.includes(action);
    return (
        <div className="popup-menu" style={{ top: y, left: x }} ref={popupRef}>
            <ul>
                <li className={isDisabled('newFile') ? 'disabled' : ''} onClick={() => !isDisabled('newFile') && onAction('newFile')}>New File</li>
                <li className={isDisabled('newFolder') ? 'disabled' : ''} onClick={() => !isDisabled('newFolder') && onAction('newFolder')}>New Folder</li>
                <li className="separator"></li>
                <li className={isDisabled('uploadFiles') ? 'disabled' : ''} onClick={() => !isDisabled('uploadFiles') && onAction('uploadFiles')}>Upload Files</li>
                {/* <li className={isDisabled('findInFolder') ? 'disabled' : ''} onClick={() => !isDisabled('findInFolder') && onAction('findInFolder')}>Find in Folder</li> */}
                <li className="separator"></li>
                <li className={isDisabled('copy') ? 'disabled' : ''} onClick={() => !isDisabled('copy') && onAction('copy')}>Copy<span className="shortcut">Ctrl+C</span></li>
                <li className={isDisabled('paste') ? 'disabled' : ''} onClick={() => !isDisabled('paste') && onAction('paste')}>Paste<span className="shortcut">Ctrl+V</span></li>
                <li className="separator"></li>
                <li className={isDisabled('rename') ? 'disabled' : ''} onClick={() => !isDisabled('rename') && onAction('rename')}>Rename / Move</li>
                <li className={isDisabled('delete') ? 'disabled' : ''} onClick={() => !isDisabled('delete') && onAction('delete')}>Delete</li>
                {/* <li className={isDisabled('rename') ? 'disabled' : ''} onClick={() => !isDisabled('rename') && onAction('rename')}>Rename / Move <span className="shortcut">F2</span></li>
                <li className={isDisabled('delete') ? 'disabled' : ''} onClick={() => !isDisabled('delete') && onAction('delete')}>Delete <span className="shortcut">Delete</span></li> */}
            </ul>
        </div>
    );
};
const ItemList = ({ attributes, parentItem, items, selectedId, setSelectedId, HandleOnEvent, main, props, showPopup,updateDisabledItems }) => {

    const handleClick = (index) => {
        // props.dataStore.get("selectedItem")
        setSelectedId(index === selectedId ? null : index);
    }
  
    const handleRightClick = (event, item) => {
        event.preventDefault();

        if(parseBool(item?.attributes?.showPopupMenu) === false ||
           parseBool(attributes?.showPopupMenu) === false) {
            return;
        }
        
        if(item?.path == null || !item.path.startsWith('/'))
          return;

        if(item.title === "appsettings.json"|| 
            item.title === "service.yaml"|| 
            item.title === "site-config.json"){
            updateDisabledItems('rename', true);
            updateDisabledItems('delete', true);
        }
        else{
            updateDisabledItems('rename', false);
            updateDisabledItems('delete', false);
        }
        updateDisabledItems('copy', true);
        updateDisabledItems('paste', true);
        showPopup(event.pageX, event.pageY, item);
        event.stopPropagation(); 
    };

    if(typeof items === 'string') {
        var copyStack = deepCopy(props.collectionStack);
        copyStack.reverse();        
        items = substitute(items, props.controller,copyStack ) ?? [ ];
    }

    var sortItems = true;
    if(parentItem?.attributes?.sortItems){
        sortItems = parseBool(parentItem?.attributes?.sortItems, true);
    }

    const itemListElements = [];
    var sortedItems = sortItems === false
        ? items // Do not sort items
        : items.sort((a, b) => { 
            if (a.items && !b.items) return -1;
            if (!a.items && b.items) return 1;
            return a.title.localeCompare(b.title);
        });     

    if(main && props.layoutItem.settings.prefixItems){  
        sortedItems = [...props.layoutItem.settings.prefixItems, ...sortedItems]
     }     

    sortedItems.forEach((item, index) => {
        const { title, path, icon,parentItem, items: nestedItems } = item;

        if(title === '.ignore') return; // Ignore items with title '.ignore'

        if (item.visible != null) {
            var copyStack = deepCopy(props.collectionStack);
            copyStack.reverse();                    
            var result = decodeDisabled(
                item,
                item.visible,
                props.controller,
                props.controller.props.dataStore,
                props.formGenerator.getFormData(),
                [item, ...copyStack]
            );

            if (!result) return
        }
        var onClick = null;
        if(item.onClick != null) {
            const myOnClick = item.onClick; 
            onClick =  (p1,p2) => {
                

                var formData = { ...props.formGenerator.getFormData() }; 
        
                var copyStack = deepCopy(props.collectionStack);
                copyStack.push(props.layoutItem.settings || {});  
                copyStack.reverse();
                props.formGenerator.runActions(props.layoutItem, myOnClick, p2 ?? p1, formData, copyStack);
            }
        }

        const generateGUID = () => {
            return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
                const r = (Math.random() * 16) | 0;
                const v = c === 'x' ? r : (r & 0x3) | 0x8;
                return v.toString(16);
            });
        };
        if(main){

            attributes = deepCopy(item.attributes ?? { });
        }
        else{
            attributes = {...attributes, ...item?.attributes};
        }

        item.id = item.id ?? generateGUID();
        itemListElements.push(
            <li key={`${item.id}`} onContextMenu={(e) => handleRightClick(e, item) }> 
                {nestedItems ? (
                    <FileExplorerSection
                        key={`${item.path}-${index}`}
                        title={title}
                        parentItem={item}
                        attributes={attributes}
                        items={nestedItems}
                        icon={icon}
                        props={props}
                        selectedId={selectedId}
                        setSelectedId={setSelectedId}
                        HandleOnEvent={onClick ?? HandleOnEvent}
                        showPopup={showPopup}
                        updateDisabledItems={updateDisabledItems}
                    />
                ) : item.header != null ? <div className= "file-explorer-header">{item.header}</div> :(
                    <a
                        href={path}
                        title={title}
                        className={(`${item.path ?? title}-index` === selectedId ? 'active' : 'inactive')}
                        onClick={(e) => {
                            e.preventDefault();
                            handleClick(`${item.path ?? title}-index`);
                            e.target.setAttribute("data-item", item);
                            onClick ? onClick() : HandleOnEvent(props.layoutItem.events.onClick, item);
                        }}
                    >
                        <FileIcon file={icon} /> {title}
                    </a>
                )}
            </li>
        );
    });

    return (
        <ul className="hub-file-explorer__itemlist">
            {itemListElements}
        </ul>
    );
}
const FileExplorerSection = ({ title, attributes, parentItem, items, icon, selectedId, setSelectedId, HandleOnEvent, props, showPopup,updateDisabledItems }) => {

    const [show, setShow] = useState(false);

    const handleItemOnClick = () => {
        setShow(!show)
    };

    return (
        <div className="hub-file-explorer__section" >
            <button onClick={handleItemOnClick} className={`${show ? 'isActive' : ''}`}>
                <span className="folder-caret">&gt;</span>
                <FileIcon file={icon} /> {title}
            </button>
            <CollapsibleSection open={show}>
                <ItemList 
                    items={items} 
                    attributes={attributes}
                    parentItem={parentItem}
                    props={props}
                    selectedId={selectedId} 
                    setSelectedId={setSelectedId} 
                    HandleOnEvent={HandleOnEvent} 
                    showPopup={showPopup}
                    updateDisabledItems={updateDisabledItems}
                />
            </CollapsibleSection>
        </div>
    );
}

const HubFileExplorer = (props) => {

    var { controller, layoutItem, collectionStack, formGenerator } = props;
    const [currentContents, setCurrentContents] = useState(null);
    const [selectedId, setSelectedId] = useState(null);
    const [popup, setPopup] = useState({ visible: false, x: 0, y: 0, item: null });
    const [disabledItems, setDisabledItems] = useState(['popupMenuItems']);

    const popupRef = useRef(null);
    const fileInputRef = useRef(null);

    const HandleOnEvent = (onEvent, item) => {
        var formData = { ...formGenerator.getFormData() }; 

        var copyStack = deepCopy(collectionStack);
        copyStack.push(layoutItem.settings || {});  
        copyStack.reverse();
        formGenerator.runActions(layoutItem, onEvent, item, formData, copyStack);
    }    

    const showPopup = (x, y, item) => {
        if(parseBool(item?.attributes?.showPopupMenu) === false) {
            closePopup();
            return
        };

        const popupWidth = 150; // Approximate width of popup
        const popupHeight = 120; // Approximate height of popup
        const screenWidth = window.innerWidth;
        const screenHeight = window.innerHeight;
    
        // Adjust x position if popup goes off-screen to the right
        const adjustedX = (x + popupWidth > screenWidth) ? screenWidth - popupWidth : x;
    
        // Adjust y position if popup goes off-screen to the bottom
        const adjustedY = (y + popupHeight > screenHeight) ? screenHeight - popupHeight : y;
    
        setPopup({ visible: true, x: adjustedX - 250, y: adjustedY - 40, item });
    };

    const updateDisabledItems = (action, disable) => {
        setDisabledItems((prevDisabledItems) => {
            if (disable) {
                return [...prevDisabledItems, action];
            } else {
                return prevDisabledItems.filter(item => item !== action);
            }
        });
    };    

    const closePopup = () => {
        setPopup({ ...popup, visible: false });
    };

    const handleAction = (action) => {
        const { item } = popup; // The item on which the action is being performed
        let updatedItems = [...currentContents.items]; // Work with a copy of the current items array
        let copyItem = null; // Variable for storing copied items
    
        const findItemIndex = (items, target) => {
            return items.findIndex(i => i.title === target.title && i.path === target.path);
        };
    
        const removeItemRecursively = (items, target) => {
            return items.filter(i => {
                if (i === target) return false;
                if (i.items) i.items = removeItemRecursively(i.items, target); // Recursive removal in nested items
                return true;
            });
        };
    
        const addNewItemAtFolderLevel = (items, targetFolder, newItem) => {
            // Recursive function to add item at the specified folder level
            return items.map(i => {
                if (i === targetFolder && i.items !== undefined) {
                    // Add new item to the target folder's items array
                    return { ...i, items: [...(i.items || []), newItem] };
                } else if (i.items) {
                    // If there are nested items, recursively search within them
                    return { ...i, items: addNewItemAtFolderLevel(i.items, targetFolder, newItem) };
                }
                return i;
            });
        };
    
        const path = item.items != null ? item.path : item.path.substring(0, item.path.lastIndexOf('/'));
        switch (action) {
            case 'newFile':
            case 'newFolder':
                var newItemPath = item.items == null
                    ? item.path.replace(item.title, '')
                    : item.path;
                
                    if(!newItemPath.endsWith('/')) 
                        newItemPath += '/';

                const newItemName = prompt("Enter new name:",  newItemPath + (action === 'newFile' ? "NewFile.yaml" : "NewFolder"));
                if(newItemName){
                // const newItem = {
                //     path:  newItemName,
                //     icon: action === 'newFile' ? "yaml" : "folder",
                //     items: action === 'newFolder' ? [] : null // Only folders have items array
                // };


                var value = {
                    path:  newItemName,
                    type: action === 'newFile' ? 'file' : 'folder',
                };
                HandleOnEvent(layoutItem?.events?.onNew, value);

    
                // // Add new item at the selected folder level
                // if (item && item.items !== undefined) {
                //     // Add within the selected folder if it has an `items` array (indicating it's a folder)
                //     updatedItems = addNewItemAtFolderLevel(updatedItems, item, newItem);
                // } else {
                //     // If no folder is selected, add at the root level
                //     updatedItems.push(newItem);
                // }
            }
                break;
    
            case 'delete':
                var msg = item.items == null
                    ? `Are you sure you want to delete '${item.title}'?`
                    : `Are you sure you want to delete the folder '${item.path}' and all it's children?`
                const isConfirmed = window.confirm(msg);
                if (isConfirmed) {
                    
                    var deleteItem = {
                        path: item.path,
                        type:  item.items == null ? 'file' : 'folder',
                    };
                    
                    HandleOnEvent(layoutItem?.events?.onDelete, deleteItem);
                    updatedItems = removeItemRecursively(updatedItems, item); // Recursively remove item
                }
                break;
            case 'uploadFiles':
                fileInputRef.current.setAttribute("data-path", path);
                fileInputRef.current.click(path); // Trigger file input click event
                break;
    
            case 'copy':
                copyItem = deepCopy(item); // Deep copy of the selected item to keep nested structure
                break;
    
            case 'paste':
                if (copyItem) {
                    const pastedItem = deepCopy(copyItem);
                    if (item && item.items !== undefined) {
                        // Paste within the selected folder if it's a folder
                        updatedItems = addNewItemAtFolderLevel(updatedItems, item, pastedItem);
                    } else {
                        // If no folder is selected, paste at the root level
                        updatedItems.push(pastedItem);
                    }
                    copyItem = null; // Clear the copied item after pasting
                }
                break;
    
            case 'rename':
                let newName = prompt("Enter new path/name:", item.path);
                if (newName) {
                    if (!newName.startsWith('/')) {
                        newName =  '/' + newName;
                    }
                    var renameItem = {
                        newPath: newName,
                        path: path + (item.items != null ? '' : '/' + item.title), 
                        type:  item.items == null ? 'file' : 'folder',
                    };

                    HandleOnEvent(layoutItem?.events?.onRename, renameItem);

                    const itemIndex = findItemIndex(updatedItems, item);
                    if (itemIndex > -1) updatedItems[itemIndex].title = newName;
                }
                break;
    
            default:
                console.log(`Unhandled action: ${action}`);
                break;
        }
    
        setCurrentContents({ ...currentContents, items: updatedItems }); // Update the state with the modified items array
        closePopup();
    };
    

    useEffect(() => {
        const fetchData = async () => {
            try {
                var data = typeof layoutItem.key === 'string' 
                    ? await controller.props.dataStore.get(layoutItem.key)
                    : layoutItem.key;
  
                if (data) {
                    setCurrentContents(data);
                }
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };
        fetchData();
    }, [controller.props.dataStore, layoutItem.originalReplacements.DATASTORE]);

    useEffect(() => {
        const handleKeyDown = (event) => {
            if (event.key === 'Escape') { closePopup(); return;}
            debugger;
        };

        const handleClickOutside = (event) => {
             if (popupRef.current && !popupRef.current.contains(event.target)) {
                closePopup();
             }
        };

        if (popup.visible) {
            document.addEventListener('keydown', handleKeyDown);
            document.addEventListener('mousedown', handleClickOutside);
        } else {
            document.removeEventListener('keydown', handleKeyDown);
            document.removeEventListener('mousedown', handleClickOutside);
        }

        return () => {
            document.removeEventListener('keydown', handleKeyDown);
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [popup.visible]);

    const handleFileChange = (event) => {
        const path = event.target.getAttribute("data-path");
        const files = event.target.files;
        if (files.length) {
            // Create a FormData object
            const formData = new FormData();
            for (let i = 0; i < files.length; i++) {
                formData.append("files", files[i]); // append each file with a key
            }

            var variantFormData = { ...formGenerator.getFormData() }; 

            var valItemm = {
                path,
                formData
            };
            
            var copyStack = deepCopy(collectionStack);
            copyStack.push(layoutItem.settings || {});  
            copyStack.reverse();
            formGenerator.runActions(layoutItem, layoutItem.events.onFileUpload, valItemm, variantFormData, copyStack);
        }
      };
      
    return (
        <div className={`hub-file-explorer tooltip-container ${layoutItem.class || ''}`}>
            {currentContents != null && currentContents.items != null ? (
                <div className="hub-file-explorer__section" >
                <input
                    type="file"
                    ref={fileInputRef}
                    onChange={handleFileChange}
                    style={{ display: "none" }} // Hide the file input
                    multiple // Allows multiple file selection
                />                    
                    <ItemList
                        key="File-Explorer-List"
                        items={currentContents.items}
                        props={props}
                        selectedId={selectedId}
                        setSelectedId={setSelectedId}
                        HandleOnEvent={HandleOnEvent}
                        attributes={layoutItem.attributes}
                        main={true}
                        showPopup={showPopup}
                        updateDisabledItems={updateDisabledItems}
                    />
                    {popup.visible && (
                        <PopupMenu 
                            x={popup.x} 
                            y={popup.y} 
                            onClose={closePopup} 
                            onAction={handleAction}                     
                            disabledItems={disabledItems}
                            popupRef={popupRef}
                        />
                    )}                    
                </div>
            ) : (
                <div className="hub-file-explorer-loading-container center-div" style={{position: 'relative', left: '80px', top: '120px'}}>
                    <BpSpinner />
                </div>
            )}
        </div>
    );
};

export default HubFileExplorer;
