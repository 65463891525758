import rootReducer from './reducer';
import { createStore } from 'redux';

var initialStatereas = {
    allDataLoaded: false,
    areas: [], // area base classes
    areaNames: [], // all areas information
    configs: [],
    pageParameters: {
        areaName: '',
        pageName: '',
        areaId: null,
    },
    lastEvent: null,
    form: null,
    userInfo: { userId: null, userEmail: null, userName: null },
    area: null,
    page: null,
    dataStore: {
        get: (key) => null,
    },
    error: '',
    accessPolicies: {},
    selectedItems: [],
    showModalSpinner: false,
};

const store = createStore(rootReducer, initialStatereas);

export default store;
