import React from 'react';
import styled from 'styled-components';

export const FormColumn = ({ children, className }) => {
    return <Column className={`ms-Grid-col form-column ${className}`}>{children}</Column>;
};

const Column = styled.div`
    padding-top: 8px;
    padding-bottom: 8px;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-wrap: wrap;
`;
