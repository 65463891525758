import React from 'react';
import styled from 'styled-components';
import { Spinner } from 'office-ui-fabric-react/lib/Spinner';
//import SplashLogo from '../../assets/ApplicationHub_splash_logo.png';
import SplashLogo from '../../assets/blank_splash_logo.png';

import PoweredBy from '../../assets/blank_powered_by.png';

const SplashScreen = (props) => {
    var spinOrError =
        props.Error == null ? (
            <Spinner styles={{ circle: { width: 50, height: 50 } }} />
        ) : (
            <span
                style={{ textAlign: 'center', fontSize: '20px', color: 'Red' }}
                dangerouslySetInnerHTML={{ __html: props.Error }}
            />
        );
    return (
        <Page>
            <UpperContainer>
                {<SplashLogoImage src={SplashLogo} alt="Application Hub" />}
                <SpinnerContainer>{spinOrError}</SpinnerContainer>
            </UpperContainer>
            <PoweredByImage src={PoweredBy} alt="Powered by JK" />
        </Page>
    );
};

export default SplashScreen;

const UpperContainer = styled.div`
    flex: 0.5;
    display: flex;
    flex-direction: column;
`;
const SplashLogoImage = styled.img`
    width: 100%;
    max-width: 350px;
    display: block;
    margin-top: auto;
    padding-bottom: 0.5rem;
`;
const SpinnerContainer = styled.div`
    transform: translateY(50%);
`;
const PoweredByImage = styled.img`
    width: 100%;
    max-width: 200px;
    display: block;
    margin-top: auto;
    padding-bottom: 30px;
`;
const Page = styled.div`
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: white;
`;
