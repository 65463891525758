import * as React from 'react';
import { DatePicker } from 'office-ui-fabric-react';
import { buildClassName } from './formFunctions';

export const FormDatePicker = ({
    formGenerator,
    field,
    additional,
    formGenerator: {
        state: { form, formData },
    },
    collectionStack,
}) => {
    var { defaultValue, onChange, decode } = additional;

    var setDate = () => {
        if (formData[field.property] == null) return defaultValue || null;

        var d = new Date(formData[field.property]);
        return d;
    };

    return (
        <DatePicker
            // className={controlClass.control}
            // firstDayOfWeek={firstDayOfWeek}
            // strings={DayPickerStrings}
            id={field.id}
            className={buildClassName(formGenerator, field, 'form-datepicker', field.class, collectionStack)}
            disabled={decode(field, field.disabled)}
            isRequired={field.required}
            label={field.header}
            placeholder={field.placeholder}
            ariaLabel="Select a date"
            value={setDate()}
            onSelectDate={(item) => onChange(field, item)}
        />
    );
};
