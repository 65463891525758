import React from 'react';
import { buildClassName, deepCopy } from './formFunctions';
import { substitute } from '../../services/stringsubstitutions';

export const FormSeparator = ({ field, additional, collectionStack = [], ...props }) => {
    var formGenerator = additional.formGenerator;
    if (formGenerator == null) debugger;

    var copyStack = deepCopy(collectionStack);
    copyStack.reverse();

    var header = substitute(field.header, formGenerator.props.controller, [...collectionStack, formGenerator.getFormData()]);

    return (
        <div
            id={field.id}
            className={buildClassName(formGenerator, field, 'content-separator hub-separator', field.class, collectionStack)}
            style={{ ...styles.separator, ...field.settings.styles, ...field.styles }}>
            {header}
        </div>
    );
};

const styles = {
    separator: {
        width: '100%',
    },
};
