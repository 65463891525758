import * as React from 'react';
import { PrimaryButton } from 'office-ui-fabric-react';
import { themeColor } from '../../services/globalMethods';
import { buildClassName, deepCopy } from './formFunctions';
import { substitute } from '../../services/stringsubstitutions';
export const FormSubmitButton = ({ formGenerator, field, isSimple, additional: { decode }, theme, collectionStack }) => {
    var validationFields = [];
    formGenerator.getVisibleAndEnabledItems(formGenerator.state.form.items, validationFields);

    var copyStack = deepCopy(collectionStack);
    copyStack.reverse();

    var header = substitute(field.header, formGenerator.props.controller, [...copyStack, formGenerator.getFormData()]);
    var disabled = decode(field, field.disabled, false, {}, copyStack);
    const styles = {
        button: {
            primary: {
                root: {
                    width: '100%',
                    backgroundColor: themeColor(theme)('buttonSubmitDefault'),
                    color: themeColor(theme)('buttonSubmitDefaultText'),
                    border: 'none',
                },
                rootHovered: {
                    backgroundColor: themeColor(theme)('buttonSubmitHovered'),
                    border: 'none',
                },
                rootPressed: {
                    backgroundColor: themeColor(theme)('buttonSubmitPressed'),

                    border: 'none',
                },
            },
            simple: {
                root: {
                    width: '100%',
                    // color: '#323130',
                    backgroundColor: themeColor(theme)('buttonSelectorSelected'),
                },
                rootHovered: {
                    backgroundColor: themeColor(theme)('buttonSelectorSelectedHovered'),
                    // color: '#323130',
                },
                rootPressed: {
                    backgroundColor: themeColor(theme)('buttonSelectorSelectedHovered'),
                },
            },
        },
    };

    return (
        <button
            id={field.id}
            className={buildClassName(formGenerator, field, 'form-submitbutton loki-button', field.class, collectionStack)}
            style={field.styles}
            {...field.attributes}
            disabled={disabled}
            onClick={() => {
                if (field.onClick != null) {
                    var formData = { ...formGenerator.getFormData() };
                    formGenerator.runActions(field, field.onClick, field.settings.value, formData, copyStack);

                    formGenerator.updateFormData(formData, () => {
                        formGenerator.primaryButtonClick(field, isSimple);
                    });

                    // formGenerator.setState({ ...formGenerator.state, formData }, () => {
                    //     formGenerator.primaryButtonClick(field, isSimple);
                    // });
                } else {
                    formGenerator.primaryButtonClick(field, isSimple);
                }
            }}>
            {header}
        </button>
    );
};
