import React, { useMemo } from 'react';
import { TooltipHost } from 'office-ui-fabric-react/lib/Tooltip';
import { getId } from 'office-ui-fabric-react/lib/Utilities';

const Tooltip = ({ children, label, onRenderContent }) => {
    const itemId = useMemo(() => getId(itemId), []);

    var ttp = {};
    if (onRenderContent != null) ttp = { onRenderContent: () => onRenderContent() };

    return (
        <TooltipHost
            tooltipProps={ttp}
            closeDelay={150}
            styles={tooltipStyles}
            hostClassName="tooltip-container"
            content={label}
            id={itemId}
            calloutProps={{ gapSpace: 0 }}>
            {children}
        </TooltipHost>
    );
};

const tooltipStyles = {
    root: {
        display: 'inline-block',
    },
};

export default Tooltip;
