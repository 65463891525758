/* eslint-disable no-template-curly-in-string */
import * as React from 'react';
import { substitute } from '../../services/stringsubstitutions';
import { pageChanged, updateForm } from '../../redux/actions';
import { NavLink } from 'react-router-dom';
import moment from 'moment';
import Tooltip from '../Tooltip';
import { Alert, findForm } from '../../services/globalMethods';
import HubFormGenerator from './HubFormGenerator';

export const hubTableTemplates = (columnInfo, controller, item, row, component) => {
    var url, cls, link;

    switch (columnInfo.template) {
        case 'form':
            // var showEventData = (controller, item, row) => {
            //     var json = row[item.columnValues[0].property];
            //     var obj = JSON.parse(json);
            //     json = JSON.stringify(obj, null, 3);
            //     Alert(json);
            // };

            var onClick = () => {
                var templateSettings = columnInfo.templateSettings;
                var form = findForm(controller.props.page, templateSettings.key);
                var formData = {
                    title: substitute(form.title, controller, [item, templateSettings, row]),
                    description: item.settings
                        ? substitute(form.description, controller, [item, templateSettings, row])
                        : form.description,
                    settings: { ...templateSettings, ...form.settings },
                    class: form.class,
                    styles: form.styles,
                    html: (
                        <HubFormGenerator controller={controller} config={component.props.config} layoutItem={templateSettings} />
                    ),
                    open: true,
                };

                component.props.dispatch(updateForm(formData));
            };

            return (
                // <Tooltip label={row[item.columnValues[0].property]}>
                <span onClick={onClick}>{row[item.columnValues[0].property]}</span>
                // </Tooltip>
            );

        case 'jsonDisplayer':
            var showEventData = (controller, item, row) => {
                var json = row[item.columnValues[0].property];
                var obj = JSON.parse(json);
                json = JSON.stringify(obj, null, 3);
                Alert(json);
            };

            return (
                <Tooltip label={row[item.columnValues[0].property]}>
                    <span onClick={() => showEventData(controller, item, row)}>{row[item.columnValues[0].property]}</span>;
                </Tooltip>
            );

        case 'dateFormatter': {
            var col = item.columnValues[0];
            if (col.templateSettings == null || col.templateSettings.format == null) {
                return <>dateFormatter needs a templateSettings.format setting</>;
            }

            var dateString = row[col.property];
            if (dateString == null) {
                dateString = substitute(col.text, controller, [row, item]);
                if (dateString === '') dateString = null;
            }

            if (dateString == null) {
                return <span data-mk="date!!" className={col.class}></span>;
            }

            var mom = moment(dateString);
            var formatedDate = mom.format(col.templateSettings.format);

            return (
                <Tooltip label={formatedDate}>
                    <span data-mk="date!!" className={col.class}>
                        {formatedDate}
                    </span>
                </Tooltip>
            );
        }

        case 'alertDisplayer': {
            return (
                <Tooltip label={row[item.columnValues[0].property]}>
                    <span
                        onClick={() => {
                            Alert(row[item.columnValues[0].property]);
                        }}>
                        {row[item.columnValues[0].property]}
                    </span>
                </Tooltip>
            );
        }

        case 'buttonLink': {
            return (
                <Tooltip label={row[item.columnValues[0].property]}>
                    <span
                        onClick={() => {
                            Alert(row[item.columnValues[0].property]);
                        }}>
                        {row[item.columnValues[0].property]}
                    </span>
                </Tooltip>
            );
        }
        case 'errorsTemplate': {
            if (!row.hasErrors) return <></>;

            url = substitute(item.columnValues[0].templateSettings.url, controller, [item, row]);
            cls = 'badge hand red pointer';
            link = (
                <NavLink onClick={() => controller.props.dispatch(pageChanged(url))} to={url}>
                    <Tooltip label={row.errorCount}>
                        <span className={cls} style={{ fontWeight: '600' }}>
                            {row.errorCount}
                        </span>
                    </Tooltip>
                </NavLink>
            );

            return link;
        }
        // Environments_Applications only
        case 'hostTemplate': {
            url = substitute('/environments/applications/${areaId}/hosts/${solutionId}', controller, [this, item, row]);
            cls = row.hostIsMapped ? '' : 'warning';
            link = (
                <NavLink onClick={() => controller.props.dispatch(pageChanged(url))} to={url}>
                    <Tooltip label={row.hostInstanceName}>
                        <span data-mk="nav-link" className={cls} style={{ fontWeight: '600' }}>
                            {row.hostInstanceName}
                        </span>
                    </Tooltip>
                </NavLink>
            );

            return link;
        }
        case 'getAverage': {
            const average = Math.round(row.totalMessageTime / row.totalMessageCount);
            return (
                <Tooltip label={average}>
                    <>{average}</>
                </Tooltip>
            );
        }
        case 'editSetting': {
            var span = (
                <div
                    style={{ width: '100%', height: '100%', textDecoration: 'none', cursor: 'pointer' }}
                    onClick={() => {
                        row.allowEdits = !row.allowEdits;
                        row.tableController.refreshTable();
                    }}>
                    <Tooltip label="Edit settings">
                        <a className="glyphicon glyphicon-edit"></a>
                    </Tooltip>
                </div>
            );
            row.tableController = component;

            return span;
            // }
        }
        default:
            return <span>Unknown template: {columnInfo.template}</span>;
    }
};
