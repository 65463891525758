import React from 'react';
import { BpSpinner } from '../BpSpinner';
import { Bar } from 'react-chartjs-2';

export const HubGraphBarChart = ({ controller, config, layoutItem }) => {
    var data = controller.props.dataStore[layoutItem.key];
    if (data == null) return <BpSpinner />;

    if (data.length === 0) return 'No data found';

    var name = 'name';
    var count = 'count';
    var settings = layoutItem.settings || {};
    if (settings.labelKey != null) name = settings.labelKey;
    if (settings.countKey != null) count = settings.countKey;

    var labels = data.map((p) => p[name]);
    var dataSetData = data.map((p) => p[count]);

    const graphData = {
        labels: labels,
        datasets: [
            {
                label: settings.label || '',
                backgroundColor: settings.colors || 'rgba(0,99,132,0.2)',
                borderColor: settings.colors || 'rgba(0,99,132,0.2)',
                borderWidth: 1,
                hoverBackgroundColor: settings.hoverColors || 'rgba(255,99,132,0.4)',
                hoverBorderColor: 'transparent',
                data: [...dataSetData],
            },
        ],
    };

    var options = {
        scales: {
            yAxes: [
                {
                    ticks: {
                        beginAtZero: true,
                    },
                },
            ],
        },
    };

    return <Bar id={layoutItem.id} className={`hub-barchart ${layoutItem.class || ''}`} data={graphData} options={options} />;

    //     return <Bar data={actual}
    //     // options={{
    //     //   maintainAspectRatio: false
    //     // }}
    //   />
};
