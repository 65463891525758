import React from 'react';
import styled from 'styled-components';

export const FormRow = ({ children, label, className = '', style = {} }) => (
    <Row className={`ms-Grid-row ${className}`} style={style}>
        {children}
    </Row>
);

const Row = styled.div`
    display: flex;
    flex-wrap: wrap;
`;
