import { addFormEvent, Alert, createGuid, decodeDisabled, findActions, findFormSection } from '../../services/globalMethods';
import { substitute } from '../../services/stringsubstitutions';

export const getActionsFromKey = function (controller, key) {
    var { page, configs } = controller.props;

    // Its an inline action
    if (typeof key !== 'string') {
        return [key];
    }

    return findActions(page, key);

    //     var actions = page.config.actions != null ? page.config.actions.find((p) => p.key === key) : null;

    //     if (actions == null) {
    //         for (var n = 0; n < page.config.layout.includes.length; n++) {
    //             var name = page.config.layout.includes[n];
    //             while (name.includes('.')) name = name.replace('.', '_');

    //             var config = configs[name.toLowerCase()];
    //             if (config == null) {
    //                 Alert(`${name.toLowerCase()} config not found`);
    //                 continue;
    //             }

    //             actions = config.actions.find((p) => p.key === key);
    //             if (actions != null) break;
    //         }
    //     }

    //     if (actions == null) actions = findActions(controller.props.page, key);

    //     if (actions == null) {
    //         var portalConfig = configs['portal'];
    //         actions = portalConfig.actions.find((p) => p.key === key);
    //     }

    //     if (actions == null) Alert('Unable to find action: ' + key);

    //     return actions == null ? null : actions.value;
};

export const getFieldFromFormSection = function (controller, formSectionField) {
    var { page } = controller.props;

    // var replacements = {...formSectionField.defaults,...formSectionField.replacements}

    for (var property in formSectionField.replacements) {
        if (formSectionField.key && typeof formSectionField.key === 'string') {
            if (formSectionField.replacements[formSectionField.key]) {
                formSectionField.key = formSectionField.replacements[formSectionField.key];
            } else {
                formSectionField.key = formSectionField.key.replace(property, formSectionField.replacements[property]);
            }
        }
    }

    var formSection = findFormSection(page, formSectionField.key);
    if (formSection == null) {
        return null;
    } else {
        if (formSectionField.key === 'searchHeader') debugger;

        var field = deepCopy(formSection);
        field = expandFormSection(controller, field, formSectionField);

        if (formSectionField.items && formSectionField.items.length !== 0) {
            var items = deepCopy(formSectionField.items);
            field.items = [...field.items, ...items];
        }

        if (formSectionField.type.toLowerCase() === 'formsection') {
            if (formSectionField.id) {
                field.id = formSectionField.id;
            }
            if (formSection.events) {
                field.events = formSection.events;
            }
            if (formSectionField.property) {
                field.property = formSectionField.property;
            }

            if (formSectionField.visible) {
                field.visible = formSectionField.visible;
            }

            if (formSectionField.disabled) {
                field.disabled = formSectionField.disabled;
            }

            if (formSectionField.header) {
                field.header = formSectionField.header;
            }

            if (formSectionField.headerClass) {
                field.headerClass = formSectionField.headerClass;
            }

            if (formSectionField.headerFormSection) {
                field.headerFormSection = formSectionField.headerFormSection;
            }

            if (formSectionField.styles) {
                field.styles = formSectionField.styles;
            }

            if (formSectionField.placeholder) {
                field.placeholder = formSectionField.placeholder;
            }

            if (formSectionField.class) {
                field.class = formSectionField.class;
            }

            if (formSectionField.form) {
                field.form = formSectionField.form;
            }

            if (formSectionField.columnClass) {
                field.columnClass = formSectionField.columnClass;
            }

            if (formSectionField.rowClass) {
                field.rowClass = formSectionField.rowClass;
            }

            if (formSectionField.validation) {
                field.validation = formSectionField.validation;
            }

            if (formSectionField.required != null) {
                field.required = formSectionField.required;
            }

            if (formSectionField.onChange != null) {
                field.onChange = formSectionField.onChange;
            }

            if (formSectionField.onLoad != null) {
                field.onLoad = formSectionField.onLoad;
            }

            if (formSectionField.onKeyPress != null) {
                field.onKeyPress = formSectionField.onKeyPress;
            }
            if (formSectionField.onClick != null) {
                field.onClick = formSectionField.onClick;
            }

            if (formSectionField.header != null) {
                field.header = formSectionField.header;
            }

            if (formSectionField.settings != null && Object.keys(formSectionField.settings).length !== 0) {
                field.settings = { ...field.settings, ...formSectionField.settings };
            }
        }

        // FORCE ALL REPLACEMENTS TO BE UPPERCASE (needed to middle tier uses camelcase)
        if (formSectionField.replacements) {
            for (var key in formSectionField.replacements) {
                var val = formSectionField.replacements[key];
                delete formSectionField.replacements[key];
                formSectionField.replacements[key.toUpperCase()] = val;
            }

            for (var key2 in formSectionField.defaults) {
                var val2 = formSectionField.defaults[key2];
                delete formSectionField.defaults[key2];
                formSectionField.defaults[key.toUpperCase()] = val2;
            }
        }

        if (formSectionField.defaults) {
            for (var key2 in formSectionField.defaults) {
                var val2 = formSectionField.defaults[key2];
                delete formSectionField.defaults[key2];
                formSectionField.defaults[key.toUpperCase()] = val2;
            }
        }

        formSectionField.replacements = {
            ...field.replacements,
            ...field.defaults,
            ...formSectionField.replacements,
        };


        var settings = deepCopy(formSectionField.settings);
        if (field.defaults || field.replacements || formSectionField.replacements) {
            replaceFormSectionItems(field, formSectionField.replacements);
            replaceFormSectionItems(settings, formSectionField.replacements);
        }

        convertStringActionsToInline(controller, field);

        if (formSectionField.type.toLowerCase() === 'formsection' && settings) {
            // Merge settings into items
            if (field.items != null) {
                for (var key2 in settings) {
                    if (field.items == null) debugger;

                    for (var n = 0; n < field.items.length; n++) {
                        var item = field.items[n];
                        if (item.property === key2) {
                            if (item.validators != null && settings[key2].validators) {
                                settings[key2].validators = [...item.validators, ...settings[key2].validators];
                            }

                            field.items[n] = { ...item, ...settings[key2] };
                            break;
                        }
                    }
                }
            }
        }
    }

    field.originalReplacements = deepCopy({ ...field.originalReplacements, ...field.defaults, ...formSectionField.replacements });
    return field;
};

export const getActionsFromProperty = function (controller, event) {
    if (event == null || event === '') {
        return null;
    }

    if (typeof event === 'string') {
        var actions = getActionsFromKey(controller, event);
        if (actions == null) {
            debugger;
            Alert(`Unable to find action: ${event}`);
            return [];
        }

        return actions;
    } else if (!Array.isArray(event)) {
        return [event];
    } else
        return event.map((item) => {
            if (item.action != null) {
                return getActionsFromKey(controller, item.action)[0];
            }
            return item;
        });
};

export const mergeFields = function (parentFieldItem, childFieldItem) {
    if (!parentFieldItem.replacements || parentFieldItem.replacements.length === 0) {
        return childFieldItem;
    }
    // var newField = deepCopy(childFieldItem);
    // newField.replacements = { ...parentFieldItem.replacements, ...childFieldItem.replacements };
    // return newField;

    // var newField = deepCopy(childFieldItem);
    childFieldItem.replacements = {
        ...childFieldItem.defaults,
        ...parentFieldItem.defaults,
        ...parentFieldItem.replacements,
        ...childFieldItem.replacements,
    };
    return childFieldItem;
};

export const deepCopy = function (obj) {
    if (obj == null) return obj;

    try {
        return JSON.parse(JSON.stringify(obj));
    } catch {
        const getCircularReplacer = () => {
            const seen = new WeakSet();
            return (key, value) => {
                if (typeof value === 'object' && value !== null) {
                    if (seen.has(value)) {
                        return '[CIRCULAR]';
                    }
                    seen.add(value);
                }
                return value;
            };
        };

        var json = JSON.stringify(obj, getCircularReplacer());
        return JSON.parse(json);
    }
};

export const deepCopyAndApplyReplacements = function (obj, replacements) {
    var copy = JSON.parse(JSON.stringify(obj));
    if (replacements == null) return copy;

    replaceFormSectionItems(copy, replacements);
    return copy;
};

export const deepMergeFlatten = function (obj1, obj2) {
    const keys = Object.keys(obj2);
    let nextObj = { ...obj1 };

    for (let i = 0; i < keys.length; i += 1) {
        const key = keys[i];
        const value = obj2[key];
        if (typeof value === 'object' && value !== null) {
            nextObj = { ...nextObj, ...deepMergeFlatten(nextObj, value) };
        } else {
            nextObj = { ...nextObj, [key]: value };
        }
    }

    return nextObj;
};

export const replaceFormSectionItems = function (obj, replacements) {
    if (Object.prototype.toString.call(obj) === '[object Array]') {
        obj.forEach((item) => replaceFormSectionItems(item, replacements));
        return;
    }

    if (typeof obj === 'object' && obj != null) {
        //

        for (var key in obj) {
            var value = obj[key];
            var keyName = key;

            if (keyName === 'originalReplacements' || keyName === 'replacements') {
                continue;
            }

            if (Object.prototype.toString.call(value) === '[object Array]') {
                value.forEach((item) => replaceFormSectionItems(item, replacements));
                continue;
            }

            if (key === 'type' && obj.originalReplacements == null) {
                obj.originalReplacements = replacements;
            }

            if (typeof obj[key] === 'object') {
                replaceFormSectionItems(obj[key], replacements);
            }

            if (obj.hasOwnProperty(key)) {
                for (var repKey in replacements) {
                    if (keyName.includes(repKey)) {
                        if (obj[keyName] == null || obj[keyName].toString().indexOf(key) < 0) {
                            var newName = keyName.replace(repKey, replacements[repKey]);
                            obj[newName] = obj[keyName];
                            if (newName !== keyName) delete obj[keyName];
                            if (keyName) keyName = newName;
                        }
                    }

                    // we might have a PREFIX: PREFIX type replacement
                    var counter = 0;
                    while (obj[keyName] != null && counter++ < 5 && obj[keyName].toString().includes(repKey)) {
                        // TODO: fix this hack
                        if (repKey === '[object Object]') {
                            // debugger; q
                            break;
                        }
                        if (
                            (replacements[repKey] == null && typeof obj[keyName] !== 'string') ||
                            (replacements[repKey] != null && typeof replacements[repKey] === 'object')
                        ) {
                            obj[keyName] = replacements[repKey];
                            break;
                        } else {
                            obj[keyName] = obj[keyName].toString().replace(repKey, replacements[repKey] || '');
                            if (obj[keyName] === '') {
                                obj[keyName] = null;
                                continue;
                            }

                            var val = obj[keyName];
                            if (val != null && val !== '' && typeof val === 'string' && val === val.toUpperCase()) {
                                var x = parseInt(val);
                                if (isNaN(x)) {
                                    console.log(val);
                                    //  throw new CustomException(`Missing replacement for ${val}`);
                                }
                            }
                        }
                    }
                }
            } else {
                if (obj != null) return;
                obj[key].originalReplacements = replacements;

                replaceFormSectionItems(obj[key], replacements);
            }
        }

        return;
    }
};

export const getFieldFromKey = function (fieldItems, key) {
    for (var i = 0; i < fieldItems.length; i++) {
        var item = fieldItems[i];
        if (item.key === key) {
            return item;
        }

        if (item.items != null) {
            var item1 = getFieldFromKey(item.items, key);
            if (item1 != null) return item1;
        }
    }

    return null;
};

export const expandFormSection = function (controller, field, formSectionField) {
    var retVal = field;

    if (field == null || field.type == null) {
        if (!field.formSection) debugger;

        field = { type: 'formSection', key: field.formSection, ...field };
        field.formSection = null;
    }

    if (field.type.toLowerCase() === 'formsection') {
        field.replacements = {
            ...field.defaults,
            ...formSectionField.defaults,
            ...formSectionField.replacements,
            ...field.replacements,
        };
        // field.replacements = { ...field.replacements };

        // field.replacements = { ...field.replacements, ...formSectionField.replacements };
        // field.replacers = { ...field.replacements, ...formSectionField.replacements };
        field.settings = { ...field.settings, ...formSectionField.settings };
        retVal = getFieldFromFormSection(controller, field);
    }

    formSectionField.replacements = { ...field.defaults, ...formSectionField.replacements };
    //  if (field?.replacements?.['AUTO_OVERFLOW'] != null) debugger;

    if (retVal == null) debugger;
    // retVal = getFieldFromFormSection(controller, field);

    if (retVal.items != null) {
        for (var n in retVal.items) {
            var fld = retVal.items[n];

            retVal.items[n] = expandFormSection(controller, fld, formSectionField);
        }
    }

    return retVal;
};

export const addItemToCollection = function (formGenerator, containerName, formItem, formData, removeFunc) {
    var container = getFieldFromKey(formGenerator.state.form.items, containerName);
    if (container == null) {
        Alert(`container: ${containerName} is not found`);
    }
    container.formItem = formItem;

    var index = 0;
    if (container.items != null) index = container.items.length;

    //       var parentCollection = container.property.includes(container.row)  || !container.row
    //  ? `${container.property}!$c{index}!`
    //  : container.row;

    var replacements = {
        PARENT_COLLECTION_PREFIX: `${container.property}!${index}!`,
        PARENT_COLLECTION_PROPERTY: container.property,
        PARENT_COLLECTION_INDEX: `${index}`,
        PARENT_COLLECTION_NO: index + 1,
        PARENT_COLLECTION_COUNTER: index + 1,
        UNIQUE_ID: createGuid(),
    };

    // is the collection a child?
    if (container.row != null) {
        var counter = 1;
        var parentRow = container.row;
        while (parentRow.indexOf('!') > -1) {
            var lastSquareBracketClose = parentRow.lastIndexOf('!');
            var lastSquareBracketOpen = parentRow.substring(0, lastSquareBracketClose).lastIndexOf('!');
            var parentIndex = container.row.substring(lastSquareBracketOpen + 1, lastSquareBracketClose);

            replacements[`PARENT${counter}_COLLECTION_PREFIX`] = parentRow;
            replacements[`PARENT${counter}_COLLECTION_INDEX`] = parentIndex;
            //            replacements[`PARENT${counter}_COLLECTION__NO`] = parentIndex + 1;
            replacements[`PARENT${counter}_COLLECTION__COUNTER`] = parentIndex + 1;

            counter++;
            parentRow = container.row.substring(0, lastSquareBracketOpen);
        }
    }

    if (formItem.replacements != null) {
        formItem.replacements = { ...formItem.replacements, ...replacements };
    }
    if (formItem.formSection != null && typeof formItem.formSection !== 'string') {
        formItem.formSection.replacements = { ...formItem.formSection.replacements, ...replacements };
    }

    if (!formItem.replacements) formItem.replacements = replacements;

    var newField;
    if (formItem.type === 'formSection') {
        newField = getFieldFromFormSection(formGenerator.props.controller, formItem);
    } else {
        newField = deepCopyAndApplyReplacements(formItem, formItem.formSection.replacements);
    }

    container.items.push(newField);

    // TODO convert addlink collection to form collection
    // var oddOrEven = index % 2 === 0 ? 'add-link-row-even ' : 'add-link-row-odd ';
    // var rowClass = 'add-link-row ' + oddOrEven + lastRow;

    newField.index = container.items.length - 1;
    container.counter = newField.index;
    // container.replacements = { ...newField.replacements, COLLECTION_TAG: `${container.property}!${container.counter}!`, ROW_INDEX: newField.index };

    updatePropertyNames(newField, container, newField.index, false, formGenerator.props.controller);
    updateRemoveLinks(formGenerator, container, [newField], newField, removeFunc, formData);

    //  formData[`${container.property}!${container.counter}!`] = {replacements: { COLLECTION_TAG: `${container.property}!${container.counter}!`, ROW_INDEX: newField.index }}

    // TODO: Rename this and the removeme reference toItemsCount
    formData['_' + container.property + 'Count'] = container.items.length.toString();
    formData['_' + container.property + 'Index'] = (container.items.length - 1).toString();

    return newField;
};

export const updatePropertyNames = function (field, container, index, isRemoveLinksCall, controller) {
    if (field.validators != null && isRemoveLinksCall && typeof field.validators !== 'string') {
        field.validators.forEach((v) => {
            Object.keys(v).forEach((key) => {
                // TODO: This validation type (url validation) could be a object. Add ability to loop through this object too
                if (v[key] != null && typeof v[key] === 'string') {
                    v[key] = v[key].replace(`${container.property}!${index + 1}!`, `${container.property}!${index}!`);
                }
            });
        });
    }

    if (field.type === 'required' && isRemoveLinksCall) {
        field.value = field.value.replace(`${container.property}!${index + 1}!`, `${container.property}!${index}!`);
    }

    if (field.settings && field.settings.ignorePropertyUpdates) return;

    var updateProperty = (f) => {
        if (f.type && f.type.toLowerCase() === 'placeholder') {
            f.container = container;
        }

        if (f.type && f.type.toLowerCase() === 'formsection') {
            f.container = container;
        }

        if (f.property != null && f.property !== '') {
            if (f.originalPropertyName == null) {
                f.originalPropertyName = f.property;
            }

            if (isRemoveLinksCall) {
                f.property = f.property.replace(`${container.property}!${index + 1}!`, `${container.property}!${index}!`);

                if (f.row) f.row = f.row.replace(`${container.property}!${index + 1}!`, `${container.property}!${index}!`);
            } else {
                if (f.originalPropertyName[0] === '_')
                    f.property = `_${container.property}!${index}!${f.originalPropertyName.substring(1)}`;
                else f.property = `${container.property}!${index}!${f.originalPropertyName}`;

                f.row = `${container.property}!${index}!`;
            }
            f.index = index;
        }
    };

    if (field.items != null) {
        field.items.forEach((element) => {
            updateProperty(element);
            if (element.items != null) {
                updatePropertyNames(element, container, index, isRemoveLinksCall, controller);
            }
        });
    }

    updateProperty(field);
};

export const updateRemoveLinks = function (hubFormGenerator, container, items, child, removeFunc, formData) {
    items.forEach((element) => {
        if (element.type === 'removelink') {
            //      element.settings = {value};
            element.property = container.property;
            var removeMeFunc = () => {
                var found = false;
                var counter = 0;
                var index = 0;

                var renames = [];
                container.items.forEach((element) => {
                    var currentPropertyName = `${container.property}!${counter}!`;
                    if (element === child) {
                        found = true;
                        renames.push({ from: currentPropertyName, to: null });
                        index = counter;
                        counter++;
                        return;
                    }

                    if (!found) {
                        counter++;
                        return;
                    }

                    updatePropertyNames(element, container, counter - 1, true, hubFormGenerator);

                    var previousPropertyName = `${container.property}!${counter - 1}!`;
                    renames.push({ from: currentPropertyName, to: previousPropertyName });
                    counter++;
                });

                addFormEvent(
                    { settings: {} },
                    hubFormGenerator.getFormData(),
                    'removelink',
                    { property: element.property, index },
                    {}
                );

                // // remove field from hierarchy
                // container.items.splice(index, 1);
                // container.counter--;

                // now update form data
                var formData = { ...hubFormGenerator.getFormData() };
                var searchString = `${container.property}!`;
                // remove by numbers
                for (var propName in hubFormGenerator.getFormData()) {
                    if (propName.indexOf(searchString) === -1) continue;

                    // eslint-disable-next-line no-loop-func
                    renames.forEach((p) => {
                        if (propName.indexOf(p.from) > -1) {
                            if (p.to == null) {
                                delete formData[propName];
                            } else {
                                if (propName.includes(p.from) && propName.endsWith('Count')) {
                                    delete formData[propName];
                                    return;
                                }

                                var newPropName = propName.replace(p.from, p.to);
                                formData[newPropName] = formData[propName];
                                delete formData[propName];
                            }
                        }
                    });
                }

                for (propName in hubFormGenerator.collections) {
                    if (propName.indexOf(searchString) === -1) continue;

                    // eslint-disable-next-line no-loop-func
                    renames.forEach((p) => {
                        if (propName.indexOf(p.from) > -1) {
                            if (p.to == null) {
                                delete hubFormGenerator.collections[propName];
                            } else {
                                if (propName.includes(p.from) && propName.endsWith('Count')) {
                                    delete hubFormGenerator.collections[propName];
                                    return;
                                }

                                var newPropName = propName.replace(p.from, p.to);
                                hubFormGenerator.collections[newPropName] = hubFormGenerator.collections[propName];
                                delete hubFormGenerator.collections[propName];
                            }
                        }
                    });
                }

                formData['_' + container.property + 'Count'] = (container.items.length - 1).toString();

                var redoNItems = container.items.length - (index + 1);
                var dc = container.items;
                dc.splice(index);
                for (var n = 0; n < redoNItems; n++) {
                    addItemToCollection(hubFormGenerator, container.key, container.formItem, hubFormGenerator.getFormData());
                }

                if (removeFunc != null) removeFunc();

                formData.formIsValid = hubFormGenerator.validateForm(formData);
                hubFormGenerator.updateFormData(formData);
            };

            // child.removeMe = element.removeMe;
            formData[element.originalReplacements.PARENT_COLLECTION_PREFIX + 'RemoveFunc'] = removeMeFunc;
        }

        if (element.items != null) {
            element.items.forEach((item) => {
                if (!item.originalReplacements) item.originalReplacements = element.originalReplacements;
            });
            updateRemoveLinks(hubFormGenerator, container, element.items, child, removeFunc, formData);
        }
    });
};

export const convertStringActionsToInline = function (controller, field) {
    if (field == null) return field;

    if (field.items) {
        field.items.forEach((item) => convertStringActionsToInline(controller, item));
    }

    var action;
    if (field.onChange != null) {
        action = getActionsFromProperty(controller, field.onChange);
        field.onChange = deepCopy(action);
    }

    if (field.onLoad != null) {
        action = getActionsFromProperty(controller, field.onLoad);
        field.onLoad = deepCopy(action);
    }

    if (field.onClick != null) {
        action = getActionsFromProperty(controller, field.onClick);
        field.onClick = deepCopy(action);
    }

    if (field.OnSuccess != null) {
        action = getActionsFromProperty(controller, field.OnSuccess);
        field.OnSuccess = deepCopy(action);
    }

    if (field.OnFailure != null) {
        action = getActionsFromProperty(controller, field.OnFailure);
        field.OnFailure = deepCopy(action);
    }

    if (field.OnLoaded != null) {
        action = getActionsFromProperty(controller, field.OnLoaded);
        field.OnLoaded = deepCopy(action);
    }

    if (field.OnFocus != null) {
        action = getActionsFromProperty(controller, field.OnFocus);
        field.OnFocus = deepCopy(action);
    }

    if (field.OnLostFocus != null) {
        action = getActionsFromProperty(controller, field.OnLostFocus);
        field.OnLostFocus = deepCopy(action);
    }
};

export const CreateCtrlsFromFormSection = function (formGenerator, additional, formSection) {
    var item = getFieldFromFormSection(formGenerator.props.controller, formSection);
    const ctrls = formGenerator.createControls(additional.addToValidationFunc, item, {});
    return ctrls;
};

export const wrapWithDiv = function (hubFormGenerator, field, collectionStack, element) {
    var cls = buildClassName(hubFormGenerator, field, '', field.class, collectionStack);
    if (!field.id && !field.styles && (!cls || cls === '')) {
        return <>{element}</>;
    } else {
        return (
            <div className={cls} style={field.styles}>
                {element}
            </div>
        );
    }
};

export const buildClassName = function (hubFormGenerator, field, controlClasses, classProperty, collectionStack) {
    controlClasses = controlClasses || '';

    if (classProperty == null || typeof classProperty === 'string') {
        //    if (classProperty && classProperty.indexOf('satisfied-') > -1) debugger;
        var cls = substitute(classProperty, hubFormGenerator.props.controller, [
            ...collectionStack,
            hubFormGenerator.getFormData(),
        ]);

        return `${controlClasses || ''} ${field.defaultClass || ''} ${cls || ''}`.trim();
    }

    var classes = classProperty;
    var additionalClasses = '';
    if (!Array.isArray(classes)) {
        classes = [classProperty];
    }

    classes.forEach((item) => {
        var isVisible =
            item.visible == null
                ? true
                : decodeDisabled(
                      field,
                      item.visible,
                      hubFormGenerator.props.controller,
                      hubFormGenerator.getDataStore(),
                      hubFormGenerator.getFormData(),
                      collectionStack
                  );
        if (isVisible) additionalClasses += ` ${item.class} `;
    });

    var retVal = `${controlClasses || ''} ${field.defaultClass || ''} ${additionalClasses || ''}`.trim();
    return retVal === '' ? null : retVal;

    // retVal = retVal.trim();
    // return retVal;
};

export const buildHeader = function (formGenerator, field, collectionStack, substitutions) {
    if (!field.header || field.header === '') return '';

    if (typeof field.header === 'string') {
        if (!substitutions) {
            substitutions = deepCopy(collectionStack) || [];
            substitutions.reverse();
        }

        return substitute(field.header, formGenerator.props.controller, substitutions);
    }

    // TODO: fix issue with using newField replacements

    var newField = deepCopy(field.header);
    newField.replacements = { ...field.replacements, ...newField.replacements };
    newField.originalReplacements = field.originalReplacements;

    var addToValidationFunc = formGenerator.additional != null ? formGenerator.additional.addToValidationFunc : null;
    return formGenerator.createControls(addToValidationFunc, newField, '', collectionStack);
};
