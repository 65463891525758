import * as React from 'react';
import { TextField, MaskedTextField } from 'office-ui-fabric-react';
import { substitute } from '../../services/stringsubstitutions';
import { buildClassName, deepCopy } from './formFunctions';
import { parseBool } from '../../services/globalMethods';
import { SearchService } from '../../services/classes/SearchService';

export class FormInput extends React.Component {
    constructor(props) {
        super(props);

        this.state = { runLoad: false, loadRun: false };
        this.previousValue = null;

        var { filterOn, filterTo, searchKey } = props.field.settings;
        this.searchKey = searchKey; // Used for backend filtering
        if (filterOn) {
            this.isLocalSearch = true;
            this.searchService = new SearchService(props.controller, filterOn, filterTo);
            this.onLocalSearch = (value) => {
                this.searchService.search(value);
            };
        }
    }

    componentDidUpdate() {
        var { formGenerator } = this.props;

        if (formGenerator.props.eventType === 'ALL_DATA_LOADED') {
            if (this.state.runLoad && !this.state.loadRun) {
                this.setState({ runLoad: true, loadRun: true });
            } else if (!this.state.runLoad) {
                this.setState({ runLoad: true, loadRun: false });
            }
        }

        if (this.isLocalSearch) {
            var fd = formGenerator.getFormData();
            var val = fd[this.props.field.property];
            this.searchService.search(val);
        }
    }
    render() {
        var { field, additional, formGenerator, collectionStack, controller } = this.props;

        var isLoading = false;

        var { onChange, decode, validation } = additional;

        var self = this;
        var formData = formGenerator.getFormData();

        var defaultValue = field.defaultValue || '';

        var copyStack = deepCopy(collectionStack);
        copyStack.reverse();

        field.property = substitute(field.property, controller, copyStack);
        field.header = substitute(field.header, controller, copyStack);
        if (field.onChange) {
            field.onChange.forEach((item) => {
                if (item.settings) {
                    Object.keys(item.settings).forEach((key) => {
                        if (item.settings[key] == null) return;

                        item.settings[key] = substitute(item.settings[key], controller, copyStack);
                    });
                }
            });
        }

        var onBlur = (val) => {
            if (formData[field.property] == null) {
                onChange(field, '', copyStack);
            }
        };

        var onChangeWithSearch = (value) => {
            debugger;
            if (this.isLocalSearch) {
                this.searchService.search(value);
            }

            onChange(field, value, copyStack);
        };
        var onKeyPress = (val) => {
            var keyPressed = {
                charCode: val.charCode,
                altKey: val.altKey,
                ctrlKey: val.ctrlKey,
            };

            if (this.searchKey && keyPressed.charCode === 13) {
                var searchText = getValue();
                formGenerator.updateSearchResults(this.searchKey, { search: searchText });
            }

            if (field.onKeyPress == null) return;

            var formData = { ...formGenerator.getFormData() };
            formGenerator.runActions(field, field.onKeyPress, { keyPressed }, formData, copyStack);
        };

        var getValue = (isLoading) => {
            var fd = formGenerator.getFormData();
            var val = fd[field.property];
            if (val != null) {
                val = val.toString();
            }

            if (!isLoading && field.onUpdate && val != self.previousValue) {
                formGenerator.runActions(field, field.onUpdate, {}, fd, copyStack);
                self.previousValue = val;
            }

            if (isLoading) self.previousValue = val;

            return val;
        };

        if (!this.state.loadRun && this.state.runLoad && field.onLoad != null) {
            var val = getValue() || defaultValue;
            var copyField = deepCopy(field);
            copyField.onChange = field.onLoad;
            onChange(copyField, val, copyStack);

            isLoading = true;
            if (field.settings && field.settings.onChangeDebounce) {
                formGenerator.onChange(field, val, formGenerator.getFormData(), copyStack);
            }
        }

        return (
            <>
                {field.header ? (
                    <>
                        <label for={field.property}>{field.header}</label>
                        <br />
                    </>
                ) : null}

                <input
                    id={field.property}
                    className={buildClassName(formGenerator, field, 'form-input', field.class, collectionStack)}
                    style={field.styles}
                    label={field.header}
                    prefix={field.settings.prefix}
                    suffix={field.settings.suffix}
                    disabled={decode(field, field.disabled)}
                    type={field.subType ?? 'text'}
                    onBlur={(e) => onBlur(e.target.value)}
                    multiline={false}
                    onGetErrorMessage={validation}
                    required={parseBool(field.required)}
                    readOnly={parseBool(field.readonly)}
                    onKeyPress={(e) => {
                        onKeyPress(e);
                    }}
                    deferredValidationTime={field.settings.deferredValidationTime || 750}
                    validateOnFocusOut={field.settings.validateOnFocusOut || false}
                    validateOnFocusIn={field.settings.validateOnFocusIn}
                    placeholder={substitute(field.placeholder, controller, [formData, field.settings])}
                    value={getValue(isLoading) || defaultValue}
                    onChange={(e, val) => {
                        onChangeWithSearch(e.target.value);
                    }}
                    autocomplete="off"
                />
            </>
        );
    }

    // debounce = function (timeout, func) {
    //     return () => {
    //         clearTimeout(this.timer);
    //         this.timer = setTimeout(() => {
    //             func();
    //         }, timeout);
    //     };
    // };
}
