import { parseInt } from 'lodash';
import * as React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import styled from 'styled-components';
import { addFormEvent } from '../../services/globalMethods';
import { addItemToCollection, buildClassName, deepCopy, getFieldFromKey } from './formFunctions';
import { setDataStore } from '../../redux/actions';
import { substitute, substituteImage } from '../../services/stringsubstitutions';
import { FormDropDown } from './FormDropDown';

class FormAddLink extends React.Component {
    constructor(props) {
        super(props);

        this.state = { loaded: false, count: 0 };
        this.onClick = this.onClick.bind(this);
    }

    render() {
        var field = this.props.field;
        var { formGenerator, additional, collectionStack, controller } = this.props;
        var { icon } = field;

        var copyStack = deepCopy(collectionStack);
        copyStack.reverse();

        var formData = formGenerator.getFormData();
        const header = substitute(field.header, controller, [formData, ...copyStack]);

        var style = {
            Cursor: 'pointer',
            TextDecoration: 'underline',
            FontSize: '15px',
            ...field.styles,
        };

        var disabled = this.props.additional.decode(field, field.disabled);

        if (field.type.toLowerCase() === 'addlinkcombo') {
            const onPreChange = (field, value, formData) => {
                var container = getFieldFromKey(formGenerator.state.form.items, field.settings.container);
                if (!container) debugger;
                var propertyName = `${field.property}!${container.items.length}!value`;

                var eventData = { property: propertyName, type: field.type, value: value };
                addFormEvent(formGenerator.state.form, formData, 'setFormData', eventData, field);

                this.onClick(false, value, formData);

                return { value, formData };
            };
            field.onPreChange = onPreChange;

            return (
                <FormDropDown
                    formGenerator={formGenerator}
                    field={field}
                    additional={additional}
                    collectionStack={this.props.collectionStack}
                />
            );
        }

        if (icon && header) {
            var src = substituteImage(icon, additional.controller.props.areas.portal);
            var image = (
                // <img src={src} width="16" height="16" className={field.class} style={{ ...field.styles, ...{ marginTop: '-2px', marginRight: '8px' } }} />
                <img
                    src={src}
                    width="16"
                    height="16"
                    alt=""
                    className={buildClassName(formGenerator, field, `form-addlink-icon`, field.class, collectionStack)}
                    style={field.styles}
                />
            );

            var linkWithHeaderAndIcon = disabled ? (
                <ButtonLinkDisabled
                    id={field.id}
                    className={buildClassName(
                        formGenerator,
                        field,
                        `form-addlink form-addlink-disbled`,
                        field.class,
                        collectionStack
                    )}
                    style={style}>
                    <span>
                        {image} {header}
                    </span>
                </ButtonLinkDisabled>
            ) : (
                <ButtonLink
                    id={field.id}
                    className={buildClassName(formGenerator, field, 'form-addlink', field.class, collectionStack)}
                    style={style}
                    onClick={() => this.onClick(false)}>
                    {' '}
                    <span>
                        {image} {header}
                    </span>{' '}
                </ButtonLink>
            );

            return linkWithHeaderAndIcon;
        }

        if (icon) {
            const src = substituteImage(icon, additional.controller.props.areas.portal);
            const image = (
                <img
                    src={src}
                    width="16"
                    height="16"
                    alt=""
                    className={buildClassName(formGenerator, field, 'form-addlink-icon', field.class, collectionStack)}
                    style={field.styles}
                />
            );

            var linkWithIcon = disabled ? (
                <ButtonLinkDisabled
                    id={field.id}
                    className={buildClassName(
                        formGenerator,
                        field,
                        'form-addlink form-addlink-disbled',
                        field.class,
                        collectionStack
                    )}
                    style={style}>
                    {' '}
                    {image}
                </ButtonLinkDisabled>
            ) : (
                <ButtonLink
                    id={field.id}
                    className={buildClassName(formGenerator, field, 'form-addlink', field.class, collectionStack)}
                    style={style}
                    onClick={() => this.onClick(false)}>
                    {' '}
                    <span>{image}</span>{' '}
                </ButtonLink>
            );

            return linkWithIcon;
        }

        var link = disabled ? (
            <ButtonLinkDisabled
                id={field.id}
                className={buildClassName(
                    formGenerator,
                    field,
                    'form-addlink form-addlink-disbled',
                    field.class,
                    collectionStack
                )}
                style={style}>
                {header}
            </ButtonLinkDisabled>
        ) : (
            <ButtonLink
                id={field.id}
                className={buildClassName(formGenerator, field, 'form-addlink', field.class, collectionStack)}
                style={style}
                onClick={() => this.onClick(false)}>
                {header}{' '}
            </ButtonLink>
        );

        return link;
    }

    onClick(ignoreActions = false, value = null, formD = null) {
        var self = this;
        var field = this.props.field;
        var { formGenerator } = this.props;
        var controller = formGenerator.props.controller;

        var formData = formD || formGenerator.getFormData();

        var formItem;
        if (typeof field.settings.formSection === 'string') {
            formItem = {
                ...field.settings,
                type: 'formSection',
                key: field.settings.formSection,
                replacements: field.originalReplacements,
            };
        } else {
            formItem = deepCopy(field.settings.formSection);
            formItem = { ...formItem, ...field.settings };
            formItem.replacements = { ...field.originalReplacements, ...formItem.replacements };
        }

        // var value = formGenerator.collections[field.property];
        if (value != null) {
            formItem.replacements = { ...formItem.replacements, VALUE: value };
        } else {
            var container = getFieldFromKey(formGenerator.state.form.items, field.settings.container);
            if (!container) debugger;
            var propertyName = `${field.property}!${container.items.length}!value`;
            if (formData[propertyName]) {
                formItem.replacements = { ...formItem.replacements, VALUE: formData[propertyName] };
            }
        }

        addItemToCollection(formGenerator, field.settings.container, formItem, formData, () => {
            this.props.dispatch(setDataStore(controller, { removeLink: !this.props.removeLink }));
            self.setState({ ...self.state, count: self.state.count - 1, defaultLoaded: false });
        });

        var formIsValid = formGenerator.validateForm(formData);
        formData = { ...formData, formIsValid: formIsValid };
        formGenerator.updateFormData(formData);

        if (!ignoreActions) {
            var copyStack = deepCopy(this.props.collectionStack);
            copyStack.reverse();

            formGenerator.runActions(field, field.onClick, field.settings.value, formGenerator.getFormData(), copyStack);
            self.setState({ ...self.state, count: self.state.count + 1 });
        }
    }

    componentDidUpdate(prevProps, nextProps) {
        var { field, formGenerator } = this.props;

        if (!this.props.allDataLoaded) {
            if (this.state.loaded) {
                this.setState({ ...this.state, defaultLoaded: false, removeLink: this.props.removeLink, loaded: false });
            }
            return;
        }

        var container = getFieldFromKey(formGenerator.state.form.items, field.settings.container);
        if (container == null) return;

        var expectedItemsCount = this.getExpectedItemsCount(formGenerator.getFormData(), container);
        if (this.state.defaultLoaded && this.state.removeLink === this.props.removeLink) {
            if (expectedItemsCount === this.state.count && container.items.length !== this.state.count) {
                container.items = [];
                for (var n = 0; n < this.state.count; n++) {
                    this.onClick(true);
                }
            }

            return;
        }

        if (
            this.state.loaded &&
            expectedItemsCount < container.items.length &&
            container.items.length === this.state.count &&
            this.state.removeLink !== this.props.removeLink
        ) {
            this.setState({ ...this.state, defaultLoaded: true, removeLink: this.props.removeLink });
            return;
        }

        // var expectedItemsCount = Object.keys(expectedItems).length;
        if (expectedItemsCount !== container.items.length) {
            container.items = [];
            for (var n = 0; n < expectedItemsCount; n++) {
                this.onClick(true);
            }

            this.setState({
                ...this.state,
                count: expectedItemsCount,
                defaultLoaded: true,
                removeLink: this.props.removeLink,
                loaded: true,
            });
            return;
        }

        if (container.items.length === 0 && field.settings.defaultItems) {
            var itemCount = parseInt(field.settings.defaultItems);

            for (var n = 0; n < itemCount; n++) {
                this.onClick(true);
            }

            this.setState({
                ...this.state,
                count: itemCount,
                defaultLoaded: true,
                removeLink: this.props.removeLink,
                loaded: true,
            });
            return;
        }

        this.setState({ ...this.state, defaultLoaded: true, removeLink: this.props.removeLink, loaded: true });
    }

    getExpectedItemsCount(formData, container) {
        var expectedItems = {};
        Object.keys(formData).forEach((key) => {
            var start = container.property + '!';
            if (key.indexOf(start) === 0) {
                key = key.replace(start, '');
                key = key.substring(0, key.indexOf('!'));
                expectedItems[key] = null;
            }
        });

        //    if (container.items.length === this.state.count && this.state.defaultLoaded) return;

        return Object.keys(expectedItems).length;
    }
}
// END OF CLASS

const mapStateToProps = (state) => ({
    dataStore: state.dataStore,
    eventType: state.eventType,
    //stopFormGeneratorUpdating: state.stopFormGeneratorUpdating,
    removeLink: state.dataStore['removeLink'],
    allDataLoaded: state.allDataLoaded,
});

export default withRouter(connect(mapStateToProps, null)(FormAddLink));

const ButtonLink = styled.span`
    cursor: pointer;
    text-decoration: underline;
    font-color: black;
`;

const ButtonLinkDisabled = styled.span`
    cursor: none;
    font-color: red;
    color: grey;
    text-decoration: none;
`;
