import React from 'react';
import App from './App';
import { Provider } from 'react-redux';
import store from './redux/store';
import { BrowserRouter as Router } from 'react-router-dom';
import { RouterHistory } from './services/history';
import { Helmet } from 'react-helmet';
import './css/form-generator.css';
import { appSettings } from './services/settings';

const getDynamicPath = ({ path, version, versionEnabled = true }) => {
    if (!path) return null;

    const baseUrl = appSettings.BASE_URL;
    const fileVersion = `?v=${version || Math.floor(Date.now() / 1000)}`;
    return `${baseUrl}${path}${versionEnabled ? fileVersion : ''}`;
};

const cssFile = getDynamicPath({ path: '/static/dynamic.css' });
const jsFile = getDynamicPath({ path: '/static/dynamic.js' });
var scripts = [];

// TODO: make import scripts configurable
// (appSettings.SCRIPTS || []).forEach((script) => {
//     scripts.push(<Helmet>{script}</Helmet>);
// });

export const Root = () => (
    <>
        <Helmet>{!!cssFile && <link rel="stylesheet" type="text/css" href={cssFile} />}</Helmet>
        <Helmet>{!!jsFile && <script src={jsFile} type="text/javascript" />}</Helmet>
        <Provider store={store}>
            <Router history={RouterHistory}>
                <App />
            </Router>
        </Provider>
    </>
);

//AD ONLY
//ReactDOM.render(<Root />, document.getElementById('root'));
{
    /* <script src="https://cdnjs.cloudflare.com/ajax/libs/microsoft-signalr/6.0.1/signalr.js"></script> */
}
