import React from 'react';
import { MessageBar } from 'office-ui-fabric-react';
import { substitute } from '../../services/stringsubstitutions';
import { decodeDisabled } from '../../services/globalMethods';

// export declare enum MessageBarType {
//   /** Info styled MessageBar */
//   info = 0,
//   /** Error styled MessageBar */
//   error = 1,
//   /** Blocked styled MessageBar */
//   blocked = 2,
//   /** SevereWarning styled MessageBar */
//   severeWarning = 3,
//   /** Success styled MessageBar */
//   success = 4,
//   /** Warning styled MessageBar */
//   warning = 5
// }

export const HubMessageBar = ({ controller, layoutItem, formGenerator }) => {
    var messageBarType = 0;
    if (layoutItem != null && layoutItem.settings != null && layoutItem.settings.messageBarType != null)
        messageBarType = layoutItem.settings.messageBarType;

    messageBarType = parseInt(messageBarType.toString());
    if (layoutItem.visible != null) {
        var result = decodeDisabled(
            layoutItem,
            layoutItem.visible,
            controller,
            controller.props.dataStore,
            formGenerator.getFormData()
        );

        if (!result) {
            return <></>;
        }
    }

    var text = substitute(layoutItem.key || layoutItem.header, controller, [layoutItem]);
    return (
        <MessageBar
            id={layoutItem.id}
            className={`hub-messagebar ${layoutItem.class || ''}`}
            messageBarType={messageBarType}
            style={layoutItem.styles}>
            <span dangerouslySetInnerHTML={{ __html: text }} />
        </MessageBar>
    );
};
