import * as React from 'react';
import { ChoiceGroup } from 'office-ui-fabric-react';
import { buildClassName } from './formFunctions';

export const FormChoiceGroup = ({ form, field, formData, additional }) => {
    var { formGenerator, controller, defaultValue, onChange, decode, collectionStack } = additional;

    var options =
        field.settings.options != null ? field.settings.options : controller.props.dataStore.get(field.settings.property);

    return (
        <ChoiceGroup
            id={field.id}
            className={buildClassName(formGenerator, field, 'form-choicegroup', field.class, collectionStack)}
            label={field.header}
            disabled={decode(field, field.disabled)}
            options={options}
            defaultSelectedKey={formData[field.property] || defaultValue}
            required={field.required}
            value={formGenerator.getFormData()[field.property] || defaultValue}
            onChange={(e, item) => {
                onChange(field, item);
            }}
        />
    );
};
