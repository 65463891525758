import * as React from 'react';
import styled from 'styled-components';
import { buildClassName, deepCopy } from './formFunctions';
import { substituteImage } from '../../services/stringsubstitutions';

export const FormRemoveLink = ({ formGenerator, field, additional, collectionStack }) => {
    const { style } = field.settings;
    var { icon, header } = field;

    var copyStack = deepCopy(collectionStack);
    copyStack.reverse();

    const onClick = () => {
        var removePropName = field.originalReplacements.PARENT_COLLECTION_PREFIX + 'RemoveFunc';
        formGenerator.getFormData()[removePropName]();
        //        field.removeMe();
        if (field.onClick !== null) {
            formGenerator.runActions(field, field.onClick, field.settings.value, formGenerator.getFormData(), copyStack);
        }
    };

    if (header != null && header.length > 0 && header[0] === '_') header = null;

    if (icon && header) {
        const src = substituteImage(icon, additional.controller.props.areas.portal);
        var image = (
            <img src={src} width="16" height="16" style={{ ...field.styles, ...{ marginTop: '-2px', marginRight: '8px' } }} />
        );

        return (
            <ButtonLink
                id={field.id}
                className={buildClassName(formGenerator, field, 'form-removelink', field.class, collectionStack)}
                style={field.styles}
                onClick={onClick}>
                <>
                    {image}
                    {header}
                </>
            </ButtonLink>
        );
    }

    if (icon) {
        const src = substituteImage(icon, additional.controller.props.areas.portal);
        const image = <img src={src} width="16" height="16" style={{ ...field.styles }} />;

        return (
            <ButtonLink
                id={field.id}
                className={buildClassName(formGenerator, field, 'form-removelink', field.class, collectionStack)}
                style={field.styles}
                onClick={onClick}>
                {image}
            </ButtonLink>
        );
    }

    return (
        <ButtonLink
            id={field.id}
            className={buildClassName(formGenerator, field, 'form-removelink', field.class, collectionStack)}
            style={style}
            onClick={onClick}>
            {header}
        </ButtonLink>
    );
};

const ButtonLink = styled.span`
    cursor: pointer;
    text-decoration: underline;
`;
