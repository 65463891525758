import React from 'react';
import ReactDOM from 'react-dom';

import b2cauth from 'react-azure-adb2c';
import { appSettings } from './services/settings';
import { Root } from './indexApp';

b2cauth.initialize(appSettings.ADAL_B2C);

b2cauth.run(() => {
    ReactDOM.render(<Root />, document.getElementById('root'));
});

// export const authContext = new AuthenticationContext(

//     appSettings.ADAL_B2C ? {} ||{
//     instance: appSettings.ADAL.INSTANCE,
//     tenant: appSettings.ADAL.TENANT,
//     clientId: appSettings.ADAL.CLIENT_ID,
//     endpoints: appSettings.ADAL.ENDPOINTS,
// });
