import * as React from 'react';
import { hideAlert } from '../redux/actions';
export const MyAlert = (props) => {
    if (props.controller.props.alert == null) return null;

    return (
        <div className={'alert alert-' + props.controller.props.alert.type} role="alert" style={{ marginBottom: '0px' }}>
            <button
                type="button"
                className="close"
                data-dismiss="alert"
                aria-label="Close"
                style={{ position: 'relative', top: '0px', right: '5px' }}
                onClick={() => props.controller.props.dispatch(hideAlert())}>
                <span aria-hidden="true">&times;</span>
            </button>
            <h4 className="alert-heading" dangerouslySetInnerHTML={{ __html: props.controller.props.alert.title }}></h4>
            <div
                style={{ overflowWrap: 'break-word' }}
                dangerouslySetInnerHTML={{ __html: props.controller.props.alert.message.toString() }}></div>
        </div>
    );
};
