/*
  dataGrabs: 
  - url: /admin/websites/d0208968-8648-4b07-b204-4849c0475ff5
    method: POST
    searchKey: default
    searchItems: webSites  -> defaultValue:  items
*/

import {
    setDataStoreFromGrabber,
    setError,
    pageChanged,
    setDataStoreFromGrabber2,
    AllowFormRedraw,
    allDataLoaded,
    setDataStore,
} from '../redux/actions';

import queryString from 'query-string';
import _ from 'lodash';
import { substitute } from './stringsubstitutions';
import { makeAxiosPostRequest, makeAxiosGetRequest } from './axiosHelper';
import { createGuid, decodeDisabled, handleHttpResponse, replaceAll } from './globalMethods';
import { objectMapper } from './requests';

export class DataGrabber {
    constructor(controller, formGenerator) {
        this.appendToStore = this.appendToStore.bind(this);

        this.currentUrl = controller.props.location.pathname + controller.props.location.search;
        this.controller = controller;
        this.formGenerator = formGenerator;

        var controllerProps = controller.props;
        var params = queryString.parse(controllerProps.location.search);
        if (controllerProps.match.params.secondaryPageName) {
            params['secondaryPageName'] = controllerProps.match.params.secondaryPageName;
            params['solutionId'] = controllerProps.match.params.secondarySolutionId;
        }

        // ssettings.formData added so data can be accessed when a form is called inside a form
        this.additionalItems = [params, formGenerator.props.controller.props.form?.settings?.formData];

        //Used to ignore old page returned data
        // TODO:  https://codeburst.io/automatically-canceling-previous-requests-in-javascript-606943e2834
        this.uniquePageId = createGuid();
        window['_uniquePageId'] = this.uniquePageId;
    }

    createDataStore(dataGrabs, dataAdditions) {
        this.controller.props.dispatch(allDataLoaded(false));
        this.formGenerator.setFormState({ dataLoaded: false });

        dataGrabs = dataGrabs || [];
        this.grabsLeft = dataGrabs == null ? 0 : dataGrabs.length;

        try {
            if (dataAdditions != null) {
                this.appendToStore(dataAdditions, dataGrabs.length !== 0);
            }

            this.fetchData(dataGrabs);
        } finally {
            if (this.grabsLeft === 0) {
                this.controller.props.dispatch(allDataLoaded());
                this.formGenerator.setFormState({ dataLoaded: true });
            }
        }
    }

    loadNextPage(dataGrabs, searchKey) {
        var dataGrab = dataGrabs.find((p) => {
            return p.searchKey === searchKey;
        });

        var ds = this.formGenerator.getDataStore();
        var searchInfo = ds._searches[searchKey];
        searchInfo.body.page = searchInfo.body.page + 1;
        this.controller.props.dispatch(setDataStore(this.controller, { _searches: ds._searches }));

        makeAxiosPostRequest(searchInfo.url, searchInfo.body, dataGrab.headers)
            .then((response) => this.handleResponse(response, dataGrab, searchInfo))
            .catch((error) => this.handleError(error))
            .finally(() => {});
    }

    updateSearchResults(dataGrabs, searchKey, searchObject) {
        var dataGrab = dataGrabs.find((p) => {
            return p.searchKey === searchKey;
        });

        var ds = this.formGenerator.getDataStore();
        var searchInfo = ds._searches[searchKey];
        searchInfo.body = { ...searchInfo.body, ...searchObject, page: 0 };
        this.controller.props.dispatch(setDataStore(this.controller, { _searches: ds._searches }));

        makeAxiosPostRequest(searchInfo.url, searchInfo.body, dataGrab.headers)
            .then((response) => this.handleResponse(response, dataGrab))
            .catch((error) => this.handleError(error))
            .finally(() => {});
    }

    appendToStore(data, stillLoading) {
        // Stll loading indicates that the first SET_DATASTORE was from hardcoded
        // dataStore values and there's more data to come from datagrabber calls
        var storeAction = setDataStoreFromGrabber2(this.currentUrl, data, false, stillLoading);
        this.controller.props.dispatch(storeAction);
        this.controller.props.dispatch(AllowFormRedraw());
    }

    fetchData(dataGrabs) {
        if (dataGrabs == null) return;

        var dataCombined = {};
        this.additionalItems.forEach((p) => {
            dataCombined = { ...p, ...dataCombined };
        });

        dataGrabs.forEach((dataGrab) => {
            if (dataGrab.disabled != null) {
                if (decodeDisabled(dataGrab, dataGrab.disabled, this.controller, this.controller.props.dataStore, dataCombined)) {
                    this.grabsLeft--;
                    return;
                }
            }

            var dg = dataGrab;
            if (dg.include) {
                dg = null;
                var configs = this.formGenerator.state.form.includes.filter((inc) => {
                    var inc2 = replaceAll(inc, '.', '_').toLowerCase();
                    return this.formGenerator.props.configs[inc2] != null;
                });

                var dataGrabs = [];
                configs.forEach((inc) => {
                    var inc2 = replaceAll(inc, '.', '_').toLowerCase();
                    var grabs = this.formGenerator.props.configs[inc2].layout?.dataGrabs;
                    if (grabs) dataGrabs = [...dataGrabs, ...grabs];
                });

                dg = dataGrabs.find((config) => config?.key === dataGrab.include);
                if (dg == null) return;
            }

            var url = substitute(dg.url, this.controller, this.additionalItems);
            if (dg.method === 'GET') {
                makeAxiosGetRequest(url, dg.headers)
                    .then((response) => this.handleResponse(response, dg))
                    .catch((error) => this.handleError(error))
                    .finally(() => {});

                return;
            }

            var postData = this.createPostData(dg, url);
            makeAxiosPostRequest(url, postData, dg.headers)
                .then((response) => this.handleResponse(response, dg))
                .catch((error) => this.handleError(error))
                .finally(() => {});
        });
    }

    createPostData(dataGrab, url) {
        var settings = dataGrab.settings || {};
        if (dataGrab.searchKey) {
            var search = {
                search: '',
                page: 0,
                pageSize: settings.pageSize || 25,
                orderBy: null,
                isAscending: false,
            };

            var ds = this.formGenerator.getDataStore();
            var searches = {
                _searches: ds.searches || {},
            };

            var storedAt = dataGrab.searchItems || 'items';
            if (dataGrab.storeAt) storedAt = `${dataGrab.storeAt}.${storedAt}`;

            searches._searches[dataGrab.searchKey] = {
                storedAt: storedAt,
                url: url,
                body: search,
            };

            this.controller.props.dispatch(setDataStore(this.controller, searches));
            return search;
        }

        var postData = {};
        if (dataGrab.body != null) {
            postData = substitute(dataGrab.body, this.controller, this.additionalItems);
        }

        return postData;
    }

    handleResponse(response, dataGrab, searchInfo = null) {
        // TODO cancel promise before it gets here?
        if (this.uniquePageId !== window['_uniquePageId']) {
            return;
        }

        //  FOR 'LOAD MORE ITEMS' to work the collection needs to be stored at the items property
        var dataStore = !dataGrab.useRawResponse ? handleHttpResponse(response) : response.data;
        dataStore = objectMapper(this.controller, dataStore, dataGrab.mapping);

        var newDataStore = this.controller.props.dataStore;
        if (searchInfo) {
            newDataStore[searchInfo.storedAt] = _.union(
                this.controller.props.dataStore[searchInfo.storedAt],
                dataStore[searchInfo.storedAt]
            );
        } else {
            if (!dataGrab.appendToDataStore) newDataStore = {};

            if (dataGrab.storeAt != null) {
                if (dataGrab.storeAt.toLowerCase() === 'formdata') {
                    var formData = {};
                    this.formGenerator.AddTo(dataStore, formData);
                    this.formGenerator.mergeFormData(formData);
                }

                newDataStore[dataGrab.storeAt] = dataStore;
            } else {
                newDataStore = { ...newDataStore, ...dataStore };
            }
        }

        var storeAction = setDataStoreFromGrabber(this.currentUrl, newDataStore, false);
        this.controller.props.dispatch(storeAction);

        this.controller.props.dispatch(AllowFormRedraw());
        this.grabsLeft--;
        if (this.grabsLeft === 0) {
            this.controller.props.dispatch(allDataLoaded());
            this.formGenerator.setFormState({ dataLoaded: true });
        }

        this.fetchData(dataGrab.dataGrabs);
    }

    handleError(error) {
        if (error.status === 403) {
            var url = '/errors/403';
            this.controller.props.history.push(url);
            this.controller.props.dispatch(pageChanged(url));
            return;
        }
        this.controller.props.dispatch(setError(error));
    }
}
