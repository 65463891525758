import React from 'react';
import { parseBool } from '../../services/globalMethods';
import { buildClassName, buildHeader, deepCopy } from '../forms/formFunctions';
import { getValueFromObjectUsingPath } from '../../services/formDataServices';
import { substitute } from '../../services/stringsubstitutions';

export const HubProperties = ({ controller, layoutItem, formGenerator, collectionStack }) => {
    if (layoutItem.settings == null) layoutItem.settings = {};

    let columns = [];
    let properties = {};
    var key = layoutItem.key;
    if (key && typeof key !== 'object') {
        key = substitute(key, controller, [layoutItem]);
    }

    if (key && typeof key !== 'object') {
        properties = getValueFromObjectUsingPath(controller.props.dataStore, key);
    } else if (key && typeof key === 'object') {
        properties = deepCopy(key);
    }

    if (properties == null) return null;

    if (key != null && layoutItem.settings.ignores != null) {
        properties = deepCopy(properties);
        layoutItem.settings.ignores.forEach((p) => delete properties[p]);
    }

    if (key != null && layoutItem.settings.includes != null && layoutItem.settings.includes.length > 0) {
        var newProperties = {};
        layoutItem.settings.includes.forEach((p) => {
            newProperties[p] = properties[p];
        });

        properties = newProperties;
    }

    if (layoutItem.settings.key != null) {
        let newProp = {};
        properties = properties.forEach((p) => (newProp[p[layoutItem.settings.key]] = p[layoutItem.settings.value]));
        properties = newProp;
    }

    if (layoutItem.settings.updates) {
        Object.keys(layoutItem.settings.updates).forEach((p) => {
            properties[p] = layoutItem.settings.updates[p];
        });
    }

    const lineBreak = getLineBreak(layoutItem.settings);
    const keys = Object.keys(properties);
    var settings = layoutItem?.settings;
    keys.forEach((key, index) => {
        if (typeof properties[key] !== 'string') return;

        var name = !parseBool(layoutItem.settings.usePropertyNames, false) ? getName(key, layoutItem.settings.renames) : key;
        var value = properties[key];

        if (settings.replacements && settings.replacements[key]) {
            var field = {
                header: settings.replacements[key],
            };

            var substitutionStack = deepCopy(collectionStack) || [];
            substitutionStack.reverse();
            var substitutions = [properties, layoutItem.settings, ...substitutionStack];
            value = buildHeader(formGenerator, field, [properties, ...collectionStack], substitutions);
        }

        var columnStyles = { display: 'inline-block', ...settings.childStyles };

        var div = (
            <div>
                <div class="properties__header" style={columnStyles}>
                    <strong style={{ paddingRight: '1ch' }}>{name}:</strong>
                </div>
                {lineBreak}
                {value}
            </div>
        );
        columns.push(div);
    });

    var copyStack = deepCopy(collectionStack);
    copyStack.reverse();
    const className = buildClassName(formGenerator, layoutItem, 'properties', layoutItem.class, copyStack);
    var styles = {
        display: 'grid',
        gap: '.5rem',
        'grid-template-columns': `repeat(${!settings.columns ? 2 : settings.columns}, 1fr)`,
        ...layoutItem.styles,
    };

    return (
        <div className={className} style={styles}>
            {columns}
        </div>
    );
};

const getName = (key, renames) => {
    let name = '';
    let isFirstTime = true;

    if (renames && renames[key]) {
        return renames[key];
    }

    key.split('').forEach((letter) => {
        if (isFirstTime) {
            isFirstTime = false;
            letter = letter.toUpperCase();
            name += letter;
            return;
        }

        if (letter === letter.toUpperCase()) {
            name += ' ';
            name += letter.toLowerCase();
            return;
        }

        name += letter;
    });
    return name;
};

const getLineBreak = (settings) => {
    const inlineSetting = (settings && parseBool(settings.inline, false)) || (settings && parseBool(settings.isInline, false));

    return !inlineSetting ? <br /> : '';
};
