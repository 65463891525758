import React from 'react';
import { DefaultButton, PrimaryButton } from 'office-ui-fabric-react';
import { buildClassName, deepCopy } from './formFunctions';
import { substitute } from '../../services/stringsubstitutions';
import { themeColor } from '../../services/globalMethods';
import { FormColumn } from './FormColumn';

import { FormLabel } from './FormLabel';

export const FormSelector = ({
    field,
    additional,
    formGenerator,
    formGenerator: {
        controller,
        state: { form },
    },
    theme,
    formId,
    collectionStack,
}) => {
    var { onChange, defaultClass } = additional;
    var maxButtons = field.settings.maxButtons || 2;

    var copyStack = deepCopy(collectionStack);
    copyStack.reverse();

    if (field.settings.options == null) debugger;

    field.property = substitute(field.property, controller, copyStack);
    field.header = substitute(field.header, controller, copyStack);

    if (field.settings.options.length > maxButtons || (field.settings.type && field.settings.type.toLowerCase() === 'dropdown')) {
        const newField = { ...field, type: 'dropdown' };
        const controls = formGenerator.createControls(additional.addToValidationFunc, newField, defaultClass, collectionStack);
        if (controls == null) return;

        return <>{controls}</>;
    }

    const fieldItems = Array.isArray(field.settings.options)
        ? field.settings.options
        : controller.props.dataStore.get(field.settings.options);

    const columns = fieldItems.map((fieldItem, sectionIndex) => {
        const onClick = () => {
            var formData = formGenerator.getFormData();
            formData[field.property] = fieldItem.key;

            //            var eventData = {property: field.property, type: field.type, value: fieldItem.key};
            onChange(field, fieldItem.key);
        };

        const styles = {
            selected: {
                root: {
                    backgroundColor: themeColor(theme)('buttonSelectorSelected'),
                    border: 'none',
                },
                rootHovered: {
                    backgroundColor: themeColor(theme)('buttonSelectorSelectedHovered'),
                    border: 'none',
                },
                rootPressed: {
                    backgroundColor: themeColor(theme)('buttonPrimaryPressed'),
                    border: 'none',
                },
            },
            unselected: {
                root: {
                    backgroundColor: themeColor(theme)('buttonSelectorUnSelected'),
                    borderWidth: 1,
                    borderColor: themeColor(theme)('buttonSelectorUnSelectedBorder'),
                    borderStyle: 'solid',
                    color: themeColor(theme)('buttonSelectorUnSelectedPressed'),
                },
                rootHovered: {
                    backgroundColor: themeColor(theme)('buttonSelectorUnSelectedPressed'),
                },
                rootPressed: {
                    backgroundColor: themeColor(theme)('buttonSelectorUnSelectedHovered'),
                },
                labelChecked: {
                    color: 'white',
                },
                labelHovered: {
                    color: 'white',
                },
            },
        };

        const button =
            formGenerator.getFormData()[field.property] === fieldItem.key || fieldItems.length === 1 ? (
                <PrimaryButton
                    className={buildClassName(formGenerator, field, 'form-buttonselector', field.class, collectionStack)}
                    styles={styles.selected}
                    disabled={formGenerator.decode(field, field.disabled)}
                    style={{ width: '100%' }}
                    onClick={onClick}>
                    {fieldItem.text}
                </PrimaryButton>
            ) : (
                <DefaultButton
                    className={buildClassName(formGenerator, field, 'form-buttonselector', field.class, collectionStack)}
                    styles={styles.unselected}
                    disabled={formGenerator.decode(field, field.disabled)}
                    style={{ width: '100%' }}
                    onClick={onClick}>
                    {fieldItem.text}
                </DefaultButton>
            );

        var fieldClass = buildClassName(formGenerator, field, defaultClass, fieldItem.class, collectionStack);
        return (
            <FormColumn id={field.id} className={fieldClass} key={`${sectionIndex}`}>
                {button}
            </FormColumn>
        );
    });

    return (
        <>
            {!!field.header && (
                <FormLabel required={field.required} field={field} additional={additional} collectionStack={collectionStack} />
            )}
            {columns}
        </>
    );
};
