import * as React from 'react';
import { IconButton, PrimaryButton } from 'office-ui-fabric-react';
import { addFormEvent, parseBool, themeColor } from '../../services/globalMethods';
import { buildClassName, buildHeader, deepCopy } from './formFunctions';
import { substitute } from '../../services/stringsubstitutions';
// import { addFormEvent, parseBool, themeColor } from '../../../globalMethods';

const FormButton = ({ formGenerator, field, additional, theme, formId, collectionStack }) => {
    const styles = {
        button: {
            primary: {
                root: {
                    width: '100%',
                    backgroundColor: themeColor(theme)('buttonPrimaryDefault'),
                    color: themeColor(theme)('buttonPrimaryDefaultText'),
                    border: 'none',
                },
                rootHovered: {
                    backgroundColor: themeColor(theme)('buttonPrimaryHovered'),
                    border: 'none',
                },
                rootPressed: {
                    backgroundColor: themeColor(theme)('buttonPrimaryPressed'),
                    border: 'none',
                },
                rootDisabled: {
                    backgroundColor: themeColor(theme)('buttonPrimaryPressed'),
                    color: themeColor(theme)('buttonPrimaryDefaultText'),
                    border: 'none',
                },
            },
            simple: {
                root: {
                    width: '100%',
                    color: themeColor(theme)('buttonSimpleDefaultText'),
                    backgroundColor: themeColor(theme)('buttonSimpleDefault'),
                    'border-color': themeColor(theme)('buttonSimpleDefaultBorder'),
                },
                rootHovered: {
                    backgroundColor: themeColor(theme)('buttonSimpleHovered'),
                    color: themeColor(theme)('buttonSimpleHoveredText'),
                    'border-color': themeColor(theme)('buttonSimpleDefaultBorder'),
                },
                rootPressed: {
                    backgroundColor: themeColor(theme)('buttonSimplePressed'),
                    'border-color': themeColor(theme)('buttonSimpleDefaultBorder'),
                },
            },
        },
    };

    var autoClickFieldName = `${field.property}AutoClicked`;

    var copyStack = deepCopy(collectionStack);
    copyStack.reverse();

    var clearConfirm = () => {
        formGenerator.mergeFormData({ confirm: null });
    };
    var { decode } = additional;
    const onClickDirect = (a, b) => {
        clearConfirm();
        var formData = { ...formGenerator.getFormData() };
        var settings = field.settings || {};
        if (field.onClick || settings.onClick) {
            if (field.onClick) formGenerator.runActions(field, field.onClick, field.settings.value, formData, copyStack);
            if (settings.onClick) formGenerator.runActions(field, settings.onClick, field.settings.value, formData, copyStack);
            formGenerator.updateFormData(formData);
            return;
        }

        var eventData = { property: field.property, type: field.type, value: field.settings.value };
        addFormEvent(formGenerator.state.form, formData, 'setFormData', eventData, field, copyStack);

        formData[field.property] = field.value || field.settings.value;
        if (field.onChange != null) {
            formGenerator.onChange(field, field.settings.value, formData);
        }

        formGenerator.updateFormData(formData);
    };

    const onClick = (a, b) => {
        if (field.settings.confirm != null) {
            var confirm = {
                confirmLabel: 'Yes',
                rejectLabel: 'No',
                onConfirm: () => onClickDirect(a, b),
                onReject: () => clearConfirm(),
                ...field.settings.confirm,
            };
            formGenerator.mergeFormData({ confirm });
            return;
        }

        onClickDirect(a, b);
    };

    var contents = buildHeader(formGenerator, field, collectionStack, [...copyStack, formGenerator.getFormData()]);
    const { variant } = field.settings;

    var isDisabledFunc = () => {
        if (field.disabled == null) return false;
        var formData = formGenerator.getFormData();
        if (parseBool(field.settings.autoClick, false) === true && !decode(field, field.disabled, false, formData, copyStack)) {
            if (formData[autoClickFieldName] == null) {
                onClick();
            }
        }

        return decode(field, field.disabled, false, formData, copyStack);
    };

    if (field.icon) {
        return (
            <>
                {styles && (
                    <IconButton
                        id={field.id}
                        className={buildClassName(formGenerator, field, 'form-button', field.class, copyStack)}
                        styles={styles.button[variant || 'primary']}
                        style={field.styles}
                        //menuProps={menuProps}
                        iconProps={{ iconName: field.icon }}
                        onClick={onClick}
                        title={contents}
                        ariaLabel={field.icon}
                        key={field.property}
                        disabled={isDisabledFunc()}>
                        {contents}
                    </IconButton>
                )}
            </>
        );
    }

    var className = buildClassName(formGenerator, field, ' loki-button', field.class, copyStack);

    var isDisabled = isDisabledFunc();
    if (isDisabled && parseBool(field?.settings?.hideIfDisabled, false) === true) return;

    return (
        <>
            {styles && (
                <button
                    id={field.id}
                    className={className}
                    // styles={styles.button[variant || 'primary']}
                    style={field.styles}
                    onClick={onClick}
                    key={field.property}
                    disabled={isDisabledFunc()}>
                    {contents}
                </button>
            )}
        </>
    );
};

export { FormButton };
