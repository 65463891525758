import { deepCopy } from '../../controls/forms/formFunctions';
import { setDataStore } from '../../redux/actions';
import { getValueFromObjectUsingPath } from '../formDataServices';
import { CustomException, parseBool } from '../globalMethods';

export class SearchService {
    constructor(controller, filterOn, filterTo, filterRequest = null) {
        this.controller = controller;
        this.dispatch = controller.props.dispatch;
        this.searchText = null;
        this.filterOn = filterOn;
        this.filterTo = filterTo;
        this.filterRequest = {
            page: 0,
            pageSize: 25,
            orderBy: null,
            orderByFunc: null,
            groupBy: null,
            isAscending: false,
            ...filterRequest,
        };

        this.filterRequest.isAscending = parseBool(this.filterRequest.isAscending, false);
        console.log(this.filterRequest);
    }

    search(searchText = '') {
        searchText = searchText.trim();
        if (searchText === this.searchText) return;

        var dataStore = this.controller.props.dataStore;
        const source = getValueFromObjectUsingPath(dataStore, this.filterOn);
        if (!source) return;

        var p = this.findItems(source, searchText);
        // if (searchText === '') {
        //     p[this.filterTo] = source;
        // } else {
        //     p = this.findItems(source, searchText);
        // }

        var split = this.filterTo.split('.');
        if (split.length !== 1) {
            // TODO fix this
            throw new CustomException("'.' notation is not allowed on the FilterTo property");

            // const lastProp = split.pop();
            // const exiting = getValueFromObjectUsingPath(this.controller.props.dataStore, split.join('.'));
            // exiting[lastProp] = null;
            // this.dispatch(setDataStore(this.controller, exiting));

            //         this.dispatch(setDataStore(this.controller, { ...p }, false, split.length !== 1));
        }

        this.dispatch(setDataStore(this.controller, { ...p }));
        this.searchText = searchText;
    }

    findItems(items, searchText) {
        var multiplyBy = this.filterRequest.isAscending ? 1 : -1;

        var sortItems = (list) => {
            if (!this.filterRequest.orderBy) return list;

            var dateCompare = (a, b) => new Date(a) - new Date(b);
            var numberCompare = (a, b) => a - b;
            var strCompare = (a, b) => {
                if (a > b) return 1;
                if (a < b) return -1;
                return 0;
            };

            if (this.filterRequest.orderByFunc != null) {
                return list.sort((a, b) => {
                    var lhs = a[this.filterRequest.orderBy];
                    var rhs = b[this.filterRequest.orderBy];
                    return this.filterRequest.orderByFunc(lhs, rhs) * multiplyBy;
                });
            }

            try {
                var retVal1 = list.sort((a, b) => {
                    var lhs = a[this.filterRequest.orderBy];
                    var rhs = b[this.filterRequest.orderBy];
                    var res = dateCompare(lhs, rhs) * multiplyBy;
                    if (isNaN(res)) throw {};
                    return res;
                });

                this.filterRequest.orderByFunc = dateCompare;
                return retVal1;
            } catch {}

            try {
                var retVal2 = list.sort((a, b) => {
                    var lhs = a[this.filterRequest.orderBy];
                    var rhs = b[this.filterRequest.orderBy];

                    var res2 = numberCompare(lhs, rhs) * multiplyBy;
                    if (isNaN(res2)) throw {};
                    return res2;
                });

                this.filterRequest.orderByFunc = numberCompare;
                return retVal2;
            } catch {}

            this.filterRequest.orderByFunc = strCompare;
            return list.sort((a, b) => {
                var lhs = a[this.filterRequest.orderBy];
                var rhs = b[this.filterRequest.orderBy];
                return strCompare(lhs, rhs) * multiplyBy;
            });
        };

        var split = this.filterTo.split('.');
        if (searchText === '' && split.length === 1) {
            var y = {};

            var copiedItems = deepCopy(items);

            if (this.filterRequest.orderBy != null) {
                copiedItems = sortItems(copiedItems);
            }

            y[this.filterTo] = copiedItems.splice(0, this.filterRequest.pageSize);

            return y;
        }

        searchText = searchText.toLowerCase();
        var filteredItems = [];

        items = sortItems(items);

        deepCopy(items).forEach((item) => {
            for (var key in item) {
                if (key === 'icon') continue;

                var row = item[key];
                if (row == null) continue;
                if (Array.isArray(row)) {
                    item[key] = this.findItems(row, searchText);
                    if (item[key].length > 0) {
                        filteredItems.push(item);
                        return;
                    }
                }

                if (row.toString().toLowerCase().indexOf(searchText) > -1) {
                    filteredItems.push(item);
                    return;
                }
            }
        });

        //var count = filteredItems.length;
        filteredItems = filteredItems.splice(0, this.filterRequest.pageSize);

        split.reverse();
        var retVal = filteredItems;
        for (var n = 0; n < split.length; n++) {
            var prop = split[n];
            var x = {};
            x[prop] = retVal;
            retVal = x;
        }

        return retVal;
    }
}
