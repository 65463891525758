import { createBrowserHistory } from 'history';
import { confirmAlert } from 'react-confirm-alert';

const history = createBrowserHistory({
    getUserConfirmation(message, callback) {
        confirmAlert({
            title: 'Unsaved changes',
            message: 'Changes that you made may not be saved. Are you sure that you want to proceed?',
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => {
                        disableRouteTransitionBlocker();
                        window.formFieldModified = false;
                        callback(true);
                    },
                },
                {
                    label: 'No',
                    onClick: () => {
                        callback(false);
                    },
                },
            ],
        });
    },
});

export const registerRouteTransitionBlocker = (message = '') => {
    if (!window.historyBlocker) {
        window.historyBlocker = history.block(message);
    }
};

export const disableRouteTransitionBlocker = () => {
    if (window.historyBlocker && typeof window.historyBlocker === 'function') {
        window.historyBlocker();
        window.historyBlocker = null;
    }
};

export const RouterHistory = history;
