import React from 'react';
import { buildClassName, deepCopy, mergeFields } from '../forms/formFunctions';
import { substitute } from '../../services/stringsubstitutions';
import { FormLabel } from './FormLabel';
import { BpSpinner } from '../BpSpinner';
import { findLists } from '../../services/globalMethods';

export const FormListBox = ({ formGenerator, controller, field, additional, collectionStack }) => {
    if (field.settings.options == null && field.items != null) {
        field.settings.options = field.items;
    }

    var copyStack = deepCopy(collectionStack) || [];
    copyStack.reverse();

    var options = [];
    if (Array.isArray(field.settings.options)) options = field.settings.options;
    else {
        field.settings.options = substitute(field.settings.options, controller, copyStack);
        options = formGenerator.props.controller.props.dataStore.get(field.settings.options);
    }

    if (!options) {
        options = findLists(formGenerator.props.controller.props.page, field.settings.options);
    }

    if (!options) return <BpSpinner />;

    // require as if there is no data then the ?form=annotation throws error
    if (options == null) {
        return null;
    }

    var isStringArray = typeof options[0] === 'string';
    if (field.settings.stringArray || isStringArray)
        options = options.map((p) => {
            return { key: p, text: p };
        });

    if (field.settings.map) {
        options = options.map((p) => {
            var text = null;
            field.settings.map.text.split('|').forEach((item) => {
                if (text != null) return;

                if (p[item] != null && p[item].trim() !== '') text = item;
            });

            if (text == null) text = field.settings.map.text;

            return { key: p[field.settings.map.key], text: p[text] };
        });
    }

    if (!options) return <BpSpinner />;

    // require as if there is no data then the ?form=annotation throws error
    // if (options == null) {
    //     return null;
    // }

    var isStringArray = typeof options[0] === 'string';
    if (field.settings.stringArray || isStringArray)
        options = options.map((p) => {
            return { key: p, text: p };
        });

    if (field.settings.map) {
        options = options.map((p) => {
            var text = null;
            field.settings.map.text.split('|').forEach((item) => {
                if (text != null) return;

                if (p[item] != null && p[item].trim() !== '') text = item;
            });

            if (text == null) text = field.settings.map.text;

            return { key: p[field.settings.map.key], text: p[text] };
        });
    }

    var onClick = (row) => {
        var formData = { ...formGenerator.getFormData() }; //), __collectionStack: collectionStack };
        if (field.onClick) {
            formGenerator.runActions(field, field.onClick, row, formData, copyStack);

            formGenerator.updateFormData(formData, () => {
                formData['formIsValid'] = formGenerator.validateForm(formData);
                formGenerator.updateFormData(formData);
            });

            // formGenerator.setState({ ...formGenerator.state, formData }, () => {
            //     formData['formIsValid'] = formGenerator.validateForm(formData);
            //     formGenerator.setState({ formData });
            // });
        }
    };

    var settings = field.settings || {};
    var rows = options.map((row) => {
        var text = `${row['text']}`;
        return <option value={row['value']} onClick={() => onClick(row)} dangerouslySetInnerHTML={{ __html: text }}></option>;
    });

    var cls = substitute(field.class, formGenerator.props.controller, [...copyStack, formGenerator.getFormData()]);
    cls = buildClassName(formGenerator, field, '', cls, collectionStack);

    var header = null; //field.header;
    if (header) {
        header = substitute(header, formGenerator.props.controller, [...copyStack, formGenerator.getFormData()]);
    }

    return (
        <>
            {!!header && (
                <FormLabel
                    formGenerator={formGenerator}
                    field={field}
                    additional={additional}
                    collectionStack={collectionStack}
                />
            )}
            <select
                id={field.id}
                style={field.styles}
                className={cls}
                name={settings.name || 'listBox'}
                size={settings.size || 10}>
                {rows}
            </select>
        </>
    );
};
