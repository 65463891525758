import * as React from 'react';
import { TextField } from 'office-ui-fabric-react';
import { deepCopy } from './formFunctions';
import { substitute } from '../../services/stringsubstitutions';

export class FormTextArea extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        var { field, additional, formGenerator, collectionStack } = this.props;

        var { defaultValue, onChange, decode, validation, controller } = additional;

        var copyStack = deepCopy(collectionStack);
        copyStack.reverse();
        var formData = { ...formGenerator.getFormData() };

        field.property = substitute(field.property, controller, copyStack);

        var value = formGenerator.getFormData()[field.property];
        if (value == null && defaultValue != null) {
            var defaultVal = substitute(defaultValue, controller, [formData, ...copyStack]);
            if (defaultVal !== '') {
                value = defaultVal;
                formData[field.property] = value;
            }
        }

        if (value != null && field.settings != null && field.settings.formatter != null) {
            var obj = JSON.parse(value);
            value = JSON.stringify(obj, null, 3);
        }
        if (field.readOnly != null) field.readonly = field.readOnly;

        return (
            <TextField
                id={field.id}
                className={`form-textarea ${field.class || ''}`}
                key={field.property}
                label={field.header}
                style={field.styles}
                prefix={field.settings.prefix}
                disabled={decode(field, field.disabled)}
                type={field.subType != null ? field.subType : null}
                multiline={true}
                onGetErrorMessage={validation}
                required={decode(field, field.required)}
                readOnly={decode(field, field.readonly)}
                deferredValidationTime={field.settings.deferredValidationTime}
                validateOnFocusOut={field.settings.validateOnFocusOut}
                placeholder={field.placeholder}
                value={value}
                onChange={(e, val) => {
                    onChange(field, val, copyStack);
                }}
            />
        );
    }
}
