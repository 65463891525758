/* eslint-disable no-template-curly-in-string */
// import _ from 'lodash';
import queryString from 'query-string';
import * as React from 'react';
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { makeAxiosRequest } from '../../services/axiosHelper';
import { showModalWrapper, findForm, handleHttpResponse, Alert } from '../../services/globalMethods';
import { substitute } from '../../services/stringsubstitutions';
import { setError, setPage, setDataStore, showAlert, hideAlert, updateConfigFile, updateForm } from '../../redux/actions';
import { Diagnostics, LOGGING_LEVEL } from '../../services/services';
import { BpSpinner } from '../BpSpinner';
import { globalMethods } from '../../services/globalMethods';
import { MyAlert } from '../MyAlert';
import { getLayout } from '../Layouts';
import { deepCopy } from '../forms/formFunctions';

class Controller extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            error: null,
            pageLoading: false,
            name: 'Controller',
            dataGrabberSettings: {
                search: '',
                page: 0,
                orderBy: null,
                isAscending: false,
            },
        };

        this.state = { ...this.state, name: '', typing: false, typingTimeout: 0 };

        this.callMethod = this.callMethod.bind(this);
        this.makeRequest = this.makeRequest.bind(this);

        var self = this;

        window.addEventListener('popstate', () => {
            var location = window.location;
            // this.props.dispatch(pageChanged(location.pathname + location.search, true));
            location.reload();
        });

        window.systemSettingsUpdated = (event) => {
            this.props.dispatch(setDataStore(this, { system: event.system }));
        };

        window.intellisenseUpdated = (event) => {
            var getEnvName = (key) => {
                const parts = key.split('_');
                return parts.slice(0, 3).join('_');
            };

            event.configs.forEach((item) => {
                var envName = getEnvName(item.name);
                var environment = self.props.intellisense.environments[envName];

                if (environment == null) {
                    self.props.intellisense.environments[envName] = {
                        suggestions: [],
                        strategySuggestions: [],
                        connectorSuggestions: [],
                        replacementSuggestions: [],
                        pipeSuggestions: [],
                        configs: {},
                    };

                    environment = self.props.intellisense.environments[envName];
                }

                environment.configs[item.name] = item.contents;
            });
        };

        window.notificationReceived = (event) => {
            let notifications = this.props.dataStore.notifications || [];
            notifications = [...notifications];
            notifications.push(event);

            this.props.dispatch(setDataStore(this, { notifications }));
        };

        window.dataStoreUpdated = (event) => {};

        window.fileUpdated = ({ fileType, name, contents, isDelete }) => {
            if (fileType === 'js') return;

            if (fileType === 'css') {
                var lastChild = document.head.lastChild;

                // TODO - issue where remove theme from added css and it does bugger all
                if (lastChild.innerText !== '') {
                    // document.head.children.pop();
                    lastChild.innerText = contents;
                    return;
                }

                var s = document.createElement('style');
                s.type = 'text/css';
                s.innerText = contents;
                document.head.appendChild(s);
            }

            if (fileType === 'intellisense') {
                var getEnvName = (key) => {
                    const parts = key.split('_');
                    return parts.slice(0, 3).join('_');
                };

                if (isDelete) {
                    var envName = getEnvName(name);
                    var environment = self.props.intellisense.environments[envName];

                    delete environment.configs[name];
                    // environment.configs[name] = null;
                    return;
                }

                var envName2 = getEnvName(name);
                var environment2 = self.props.intellisense.environments[envName2];
                environment2.configs[name] = contents;
                return;
            }

            if (name === 'system_pagebuilder') return;

            // TODO find a better way to refresh the page
            this.props.dispatch(updateConfigFile(name, contents));
            var todayDate = new Date().toISOString().slice(11, 19);
            this.props.dispatch(setDataStore(this, { fileUpdated: { name: `${name}.${fileType}`, date: todayDate } }));
        };
    }

    render() {
        if (this.state.error) {
            return <div>{this.state.error.toString()}</div>;
        }

        if (this.props.page == null) {
            return <BpSpinner />;
        }

        if (this.props.eventType === 'PAGE_CHANGED') {
            return <span></span>;
        }

        var controller = this;
        var props = controller.props;
        var config = props.page.config;
        var layout = config.layout;

        return (
            <>
                <MyAlert alert={props.alert} controller={controller} />
                {getLayout(controller, { type: 'form', key: layout }, 0, true, [], {}, null)}
            </>
        );
    }

    componentDidUpdate(prevProps, nextProps) {
        if (this.props.eventType === 'PAGE_CHANGED' || this.props.eventType === 'SET_PAGE') {
            if (this.props.page.pageChanged != null) {
                if (this.props.page.pageChanged(this, this.props, this.state)) return;
            }

            var pageTitle = this.props.page.config.layout.pageTitle;
            var title = pageTitle ?? substitute('${siteSettings.pageTitle}', this, [this.props.page, this.props.area]);
            window.document.title = substitute(title, this, [this.props.page, this.props.area]);
        }

        if (prevProps.dataStore !== this.props.dataStore) {
            this.setState({ ...this.state, pageLoading: false });
            this.setState({ ...this.state, update: Date.now() });
            return;
        }

        if (prevProps.selectedItems !== this.props.selectedItems) {
            this.setState({ update: Date.now() });
            return;
        }

        if (this.props.eventType === 'HIDE_ALERT') {
            this.props.dispatch(hideAlert());
            return;
        }

        if (this.state.error !== this.props.error && this.props.eventType === 'SET_ERRORX') {
            this.setState({ ...this.state, error: this.props.error });
            return;
        }
    }
    // refresh() {
    //     this.props.dispatch(setSearch(this.props.search));
    // }
    shouldComponentUpdate(nextProps, nextState) {
        var shouldUpdateFunc = () => {
            if (nextProps.eventType === 'CLEAN_DATASTORE') return false;

            if (nextProps.eventType === 'UPDATE_CONFIGS') return true;
            if (nextProps.eventType === 'SET_DATASTORE') return true;

            if (nextProps.eventType === 'PAGE_CHANGED') {
                var shouldUpdate =
                    this.props.location.pathname + this.props.location.search !==
                    nextProps.location.pathname + nextProps.location.search; // stops double clicking on a link
                return shouldUpdate;
            }

            var shouldUpdate2 =
                this.props.pageLoading ||
                this.props.page == null ||
                this.props.page !== nextProps.page ||
                nextProps.eventType === 'SET_PAGE' ||
                nextProps.eventType === 'SET_ERROR' ||
                nextProps.eventType === 'UPDATE_PAGE' ||
                nextProps.eventType === 'RENDER_PAGE' ||
                nextProps.eventType === 'HIDE_ALERT' ||
                nextProps.eventType === 'SET_ALERT' ||
                this.props.location.pathname !== nextProps.location.pathname;
            //||(nextProps.eventType === 'SET_DATASTORE' && this.props.eventType === 'SET_SEARCH');

            return shouldUpdate2;
        };

        var result = shouldUpdateFunc();
        Diagnostics.log(
            LOGGING_LEVEL.DEBUG,
            `Controller::shouldComponentUpdate. EventType: ${nextProps.eventType}, Result: ${result}`
        );
        return result;
    }

    componentDidMount() {
        if (this.props.eventType) var areaName = this.props.match.params.areaName || 'portal';
        var pageName = this.props.match.params.pageName || 'home';
        var areaId = this.props.match.params.areaId;

        Diagnostics.log(LOGGING_LEVEL.DEBUG, `Controller::componentDidMount. Area: ${areaName}, Page: ${pageName}`);

        var secondaryPageName = this.props.match.params.secondaryPageName || null;
        if (secondaryPageName != null) pageName = secondaryPageName;

        var pageConfigName = areaName + '_' + pageName;
        pageConfigName = pageConfigName.toLowerCase();
        var pageConfig = this.props.configs[pageConfigName];
        if (pageConfig == null) {
            pageConfig = this.props.configs['portal_404'];
            areaName = 'portal';
            pageName = '404';
            if (pageConfig == null) {
                this.props.history.push('/');
                this.props.dispatch(setPage('/'));

                return;
            }
        }

        var factory = window['factory'];
        var areaIndex = areaId == null ? areaName : areaId;
        var area = this.props.areas[areaIndex];
        if (area == null && areaId != null) area = this.props.areas[areaName];
        area.parameterName = areaId;

        // if(area == null)
        //     Alert('Area not found in database');

        var page = factory.buildPage(areaName + '_' + pageName, pageConfig, area, this);
        //   this.props.dispatch(setPage(page));
        this.page = page;
        this.setState({ ...this.state, currentUrl: this.props.location.pathname + this.props.location.search });

        this.searchParams = this.buildAdditionalDataStoreParams();
        if (page.config == null) {
            Alert('error. open debugger an resume (page.config == null)');
            debugger;
        }

        var paths = this.props.location.pathname.substring(1).split('/');
        var _location = {
            ...this.props.location,
            area: paths[0],
            page: paths[1],
        };
        for (let index = 2; index < paths.length; index++) {
            const element = paths[index];
            _location['subPage' + (index - 1).toString()] = element;
        }

        var dataStore = { _location: _location };
        if (this.props.configs['portal'].dataStore) dataStore = { ...dataStore, ...this.props.configs['portal'].dataStore };

        if (area.config && area.config.dataStore) dataStore = { ...dataStore, ...area.config.dataStore };

        var includes;
        try {
            includes = ['portal', ...(this.props.configs['portal'].includes || []), ...(page.config.includes || [])];
        } catch {
            debugger;
        }

        var self = this;
        if (includes == null) includes = [];

        includes.forEach((inc) => {
            var includes = inc.toLowerCase();
            while (includes.indexOf('.') > -1) includes = includes.replace('.', '_');
            if (self.props.configs[includes] == null) {
                return;
            }
            if (self.props.configs[includes].dataStore) {
                dataStore = { ...this.props.configs[includes].dataStore, ...dataStore };
            }
        });

        if (page && page.config && page.config.layout && page.config.layout.dataStore)
            dataStore = { ...dataStore, ...page.config.layout.dataStore };

        this.props.dispatch(setDataStore(this, dataStore));

        this.props.dispatch(setPage(page));
    }

    buildAdditionalDataStoreParams() {
        var retVal = queryString.parse(this.props.location.search);
        if (this.props.match.params.secondaryPageName) {
            retVal['secondaryPageName'] = this.props.match.params.secondaryPageName;
            retVal['solutionId'] = this.props.match.params.secondarySolutionId;
        }

        return retVal;
    }

    // refresh() {
    //     this.props.dispatch(setSearch(this.props.search));
    // }

    callMethod(item, row, subItem, callback, callbackError) {
        if (item.methodName === 'refresh') {
            debugger;
            // if (window.formFieldModified) {
            //     confirmAlert({
            //         title: 'Unsaved changes',
            //         message: 'Changes that you made may not be saved. Are you sure that you want to proceed?',
            //         buttons: [
            //             {
            //                 label: 'Yes',
            //                 onClick: () => {
            //                     window.formFieldModified = false;
            //                     disableRouteTransitionBlocker();
            //                     this.refresh();
            //                 },
            //             },
            //             {
            //                 label: 'No',
            //                 onClick: () => {},
            //             },
            //         ],
            //     });
            // } else
            {
                this.refresh();
                if (this.props.page.refresh != null) this.props.page.refresh(this);
            }
            return;
        }

        if (item.request != null && item.methodName == null) {
            this.makeRequest(item, false, { ...row, ...subItem }, callback, callbackError);
            return;
        }

        if (this.props.page[item.methodName] == null) {
            // add area check here

            var global = new globalMethods();
            if (global[item.methodName] != null) {
                global[item.methodName](this, item);
                return;
            }

            Alert('Missing method: ' + item.methodName);
            return;
        }

        if (row == null) {
            row = {};
        }
        var rowArray = [row];

        var selectedItems = this.props.selectedItems;
        this.props.page[item.methodName](this, item, [...rowArray, ...selectedItems], subItem);
    }

    makeRequest(item, useDataGrabber, row = {}, callback = null, callbackError = null) {
        //  try{

        var record = {};
        var selectedItems = this.props.selectedItems;

        if (item.request.body == null) {
            selectedItems = null;
        }
        // Selected item
        else if (item.request.body === '${selectedItem}') {
            selectedItems = selectedItems[0];
        }
        // form
        else if (item.request.body === '${form}') {
            selectedItems = row.form;
        }
        // form
        else if (item.request.body === '${formData}') {
            selectedItems = row;
        }
        // form property
        else if (item.request.body.toString().includes('${form.')) {
            selectedItems = row;
            var tmp = item.request.body.replace('$', '').replace('{', '').replace('}', '');

            tmp.split('.').forEach((item) => {
                if (selectedItems == null) return;

                selectedItems = selectedItems[item];
            });
        } else if (item.request.useFirstSelectedItem) {
            record = selectedItems[0];

            var json = substitute(item.request.body, this, [row, record, item]);

            json = json.split('\n').join('\\n').split('\t').join('\\t').split('\r').join('\\r');
            selectedItems = JSON.parse(json);
        } else if (item.request.body != null && typeof item.request.body === 'string' && item.request.body.indexOf('[') === 0) {
            Alert('STOP USINGS STRINGS AND COVERT BODY TO OBJECT!!');
        } else {
            //if (typeof item.request.body === 'object' || Array.isArray(item.request.body)) {

            var copy = deepCopy(item.request.body);
            selectedItems = substitute(copy, this, [row, record, item]);
        }

        var self = this;
        var url = substitute(item.request.url, this, [row, record, item, row != null && row.length === 1 ? row[0] : {}]);

        if (useDataGrabber) {
            alert('THIS IS USED');
            // var dg = new DataGXXXrabber(this.props.page, this);
            // dg.createDatXXXaStore(
            //     {
            //         search: '',
            //         page: 0,
            //         orderBy: null,
            //         isAscending: false,
            //     },
            //     [item.request]
            // ); //this.state.sectionIndex

            return;
        }

        if (callback != null) {
            var func = (onEnd) => {
                makeAxiosRequest(item.request.method, url, selectedItems, null, item.request.responseType)
                    .then((response) => {
                        //if (parseBool(item.request.isDownload)) {
                        //     callback(response.data);
                        //     return;
                        // }

                        var data = !item.request.useRawResponse ? handleHttpResponse(response) : response.data;

                        callback(data);
                        if (data != null && item.request.appendToDataStore) {
                            var storedData = data;
                            if (item.request.storeAt) {
                                storedData = {};
                                storedData[item.request.storeAt] = data;
                            }

                            self.props.dispatch(setDataStore(self, storedData));
                        }

                        this.showAlert(item.request.alert, data);

                        if (onEnd != null) onEnd();
                    })
                    .catch((error) => {
                        if (callbackError != null) callbackError(error);
                        else self.props.dispatch(setError(error));

                        if (onEnd != null) onEnd();
                    });
                // .finally(onEnd =>{
                //     if(onEnd != null)
                //         onEnd();
                // });
                return;
            };

            if (item.request.method === 'GET' || (item.request.showModal != null && !item.request.showModal)) {
                func();
            } else {
                showModalWrapper(this, (closeModal) => {
                    func(() => closeModal());
                });
            }

            this.props.dispatch(updateForm(null));
            return;
        }

        var errorObj = null;
        showModalWrapper(this, (closeModal) => {
            makeAxiosRequest(item.request.method, url, selectedItems)
                .then((response) => {
                    var data = !item.request.useRawResponse ? handleHttpResponse(response) : response.data;
                    if (data != null && item.request.appendToDataStore) {
                        var storedData = data;
                        if (item.request.storeAt) {
                            storedData = {};
                            storedData[item.request.storeAt] = data;
                        }

                        self.props.dispatch(setDataStore(self, storedData));
                    }

                    this.showAlert(item.request.alert, data);
                    self.refresh();
                })
                .catch((error) => {
                    console.error(error);
                    errorObj = error;
                })
                .finally(() => {
                    closeModal();
                    if (errorObj != null) this.props.dispatch(setError(errorObj));
                });
        });
    }

    showAlert(alert, data) {
        if (alert) {
            var msg = substitute(alert.message, this, [this.state, data]);

            this.props.dispatch(showAlert(alert.type, msg, alert.title));
        }
    }
}

const mapStateToProps = (state) => ({
    accessPolicies: state.accessPolicies,
    dataStore: state.dataStore,
    intellisense: state.intellisense,
    configs: state.configs,
    areas: state.areas,
    page: state.page,
    eventType: state.eventType,
    eventTime: state.eventTime,
    search: state.search,
    error: state.error,
    //    pageData: state.pageData,
    selectedItems: state.selectedItems,
    selectedItem: state.selectedItems != null && state.selectedItems.length === 1 ? state.selectedItems[0] : null,
    alert: state.alert,
    stillLoading: state.stillLoading,
    area: state.area,
    form: state.form,
});

export default withRouter(connect(mapStateToProps, null)(Controller));
