import * as React from 'react';

import { buildClassName, deepCopy, mergeFields } from '../forms/formFunctions';
import { FormColumn } from '../forms/FormColumn';
import { FormLabel } from '../forms/FormLabel';
import { FormRow } from '../forms/FormRow';

export const ContainerResponsive = ({ formGenerator, field, additional, collectionStack }) => {
    field.settings = field.settings || {};

    var reverseStack = deepCopy(collectionStack);
    reverseStack.reverse();

    const columns = field.items.map((fieldItem) => {
        var fld = { ...fieldItem, class: 'ms-sm12' };
        // var updatedFieldItem = { ...fieldItem };
        // updatedFieldItem.class = 'ms-sm12 ' + fieldItem.class;
        // REmoving this update class DO NOT DELETE THE FOLLOWING COMMENTED OUT CODE
        // if (fieldItem.class && fieldItem.class !== '') {
        //     // remove
        //     var classes = fieldItem.class.split(' ');
        //     classes.forEach((element) => {
        //         if (element.indexOf('ms-') < 0 || fieldItem.type.toLowerCase() !== 'dropdown')
        //             updatedFieldItem.class += element + ' ';
        //     });
        // }

        var newField = mergeFields(fieldItem, fld);

        const column = formGenerator.createControls(additional.addToValidationFunc, newField, '', collectionStack);
        if (column == null) {
            return null;
        }

        var columnClass = fieldItem.columnClass;
        if (columnClass) {
            columnClass = buildClassName(formGenerator, field, '', columnClass, collectionStack);
        }

        var fieldClass = buildClassName(formGenerator, field, '', field.class, collectionStack);
        return <FormColumn className={columnClass || fieldClass}>{column}</FormColumn>;
    });

    var fieldLabel = { header: field.header, class: field.headerClass, style: field.headerStyle };

    //var rowClass = field.settings.rowClass || 'ms-sm12';
    var cls = field.class || 'ms-sm12';
    cls = buildClassName(formGenerator, field, '', cls, collectionStack);

    return (
        <>
            {!!field.header && <FormLabel field={fieldLabel} additional={additional} formGenerator={formGenerator} />}
            {!!field.header && <div className="ms-sm12"></div>}
            {/* <FormRowX className={rowClass} style={field.styles}> */}
            <FormRow className={cls} style={field.styles}>
                {columns}
            </FormRow>
        </>
    );
};
