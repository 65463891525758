import React from 'react';

export const HubCustom = ({ controller, config, layoutItem }) => { 
    var item = 'Custom methodName not found: ' + layoutItem.methodName;
    if (controller.props.page[layoutItem.methodName] != null)
        item = controller.props.page[layoutItem.methodName](controller, controller.props, controller.state);

    return (
        <div id={layoutItem.id} className={`hub-custom ${layoutItem.class || ''}`}>
            {item}
        </div>
    );
};
