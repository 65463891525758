import React from 'react';
import { buildClassName, deepCopy } from './formFunctions';
import { substitute, substituteImage } from '../../services/stringsubstitutions';

export const FormImage = ({ formGenerator, field, collectionStack, additional }) => {
    var substitutionStack = deepCopy(collectionStack) || [];
    substitutionStack.reverse();

    var controller = formGenerator.props.controller;
    var imageName = field.settings.image;
    if (!imageName) return null;
    var image;
    if (imageName.indexOf('icon.') === 0) {
        image = substituteImage('${' + imageName.replace('icon.', '') + '}', controller.props.areas.portal);
    } else {
        image = substituteImage(imageName, controller.props.areas.portal);

        // Do we have an image
        if (image == null || image === '') {
            var text = substitute(imageName, controller, [...substitutionStack, this]);
            image = substituteImage('${' + text + '}', controller.props.areas.portal);
        }
    }

    var cls = buildClassName(formGenerator, field, '', field.class, substitutionStack);

    return (
        <>
            {image && image !== '' ? (
                <img src={image} className={cls} style={field.styles} alt={field.settings.alt} />
            ) : (
                `${imageName} Not found`
            )}
        </>
    );
};
