export const UPDATE_AREA = 'UPDATE_AREA';
export const UPDATE_MAIN_MENU = 'UPDATE_MAIN_MENU';
export const UPDATE_USER_DETAILS = 'UPDATE_USER_DETAILS';
export const UPDATE_CURRENT_PAGE = 'UPDATE_CURRENT_PAGE';
export const UPDATE_TITLE = 'UPDATE_TITLE';
export const UPDATE_TABLE = 'UPDATE_TABLE';
export const UPDATE_SIDE_MENU = 'UPDATE_SIDE_MENU';
export const UPDATE_COMMANDBAR = 'UPDATE_COMMANDBAR';
export const UPDATE_BREADCRUMBS = 'UPDATE_BREADCRUMBS';
export const UPDATE_CUSTOM_AREA = 'UPDATE_CUSTOM_AREA';
export const UPDATE_SELECTED_ITEMS = 'UPDATE_SELECTED_ITEMS';

export const UPDATE_PAGE = 'UPDATE_PAGE';

export const PAGE_CHANGED = 'PAGE_CHANGED';

export const SET_AREA = 'SET_AREA';
export const ADD_AREAS = 'ADD_AREAS';
