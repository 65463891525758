import React from 'react';
import HubFormGenerator from '../controls/hub/HubFormGenerator';
import { substitute } from './stringsubstitutions';
import save from 'save-file';
import { showModalSpinner, hideModalSpinner } from '../redux/actions';
import { deepCopy, getFieldFromFormSection } from '../controls/forms/formFunctions';
import { getValueFromObjectUsingPath } from './formDataServices';
import queryString from 'query-string';

export const globalMethods = function () {};

globalMethods.prototype.download = function (controller, item) {
    controller.makeRequest(item, false, {}, (data) => {
        var fileName = substitute(item.settings.fileName, controller, [item]);
        save(data, fileName);
    });
};

export const showModalWrapper = function (controller, func, spinnerText) {
    controller.props.dispatch(showModalSpinner(spinnerText));
    func(() => controller.props.dispatch(hideModalSpinner()));
};

export const parseBool = function (obj, defaultValue) {
    if (obj == null) {
        if (defaultValue != null) return defaultValue;

        return null;
    }
    var string = null;
    try {
        string = obj.toString();
    } catch {
        debugger;
    }

    try {
        switch (string.toLowerCase().trim()) {
            case 'true':
            case 'yes':
                return true;
            case 'false':
            case 'no':
            case null:
                return false;
            default:
                return defaultValue != null ? defaultValue : obj;
        }
    } catch {
        debugger;
    }
};

export const addFormEvent = function (form, formData, eventType, eventData, item, collectionStack) {
    if (form.settings == null) debugger;

    if (parseBool(form.settings.ignoreEvents, true)) return;

    if (!formData.events) formData.events = [];

    if (formData.events.length > 0 && eventType === 'setFormData') {
        var lastEvent = formData.events[formData.events.length - 1];
        if (eventData.property === lastEvent.property && eventData.type.toLowerCase() === 'textbox') {
            formData.events.pop();
        }
    }

    var evnt = { ...eventData, eventType, date: Date.now(), collectionStack };
    if (item && item.eventData) {
        evnt.additional = item.eventData;
    }

    formData.events.push(evnt);
};

export const decodeDisabled = function (field, constraints, controller, dataStore, data, collectionStack = []) {
    constraints = substitute(constraints, controller, [data, ...collectionStack]);

    if (constraints == null) return false;

    if (constraints === true || constraints === '' || constraints.toString().toLowerCase() === 'true') return true;

    if (constraints === false || constraints.toString().toLowerCase() === 'false') return false;

    if (typeof constraints === 'string') {
        var state = findState(controller.props.page, constraints);
        if (state) constraints = state;
    }

    if (!Array.isArray(constraints)) {
        constraints = [constraints];
    }

    if (!data) data = dataStore;

    var copyStack = deepCopy(collectionStack) || [];
    copyStack.reverse();

    var visibleOrDisabled = false;
    constraints.forEach((constraint) => {
        if (visibleOrDisabled) {
            return;
        }

        // Check each individual constraints
        var useTitleCase = null;
        var counter = Object.keys(constraint).length;
        Object.keys(constraint).forEach((key1) => {
            if (visibleOrDisabled) return;

            if (key1 === 'debugger') {
                counter--;
                debugger;
                return;
            }

            var additions = {};
            if (field.originalPropertyName != null) {
                additions[field.originalPropertyName] = data[field.property];
            }

            var val = constraint[key1];

            var key = substitute(key1, controller, [additions, ...copyStack, data]);
            if (key === 'useTitleCase') {
                useTitleCase = constraint[key];
                counter--;
                return;
            }

            if (val === 'null') {
                val = undefined;
            }

            var realKey = key;

            if (key[key.length - 1] === '!') {
                realKey = key.substr(0, key.length - 1);
            }

            // check for index here is a bit of a hack  but safest option
            if (field.originalPropertyName != null && field.row && realKey.indexOf(field.row) === -1) {
                //      realKey = (field.row || '') + realKey;
            }

            if (useTitleCase) {
                var upperCaseKey = realKey.toUpperCase();
                upperCaseKey = upperCaseKey[0] + key.substring(1);
                if (upperCaseKey[upperCaseKey.length - 1] === '!') upperCaseKey = upperCaseKey.substr(0, key.length - 1);

                realKey = upperCaseKey;
            }

            var retVal = queryString.parse(controller.props.location.search);
            var comparator = getValueFromObjectUsingPath(retVal, realKey);

            if (comparator == null) {
                if (key.indexOf('ds.') === 0) {
                    comparator = getValueFromObjectUsingPath(dataStore, realKey.substring(3));
                }

                try {
                    copyStack.forEach((stackItem) => {
                        if (comparator != null) return;
                        comparator = getValueFromObjectUsingPath(stackItem, realKey);
                    });
                } catch {
                    debugger;
                }

                if (comparator == null) comparator = getValueFromObjectUsingPath(data, realKey);
            }

            comparator = parseBool(comparator);
            if (comparator != null) comparator = comparator.toString();

            var contains = null;
            if (val != null && typeof val === 'object') {
                if (val.operator == null) debugger;
                if (val.operator.toLowerCase() === 'contains') {
                    contains = true;
                    val = val.value;
                } else if (val.operator.toLowerCase() === 'regex') {
                    var result = (comparator || '').toString().match(val.value) != null;
                    if (key[key.length - 1] === '!') result = !result;

                    if (result) counter--;

                    return;
                }
            }

            if (typeof val === 'string') {
                val = substitute(val, controller, [...copyStack, data]);
            }

            val = parseBool(val);
            if (val != null) val = val.toString();

            if (val === 'nullOrEmpty') {
                if (comparator == null) val = null;
                else val = '';
            }

            if (key.indexOf('@') === 0) key = key.substr(1);

            if (key[key.length - 1] === '!') {
                if (contains != null) {
                    var result = contains && comparator != null && comparator.indexOf(val) > -1;
                    if (!result) counter--;
                    return;
                }

                // eslint-disable-next-line eqeqeq
                if (val !== comparator || (comparator === '' && val != null && val !== '')) {
                    counter--;
                }
                return;
            }

            if (contains != null) {
                var result = contains && comparator != null && comparator.indexOf(val) > -1;
                if (result) counter--;
                return;
            }

            // eslint-disable-next-line eqeqeq
            // Final check allows a string is empty ot null scenario
            if (val === comparator || (comparator === '' && val == null && val !== '')) {
                counter--;
            }
        });

        if (counter === 0) {
            visibleOrDisabled = true;
        }
    });

    return visibleOrDisabled;
};

export const getPropertyFromObject = function (data, property) {
    if (property == null || property === '') return data;

    var items = property.split('.');
    items.forEach((key) => {
        if (data == null) return;

        data = data[key];
    });

    return data;
};

export const findFunction = function (page, key) {
    var retVal = findPageItem('functions', page, key);
    return retVal;
};

export const findForm = function (page, key) {
    var retVal = findPageItem('forms', page, key);
    return retVal;
};

export const findTable = function (page, key) {
    var retVal = findPageItem('tables', page, key);
    return retVal;
};

export const findActions = function (page, key) {
    var retVal = findPageItem('actions', page, key);
    return retVal;
};

export const findSection = function (page, key) {
    var retVal = findPageItem('sections', page, key);
    return retVal;
};

export const findState = function (page, key) {
    var retVal = findPageItem('states', page, key, false);
    return retVal;
};

export const findLists = function (page, key) {
    var retVal = findPageItem('lists', page, key, false);
    return retVal;
};

export const findFormSection = function (page, key) {
    var retVal = findPageItem('formSections', page, key);
    return retVal;
};

export const findValidators = function (page, key) {
    var retVal = findPageItem('validators', page, key);
    return retVal;
};

export const findValidationItem = function (page, key) {
    var retVal = findPageItem('validationItems', page, key);
    return retVal;
};

const findPageItem = function (type, page, key, throwAlert = true) {
    // Allows inline placement of objects
    if (typeof key !== 'string') {
        return key;
    }

    const config = page.config;
    const configs = page.controller.props.configs;

    if (config[type] == null) config[type] = [];
    // if (!config[type]) debugger;

    var item = config[type].find((p) => p.key === key);

    var allIncludes = [...config.layout.includes, ...[page.area.name]];
    if (item == null) {
        for (var n = 0; n < allIncludes.length; n++) {
            var name = allIncludes[n];
            while (name.includes('.')) name = name.replace('.', '_');

            var config1 = configs[name.toLowerCase()];
            if (config1 == null) {
                Alert(`${name.toLowerCase()} config not found`);
                continue;
            }

            if (config1[type] == null) continue;

            item = config1[type].find((p) => p.key === key);
            if (item != null) break;
        }
    }

    if (item == null) {
        var portalConfig = configs['portal'];
        if (portalConfig[type] != null) {
            item = portalConfig[type].find((p) => p.key === key);
        }

        // Check portal includes
        if (item == null && portalConfig.includes != null) {
            for (var n = 0; n < portalConfig.includes.length; n++) {
                name = portalConfig.includes[n];
                while (name.includes('.')) name = name.replace('.', '_');

                config1 = configs[name.toLowerCase()];
                if (config1 == null) {
                    Alert(`${name.toLowerCase()} config not found`);
                    continue;
                }

                try {
                    if (config1[type] == null) continue;
                    item = config1[type].find((p) => p.key === key);
                } catch {
                    debugger;
                }

                if (item != null) break;
            }
        }
    }

    if (item == null) {
        if (throwAlert) {
            Alert(`${type} not found: ${key}`);
        }
        return null;
    }

    if (!item.value) debugger;
    return item.value;
};

export const themeColor = (theme) => {
    return (property) => theme()[property];
};

export const areaThemeColor = (theme, themeName) => {
    const currPageTheme = theme[themeName] || theme['default'];
    return (property) => {
        return currPageTheme[property];
    };
};

export const generateFormFromLayoutItems = (controller, formItems) => {
    const layoutItem = {
        key: {
            items: [
                {
                    type: 'responsive',
                    items: formItems,
                },
            ],
        },
    };

    return <HubFormGenerator controller={controller} config={controller.props.page.config} layoutItem={layoutItem} />;
};

export const handleHttpResponse = (response, self, request) => {
    if (response.status >= 200 && response.status < 300) {
        if (response?.data?.data != null)
            // Hack to allow old versions of API to work
            return response?.data.data;
    }
    return response?.data;

    debugger;
    var error = {
        message: response.data,
        status: response.status,
        url: response.request.responseURL,
        request: response.request,
    };

    throw error;

    // if (response.status / 100 === 2 && response.data.success) return response.data.data;

    // var error = {
    //     message: response.data.message || response.data,
    //     status: response.data.statusCode || response.status,
    //     id: response.data.RequestId,
    //     url: response.request.responseURL,
    //     request: response.request,
    // };

    // throw error;
};

export const Alert = (message) => {
    // debugger;
    throw new CustomException(message);
};

export const CustomException = function (message) {
    const error = new Error(message);
    debugger;
    error.code = 'THIS_IS_A_CUSTOM_ERROR_CODE';
    return error;
};

CustomException.prototype = Object.create(Error.prototype);

export const encodeUrl = (url) => {
    // TODO HACK: As encodeUui function does
    // var encodedUrl = encodeURI(url);
    while (url.indexOf('#') > -1) url = url.replace('#', '%23');
    return url;
};

export const CreateControlFromText = (formGenerator, field, header, additional) => {
    var replaceComponent = (str, comp) => {
        var counter = 0;
        let strArr = str.split(new RegExp(`~`, 'ig'));
        return strArr.map((ea, i) => {
            var index = parseInt(ea);
            counter++;
            if (!isNaN(index)) {
                var clsName = 'component component' + counter.toString();
                return <span class={clsName}>{comp[index]}</span>;
            } else {
                var clsName1 = 'text text' + counter.toString();
                if (ea !== '') return <span class={clsName1}>{ea}</span>;
            }
        });
    };

    const { defaultClass } = additional;

    var re = /#\{(.*?)\}/g;
    var replacements = [];
    var match1;
    while ((match1 = re.exec(header))) {
        replacements.push(match1);
    }

    if (replacements.length > 0) {
        var components = [];
        var counter = 0;
        replacements.forEach((match) => {
            var variable = match[1];

            var formSection = {
                type: 'formSection',
                key: variable,
                replacements: field.originalReplacements,
            };

            var item = getFieldFromFormSection(formGenerator.props.controller, formSection);

            const columns = formGenerator.createControls(additional.addToValidationFunc, item, defaultClass);

            header = header.replace('#{' + variable + '}', '~' + counter.toString() + '~');
            counter++;

            components.push(columns == null ? null : columns[0]);
        });

        header = replaceComponent(header, components);
    }

    return header;
};

export const createGuid = () => {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
        var r = (Math.random() * 16) | 0,
            v = c == 'x' ? r : (r & 0x3) | 0x8;
        return v.toString(16);
    });
};

export const replaceAll = (str, from, to) => {
    while (str.indexOf(from) > -1) str = str.replace(from, to);
    return str;
};

export const contains = (str, contains) => {
    if (str == null || typeof str !== 'string') return false;

    return str.indexOf(contains) > -1;
};

export const containsSubstitution = (str) => {
    if (str == null || typeof str !== 'string') return false;

    return str.indexOf('${') > -1;
};
