/*

/ If set will override search key
table.items: [dataStore Key|inline list]

table.searchKey = Used  with data grabber

*/

import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import {
    DetailsList,
    DetailsListLayoutMode,
    Selection,
    SelectionMode,
    CheckboxVisibility,
    ConstrainMode,
    DetailsRow,
} from 'office-ui-fabric-react/lib/DetailsList';
import { setSelectedItems, pageChanged, updateForm, confirmForm, setDataStore } from '../../redux/actions';
import { ContextualMenuItemType } from 'office-ui-fabric-react/lib/ContextualMenu';
import { MarqueeSelection } from 'office-ui-fabric-react/lib/MarqueeSelection';
import _ from 'lodash';
import {
    IconButton,
    Checkbox,
    StickyPositionType,
    Sticky,
    ScrollablePane,
    ScrollbarVisibility,
    Dropdown,
} from 'office-ui-fabric-react';
import { substitute, substituteImage } from '../../services/stringsubstitutions';
import { TextField } from 'office-ui-fabric-react/lib/TextField';
import { NavLink } from 'react-router-dom';

import {
    decodeDisabled,
    findForm,
    findTable,
    findFunction,
    getPropertyFromObject,
    parseBool,
    Alert,
    encodeUrl,
    findFormSection,
} from '../../services/globalMethods';
import { getValueFromObjectUsingPath } from '../../services/formDataServices';
import HubFormGenerator from './HubFormGenerator';
import { deepCopy } from '../forms/formFunctions';
import { BpSpinner } from '../BpSpinner';
import { hubTableTemplates } from './hubTableTemplates';
import Tooltip from '../Tooltip';

export const table_Config = {
    key: 'html',
    value: {
        create: (ct, cf, li) => <HubTable controller={ct} config={cf} layoutItem={li} />,
        overrides: {},
    },
};

const TableWrapper = ({ children }) => {
    return <ScrollablePane scrollbarVisibility={ScrollbarVisibility.auto}>{children}</ScrollablePane>;
};

class HubTable extends React.Component {
    constructor(props) {
        super(props);

        this.getRowItems = this.getRowItems.bind(this);
        this._onColumnClick = this._onColumnClick.bind(this);
        this.renderItemColumn = this.renderItemColumn.bind(this);
        this.updateDimensions = this.updateDimensions.bind(this);
        this.renderRow = this.renderRow.bind(this);

        this._selection = new Selection({
            onSelectionChanged: () => {
                this.updateSelected();

                // On row click
                var onClick = this.tableConfig?.onRowClick ?? this.tableConfig?.settings?.onRowClick;
                if (onClick != null) {
                    var { formGenerator, collectionStack } = this.props;
                    formGenerator.runActions(this.tableConfig, onClick, this.props, formGenerator.getFormData(), collectionStack);
                }
            },
        });

        this.state = {};
        this.tableConfig = null;
    }

    refreshTable() {
        this.setState(Object.assign({}, this.state, { columns: this.createColumns() }));
    }

    onRenderCheckbox(props) {
        return (
            <div style={{ pointerEvents: 'none' }}>
                <Checkbox checked={props.checked} />
            </div>
        );
    }

    onRenderDetailsHeader = (props, defaultRender) => {
        if (this.props.layoutItem.settings && this.props.layoutItem.settings.stickyHeaders) {
            return (
                <Sticky stickyPosition={StickyPositionType.Header} isScrollSynced={true}>
                    {defaultRender({ ...props })}
                </Sticky>
            );
        }
        return defaultRender({ ...props });
    };

    getTableItemsKey() {
        var table = findTable(this.props.page, this.props.layoutItem.key);
        if (table.searchKey) return table.searchKey;
        if (table.dataStore) {
            if (table.dataStore.indexOf('ds.') < 0) return 'ds.' + table.dataStore;
            return table.dataStore;
        }
        return 'default';
    }

    render() {
        var { formGenerator, controller, config, layoutItem, collectionStack } = this.props;

        var copyStack = deepCopy(collectionStack);
        copyStack.reverse();

        this.tableConfig = findTable(this.props.page, layoutItem.key);
        var settings = this.tableConfig.settings || {};

        var ds = controller.props.dataStore;
        var searchInfo = this.state.searchInfo;
        var searchProperty = this.getTableItemsKey();
        if (searchInfo) {
            searchProperty = searchInfo.storedAt;
        } else if (ds._searches && ds._searches[searchProperty] && typeof ds._searches[searchProperty].storedAt == 'string') {
            searchInfo = ds._searches[searchProperty];
            searchProperty = searchInfo.storedAt;
            this.setState({ ...this.state, searchInfo });
        }

        var items = this.getTableItemsKey();
        if (typeof searchProperty === 'string') {
            searchProperty = substitute(searchProperty, controller, [formGenerator.getFormData()]);

            items =
                this.props.data != null
                    ? getPropertyFromObject(this.props.data, searchProperty)
                    : getValueFromObjectUsingPath(controller.props.dataStore, searchProperty);
        }

        if (items == null || this.state.columns == null || this.props.stillLoading) {
            return (
                <div style={styles.spinnerContainer} ref="container">
                    <BpSpinner className="table-spinner-override" />
                </div>
            );
        }

        var count = getValueFromObjectUsingPath(controller.props.dataStore, searchProperty + 'Count');
        var allLoaded = items.length === count; // || this.props.controller.state.dataGrabberSettings.page === 0;

        var loadMoreItems =
            !allLoaded && searchInfo != null ? (
                <a className="hand" onClick={() => this.loadNextPage()}>
                    {' '}
                    Load more items
                </a>
            ) : null;

        var showCounts = parseBool(this.tableConfig.showCounts, true);
        var removeSpacings = parseBool(settings.removeSpacings, false);

        var loadStyles = {
            color: 'black',
            marginTop: '8px',
            marginBottom: '0px',
            marginLeft: '12px',
            fontSize: '12px',
            fontWeight: '600',
        };

        var rowsCount =
            showCounts && count > 0 ? (
                <div style={loadStyles} className="counters">
                    Showing {items.length} of {count} record{count === 1 ? '' : 's'}. {loadMoreItems}{' '}
                </div>
            ) : (
                <div style={loadStyles} className="counters">
                    {loadMoreItems}{' '}
                </div>
            );

        var noRows = showCounts && items.length === 0 ? <div style={loadStyles}>Showing 0 of 0 records.</div> : null;

        var nowRowsStyle = { ...loadStyles, textAlign: 'center', marginTop: '0px' };
        var noRowsBelow = items.length === 0 ? <div style={nowRowsStyle}>No records found.</div> : null;

        const Wrapper = layoutItem.settings && layoutItem.settings.stickyHeaders ? TableWrapper : React.Fragment;
        var tableStyle = config.settings != null && config.settings.styles != null ? config.settings.styles : null;

        if (!showCounts) {
            rowsCount = null;
            noRows = null;
        }

        return (
            <div id={layoutItem.id} className={`hubTable hub-table ${layoutItem.class || ''}`} style={tableStyle}>
                {rowsCount}
                {noRows}

                <div
                    ref="container2"
                    className={'table-container ' + removeSpacings ? '' : 'spacer-table'}
                    data-is-scrollable={true}>
                    <Wrapper>
                        <MarqueeSelection
                            selection={this._selection}
                            isEnabled={this.tableConfig.selectionMode === SelectionMode.multiple}>
                            <div styles={loadStyles}> </div>
                            <DetailsList
                                style={styles.detailsList}
                                items={items}
                                compact={this.tableConfig.compact}
                                columns={this.state.columns}
                                selectionMode={
                                    this.tableConfig.selectionMode == null
                                        ? SelectionMode.multiple
                                        : this.tableConfig.selectionMode
                                }
                                constrainMode={ConstrainMode.horizontalConstrained}
                                setKey="set"
                                checkboxVisibility={
                                    this.tableConfig.checkBoxes ? CheckboxVisibility.always : CheckboxVisibility.hidden
                                }
                                layoutMode={DetailsListLayoutMode.fixedColumns}
                                onRenderItemColumn={this.renderItemColumn}
                                onRenderRow={this.renderRow}
                                onRenderDetailsHeader={this.onRenderDetailsHeader}
                                isHeaderVisible={true}
                                selection={this._selection}
                                selectionPreservedOnEmptyClick={true}
                                ariaLabelForSelectionColumn="Toggle selection"
                                ariaLabelForSelectAllCheckbox="Toggle selection for all items"
                                onRenderMissingItem={this.loadNextPage}
                                onShouldVirtualize={() => false}
                            />
                        </MarqueeSelection>
                    </Wrapper>

                    {noRowsBelow}
                </div>
            </div>
        );
    }
    renderRow(props) {
        const customStyles = { borderBottom: '1pt solid black;' }; // This doesn't work
        if (
            this.tableConfig.groupBy != null &&
            (this.lastGroupName == null || this.lastGroupName !== props.item[this.tableConfig.groupBy])
        ) {
            this.lastGroupName = props.item[this.tableConfig.groupBy];
            // Every other row renders with a different background color
            //customStyles.root = { borderBottom: "1px solid black" };

            return (
                <div styles={customStyles}>
                    <h6 style={{ textTransform: 'uppercase', fontWeight: 'bold' }}>{props.item[this.tableConfig.groupBy]}</h6>
                    <DetailsRow maxHeight={10} {...props} />{' '}
                </div>
            );
        }

        return <DetailsRow {...props} />;
    }
    updateDimensions() {
        //this.setState({ ...this.state, columns:[], redraw:Date.now() });
        this.setState({ ...this.state, columns: null, redraw: Date.now() }, () =>
            this.setState({ ...this.state, columns: this.createColumns(), redraw: Date.now() })
        );
    }

    componentDidMount() {
        window.addEventListener('resize', this.updateDimensions);
        window.addEventListener('menuToggledEvent', this.updateDimensions);
        this.updateDimensions();
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateDimensions);
        window.removeEventListener('menuToggledEvent', this.updateDimensions);
    }

    loadNextPage() {
        var searchInfo = this.state.searchInfo;
        if (!searchInfo) return;

        var formGenerator = this.props.formGenerator;
        formGenerator.loadNextPage(this.getTableItemsKey());
    }

    updateSelected() {
        // if (this.tableConfig.checkBoxes) {
        //     var selectedItems = this._selection.getSelection();
        //     selectedItems.forEach((item) => (item.isSelected = true));
        //     this.props.dispatch(setSelectedItems(selectedItems));
        // } else {
        var selectedItems2 = this._selection.getSelection();

        var formData = {
            selectedRow: selectedItems2.length === 1 ? selectedItems2[0] : null,
            selectedRows: selectedItems2.length !== 1 ? selectedItems2 : null,
        };

        formData[this.tableConfig.dataStore.replace('ds.', '') + '_selectedRow'] =
            selectedItems2.length === 1 ? selectedItems2[0] : null;
        formData[this.tableConfig.dataStore.replace('ds.', '') + '_selectedRows'] =
            selectedItems2.length !== 1 ? selectedItems2 : null;

        this.props.formGenerator.mergeFormData(formData);

        // var { controller, layoutItem } = this.props;
        // var table = findTable(controller.props.page, layoutItem.key);
        // var items = controller.props.dataStore.get(table.items);
        // if (items == null) return;

        // if (this.tableConfig.selectionMode === 1) {
        //     items.forEach((item) => (item.isSelected = false));
        // }
        // selectedItems2.forEach((item) => (item.isSelected = true));

        // var selectedItems3 = items.filter((item) => item.isSelected);
        // this.props.dispatch(setSelectedItems(selectedItems3));
    }

    shouldComponentUpdate(nextProps, nextState) {
        if (nextProps.eventType === 'SET_PAGE') return false;

        if (nextProps.dataStore !== this.props.dataStore) return true;

        if ((nextState = null && nextState.refresh)) {
            this.setState({ ...this.state, refresh: false });
            return true;
        }

        if (this.state.redraw !== nextProps.redraw) return true;

        if (this.props.controller.props.eventType === 'SET_DATASTORE') {
            return true;
        }

        return nextProps.selectedItems === this.props.selectedItems;
    }

    where(collection, constraint) {
        return collection.filter((collectionItem) =>
            Object.keys(constraint).every((key) => {
                if (key === 'isNull') {
                    var result =
                        !collectionItem.hasOwnProperty(constraint[constraint[key]]) ||
                        !collectionItem[constraint[key]] ||
                        collectionItem[constraint[key]] === '';
                    return result;
                }

                if (key === 'isNotNull') {
                    var result2 =
                        collectionItem.hasOwnProperty(constraint[key]) &&
                        collectionItem[constraint[key]] &&
                        collectionItem[constraint[key]] !== '';
                    return result2;
                }

                var constraintValue = constraint[key];
                if (collectionItem.hasOwnProperty(key)) {
                    var constraintIsBoolean =
                        constraintValue.toString().toLowerCase() === 'true' ||
                        constraintValue.toString().toLowerCase() === 'false';
                    if (constraintIsBoolean)
                        return constraint[key].toString().toLowerCase() === collectionItem[key].toString().toLowerCase();
                }

                return collectionItem.hasOwnProperty(key) && constraint[key] === collectionItem[key];
            })
        );
    }

    disableButton(selectedItems, buttonItem) {
        if (buttonItem.canExecute === undefined) return false;

        if (this.props.dataStore == null) return true;

        var notRestartable = this.where(selectedItems, buttonItem.canExecute);
        return !(selectedItems.length !== 0 && selectedItems.length === notRestartable.length);
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.dataStore !== this.props.dataStore) {
            this.setState(Object.assign({}, this.state, { columns: this.createColumns() }));
        }
    }

    showRowFunctionMenu(item) {
        Alert(item.canExecute);
    }

    renderItemColumn(item, index, column) {
        if (index == null) return;

        //   if (column.fieldName == null) {
        try {
            if (column.displayRowFunctions) {
                var items = column.controller.getRowItems(column.props.controller, item);

                var section = findFormSection(column.props.controller.page, 'documents');

                const row = this.props.formGenerator.createControls(
                    {}, // SHould have additional here but doesn;t exist for hub items. TODO Change this to a form item
                    section,
                    '',
                    [...[item, { row: item }, { column: column }], this.props.collectionStack]
                );

                // return <div style={{ maxWidth: '30px', maxHeight: '20px', verticalAlign: 'centre' }}>{row}</div>;
                return (
                    <div style={{ maxWidth: '30px', maxHeight: '20px', verticalAlign: 'centre' }}>
                        <IconButton
                            styles={{ verticalAlign: 'centre' }}
                            text=""
                            split={false}
                            iconProps={{ iconName: 'GlobalNavButton' }}
                            menuIconProps={{ iconName: '' }}
                            menuProps={{
                                items: items,
                            }}>
                            {/* {row} */}
                        </IconButton>
                        {/* {row} */}
                    </div>
                );
            }

            // find correct rule to use
            var matchingItems = _.filter(column.column.columnValues, function (item2) {
                var items =
                    item2.rule != null
                        ? [item].find((item) => Object.keys(item2.rule).every((key) => item[key] === item2.rule[key]))
                        : [item2];

                return items !== undefined;
            });

            var columnInfo = matchingItems.length === 0 ? column.column.columnValues[0] : matchingItems[0];
            columnInfo.linkUrl = columnInfo.linkUrl || column.column.linkUrl;

            var ci2 = null;
            for (let index = 0; index < column.column.columnValues.length; index++) {
                const element = column.column.columnValues[index];

                // Doesn't use new way of decoing rules
                if (!element.visible && index === 0) break;

                var isVisible = element.visible;
                if (isVisible == null) {
                    ci2 = element;
                    break;
                }

                if (!Array.isArray(element.visible)) isVisible = [element.visible];

                if (decodeDisabled(element, isVisible, this.props.controller, column.props.controller.props.dataStore, item)) {
                    ci2 = element;
                    break;
                }
            }

            columnInfo = ci2 || columnInfo;

            if (columnInfo == null) return;

            var icon = null;
            if (columnInfo.icon) {
                var image = substituteImage(columnInfo.icon, column.props.controller.props.areas.portal);

                // Do we have an image
                if (image == null) {
                    var text = substitute(columnInfo.icon, column.props.controller, [item, index, column.column, item, this]);
                    image = substituteImage('${' + text + '}', column.props.controller.props.areas.portal);
                }

                icon = (
                    <>
                        {' '}
                        <img src={image} width="16" height="16" style={{ marginTop: '-2px', marginRight: '8px' }} alt="" />
                    </>
                );
            }

            if (columnInfo.type === 'object') {
                var section = findFormSection(
                    column.props.controller.page,
                    columnInfo.formSection || columnInfo.settings.formSection
                );
                const row = this.props.formGenerator.createControls(
                    {}, // SHould have additional here but doesn;t exist for hub items. TODO Change this to a form item
                    section,
                    '',
                    [...[item, { row: item }, { column: columnInfo }], this.props.collectionStack]
                );
                return (
                    <div style={{ display: 'inline-flex' }}>
                        {icon}
                        {row}
                    </div>
                );
            }

            if (columnInfo.type === 'html') {
                var text = substitute(columnInfo.text, column.props.controller, [item, index, column.column, item, this]);
                return (
                    <>
                        {icon}
                        <span dangerouslySetInnerHTML={{ __html: text }} />
                    </>
                );
            }

            if (columnInfo.template != null) {
                if (columnInfo.template === 'join') {
                    return this.join(column.props.controller, column.column, item);
                } else {
                    var method = column.props.controller.page[columnInfo.template];
                    if (method != null) return <>{method(column.props.controller, column.column, item, this)}</>;

                    method = column.props.controller.page.area[columnInfo.template];
                    if (method != null) return <>{method(column.props.controller, column.column, item, this)}</>;

                    var result = hubTableTemplates(columnInfo, column.props.controller, column.column, item, this);
                    return result;

                    // var method = column.props.page[columnInfo.template];
                    // return <span>{method(column.props.controller, column.column, item, this)}</span>;
                    // TODO : above should be {mainController: column.props.controller, tableController: this, column: column.column}, item
                }
            }

            if (columnInfo.type === 'checkbox') {
                //                var check = <Checkbox label="Disabled checked checkbox"
                var val = getValueFromObjectUsingPath(item, columnInfo.property);
                if (val === true || val === false) return <Checkbox disabled defaultChecked={val} />;

                var check = val != null && val.toString().toLowerCase() === 'true';
                return <Checkbox disabled defaultChecked={check} />;
            }

            if (columnInfo.type === 'dropdown') {
                //                var check = <Checkbox label="Disabled checked checkbox"
                //var val = item[columnInfo.property];
                var options = Array.isArray(columnInfo.settings.options)
                    ? columnInfo.settings.options
                    : column.props.controller.props.dataStore.get(columnInfo.settings.options);

                return (
                    <>
                        {icon}
                        <Dropdown selectedKey={getValueFromObjectUsingPath(item, columnInfo.property)} options={options} />
                    </>
                );
            }

            if (columnInfo.type === 'textarea')
                return (
                    <>
                        {icon}
                        <TextField
                            style={{ maxHeight: '100%', widows: '100%' }}
                            rows={1}
                            multiline
                            autoAdjustHeight
                            value={getValueFromObjectUsingPath(item, column.fieldName)}
                            onChange={(e) => {
                                e.persist();
                                window.formFieldModified = true;
                                // registerRouteTransitionBlocker();
                                item[column.fieldName] = e.target.value;
                                item.isSelected = true;
                                item.hasChanged = true;
                                column.controller.updateSelected();

                                // force refresh
                                column.props.controller.props.dispatch(setDataStore(column.props.controller, { refreshMe: {} }));
                            }}
                        />
                    </>
                );

            if (columnInfo.linkUrl != null) {
                var url = substitute(columnInfo.linkUrl, this.props.controller, [item, columnInfo]);
                url = encodeUrl(url);

                var text = columnInfo.text
                    ? substitute(columnInfo.text, this.props.controller, [item, columnInfo])
                    : getValueFromObjectUsingPath(item, columnInfo.property);

                var label = getValueFromObjectUsingPath(item, columnInfo.property);
                return (
                    <Tooltip label={label}>
                        <NavLink
                            className={'form-link link-url ' + columnInfo.class || ''}
                            onClick={() => {
                                this.props.dispatch(pageChanged(url));
                            }}
                            to={url}>
                            {icon}
                            <span className="link-url-text" dangerouslySetInnerHTML={{ __html: text }} />
                        </NavLink>
                    </Tooltip>
                );
            }

            if (columnInfo.type === 'download') {
                const onDownloadClick = () => {
                    var ci = deepCopy(columnInfo);
                    ci = ci.settings;

                    ci.fileName = substitute(ci.settings.fileName, this.props.controller, [item, item.request]);
                    ci.request.url = substitute(ci.request.url, this.props.controller, [item, item.request]);

                    const downloadCallback = (blob) => {
                        const url = window.URL.createObjectURL(new Blob([blob]));
                        const downloadLink = document.createElement('a');
                        downloadLink.href = url;
                        downloadLink.download = ci.fileName;
                        downloadLink.click();
                    };
                    // add responseType: blob for use in axios;
                    const ciWithResponseType = { ...ci, request: { ...ci.request, responseType: 'blob' } };

                    this.props.controller.callMethod(ciWithResponseType, item, item.request, downloadCallback);
                };

                var text2 = substitute(columnInfo.settings.settings.fileName, this.props.controller, [item, item.request]);

                return (
                    <a href="#" onClick={onDownloadClick}>
                        {text2}
                    </a>
                );
            }

            if (columnInfo.type === 'form') {
                debugger;
                const onClick = (selectedItem) => {
                    var form = findForm(this.props.controller.page, columnInfo.settings.key);
                    var item = {
                        key: columnInfo.settings.key,
                        settings: columnInfo.settings,
                    };

                    var formItem = {
                        title: form.title,
                        description: form.description,
                        class: form.class,
                        styles: form.styles,
                        html: (
                            <HubFormGenerator
                                controller={this.props.controller}
                                config={this.props.config}
                                layoutItem={item}
                                formData={selectedItem}
                            />
                        ),
                        open: true,
                    };

                    this.props.dispatch(setSelectedItems([selectedItem]));
                    this.props.dispatch(updateForm(formItem));
                };

                return (
                    <div className={columnInfo.class} style={columnInfo.settings.styles}>
                        {' '}
                        <a onClick={() => onClick(item)}>
                            <span>
                                {columnInfo.settings.image}
                                {columnInfo.text}
                            </span>{' '}
                        </a>
                    </div>
                );
            }

            var rule = matchingItems[0] != null ? matchingItems[0] : columnInfo.rule;
            if (rule.type == null && column.column.template == null && column.column.linkUrl == null) {
                let text = '';
                if (rule.text) text = substitute(rule.text, this.props.controller, [item, columnInfo]);
                else text = getValueFromObjectUsingPath(item, columnInfo.property);

                return (
                    <Tooltip label={text}>
                        <span className={rule.spanClass} style={column.column.styles}>
                            {icon}
                            <span dangerouslySetInnerHTML={{ __html: text }} />
                        </span>
                    </Tooltip>
                );
            }
        } catch (error) {
            return item.hasChanged;
        }
    }

    join(controller, item, row) {
        var settings = item.columnValues[0].templateSettings;
        var dataStore = controller.props.dataStore;
        if (dataStore.items == null || dataStore.get(settings.secondaryProperty) == null) return null;

        var primaryItem = dataStore.items.find(
            (item) =>
                item[settings.primaryKey] === row[settings.primaryKey] || item[settings.primaryKey] === row[settings.secondaryKey]
        );

        var matchingRow = dataStore.get(settings.secondaryProperty).items.find((item) => {
            var found = item[settings.secondaryKey] === row[settings.primaryKey];
            return found;
        });

        if (matchingRow == null) return;

        row = Object.assign(row, matchingRow, primaryItem);
    }

    getRowItems(controller, row) {
        var counter = 0;
        var items = [];

        var self = this;
        var rowFunctions = findFunction(this.props.controller.props.page, this.tableConfig.rowFunctionsKey);
        if (rowFunctions) {
            rowFunctions.forEach((item) => {
                var sub = (str) => substitute(str, this.props.controller, [row, item]);

                var onClick = () => {
                    controller.callMethod(item, row);
                };

                if (item.type === 'Link') {
                    var url = sub(item.hRef);
                    onClick = () => {
                        self.props.history.push(url);
                        this.props.dispatch(pageChanged(url));
                    };
                }

                if (item.type.toLowerCase() === 'form') {
                    onClick = () => {
                        this.props.dispatch(setSelectedItems([row]));
                        var form = findForm(this.props.controller.props.page, item.key);

                        var formItem = {
                            title: form.title,
                            description: form.description,
                            class: form.class,
                            styles: form.styles,
                            html: (
                                <HubFormGenerator
                                    controller={this.props.controller}
                                    config={this.props.config}
                                    layoutItem={item}
                                    formData={row}
                                />
                            ),
                            open: true,
                        };

                        this.props.dispatch(updateForm(formItem));
                    };
                }

                if (item.type === 'download') {
                    const url = substitute(item.hRef, self.props.controller, [row, item]);
                    onClick = () => {
                        self.props.history.push(url);
                    };
                }

                // Require confirmation?
                if (item.confirm != null) {
                    var currentOnClick = onClick;

                    var form = {
                        title: sub(item.confirm.title),
                        description: sub(item.confirm.description),
                        onYes: () => {
                            currentOnClick();
                        },
                    };

                    onClick = () => this.props.dispatch(confirmForm(form));
                }

                var rowItem = {
                    key: counter++,
                    name: item.name,
                    iconProps: { iconName: item.image },
                    onClick: onClick,
                    item: item,
                    disabled: self.disableButton([row], item),
                    itemType: item.type === 'Separator' ? ContextualMenuItemType.Divider : null,
                    controller: controller,
                };

                if (rowItem.disabled && item.hideIfDisabled != null && item.hideIfDisabled === true) return;

                items.push(rowItem);
            });
        }

        return items;
    }

    createColumns() {
        this.lastGroupName = null;
        var counter = 0;

        this.tableConfig = findTable(this.props.page, this.props.layoutItem.key);

        const CONST = {
            SCREEN_PADDING: 0 + this.tableConfig.padding || 0,
            CHECKBOX: 40,
            ROW_FUNCTIONS: 54,
            COLUMN_PADDING: 24,
            FIRSTCOLUMN_ADDITIONAL_WIDTH: 0,
        };

        // basically wherever in the code is a width property
        var dynamicWidthsCount = 0;
        var dynamicWidthsTotal = 0;

        // var tables =  this.props.config.tables.find(p => p.key === this.props.layoutItem.key);
        // this.tableConfig = tables.value;

        var hasRowFunctions = this.tableConfig.rowFunctionsKey != null;

        var columns = this.tableConfig.columns;
        columns.forEach((item) => {
            if (item.width != null && item.width > 0) {
                dynamicWidthsTotal += item.width;
                dynamicWidthsCount++;
            }
        });

        var container = this.refs.container != null ? this.refs.container : this.refs.container2;
        if (container == null) return null;

        var width = container.clientWidth;
        width -= CONST.SCREEN_PADDING;

        if (this.tableConfig.checkBoxes) width -= CONST.CHECKBOX;

        if (hasRowFunctions) width -= CONST.ROW_FUNCTIONS;

        if (this.props.widthShrink != null) width -= this.props.widthShrink;

        width -= CONST.COLUMN_PADDING * (columns.length - 1);

        width = width - dynamicWidthsTotal - CONST.FIRSTCOLUMN_ADDITIONAL_WIDTH;
        width = width / (columns.length - dynamicWidthsCount);

        var columnIndex = 0;
        const formattedColumns = columns.map((item) => {
            var minWidth = 20;
            var maxWidth = item.width == null ? width : item.width - minWidth;
            if (columnIndex === 0) {
                maxWidth += CONST.FIRSTCOLUMN_ADDITIONAL_WIDTH;
            }

            var key = 'column' + counter++;
            var sortAscending = false;

            if (this.state.sort != null && this.state.sort.key === key) {
                sortAscending = this.state.sort.isAscending;
            }

            columnIndex++;
            return {
                key: key,
                name: (item.header == null ? '' : item.header) + (item.allowGrouping ? '*' : ''),
                fieldName: item.columnValues.length > 0 ? item.columnValues[0].property : null,
                className: item.class,
                minWidth: minWidth,
                maxWidth: maxWidth,
                // minWidth: 210,
                // maxWidth: 350,
                // width: 350,
                // style:{width:'150px'},
                isRowHeader: true,
                isResizable: item.width == null || item.isResizable,
                isSorted: item.sortable,
                isSortedDescending: !sortAscending,
                sortAscendingAriaLabel: 'Sorted A to Z',
                sortDescendingAriaLabel: 'Sorted Z to A',
                onColumnClick: this._onColumnClick,
                data: 'string',
                isPadded: false,
                props: this.props,
                controller: this,
                column: item,
                state: this.state,
                displayRowFunctions: false,
            };
        });

        if (hasRowFunctions) {
            formattedColumns.push({
                key: 'column' + counter++,
                name: '',
                fieldName: null,
                data: 'string',
                isPadded: true,
                props: this.props,
                state: this.state,
                displayRowFunctions: true,
                controller: this,
                className: 'table-selector',
                minWidth: 5,
                maxWidth: 5,
            });
        }
        return formattedColumns;
    }

    _onColumnClick = (element, column) => {
        var searchInfo = this.state.searchInfo;
        if (!searchInfo) return;

        if (!column.column.sortable) return;

        var formGenerator = this.props.formGenerator;
        var searchObject = {};

        if (column.column.allowGrouping && window.event.ctrlKey) {
            if (this.tableConfig.groupBy === column.fieldName) {
                this.tableConfig.groupBy = null;
            } else {
                this.tableConfig.groupBy = column.fieldName;
            }

            searchObject.orderBy = column.fieldName || column.column.orderBy;
            searchObject.isAscending = true;
            formGenerator.updateSearchResults(this.getTableItemsKey(), searchObject);

            return;
        }

        if (!column.column.allowGrouping && this.tableConfig.groupBy != null) this.tableConfig.groupBy = null;
        else if (this.tableConfig.groupBy !== column.fieldName) {
            this.tableConfig.groupBy = null;
        }

        var isAscending = false;
        if (this.state.sort != null && this.state.sort.key === column.key) {
            isAscending = !this.state.sort.isAscending;
        }

        var sort = { key: column.key, isAscending: isAscending };
        this.setState(Object.assign({}, this.state, { sort: sort }));

        searchObject = {};
        searchObject.orderBy = column.fieldName || column.column.orderBy;
        searchObject.isAscending = isAscending;
        formGenerator.updateSearchResults(this.getTableItemsKey(), searchObject);
    };
}

const styles = {
    detailsList: { height: '100%', maxWidth: '100%', overflow: 'visible' },
    spinnerContainer: {
        height: '100%',
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'flex-start',
    },
};

const mapStateToProps = (state) => ({
    accessPolicies: state.accessPolicies,
    area: state.area,
    page: state.page,
    dataStore: state.dataStore,
    pageSettings: state.pageSettings,
    selectedItems: state.selectedItems,
    eventType: state.eventType,
    stillLoading: state.stillLoading,
    refreshMe: state.refreshMe,
});

export default withRouter(connect(mapStateToProps, null)(HubTable));
