import * as React from 'react';
import { substitute } from '../../services/stringsubstitutions';

export class PageBase {
    constructor(name, config, area, controller) {
        this.area = area;
        this.controller = controller;
        this.shortName = name;
        this.config = config;
    }

    pagetitle(dataStore) {
        var re = /\$\{(.*?)\}/g;
        if (re.exec(this.config.layout.title)) {
            var title = substitute(this.config.layout.title, this.controller, [this.config.layout, this.area]);
            title = substitute(title, this.controller, [this.config.layout, this.area]);
            return <span dangerouslySetInnerHTML={{ __html: title }} />;
        }

        return <span>{this.area.name + ': ' + this.config.layout.title}</span>;
    }

    pageTitleDescription(dataStore) {
        if (this.config.layout.description == null)
            return (
                <span>
                    <b>Area id: </b>
                    {this.area.id}
                </span>
            );

        if (this.config.layout.description === '') return null;

        var desc = substitute(this.config.layout.description, this.controller, [
            { areaName: this.area.name },
            this.config.layout,
        ]);
        return <span dangerouslySetInnerHTML={{ __html: desc }} />;
    }

    // titleDetails(dataStore) {
    //     return '';
    // }

    // titleRhs(dataStore) {
    //     return '';
    // }

    // header(dataStore) {
    //     return '';
    // }

    // middle(dataStore) {
    //     return '';
    // }

    footer(dataStore) {
        return '';
    }

    get settings() {
        if (this.config.settings == null) this.config.settings = {};

        if (this.config.settings.pageSize == null) this.config.settings.pageSize = 25;

        return this.config.settings;
    }

    get name() {
        return this.config.layout.title;
    }

    get description() {
        return this.config.layout.description;
    }

    get icon() {
        return this.config.layout.icon;
    }
}

export class AreaBase {
    constructor(config, info, state) {
        this.config = config;
        this.info = info;
        this.state = state;
    }

    get type() {
        return this.config.type;
    }

    get name() {
        return this.info.name[0].toUpperCase() + this.info.name.substring(1);
    }

    get description() {
        return this.info.description;
    }

    get id() {
        return this.info.id;
    }
}
