const palette = {
    gold: '#c8a774',
    goldLight: '#ddc7a7',
    blue: '#4596b4',
    blueLight: '#8acde3',
    grey: '#484644',
    greyMedium: '#7a7773',
    greyLight: '#f3f2f1',
    white: '#ffffff',
    black: '#000000',
    transparent: 'transparent',
};

const themes = {
    default: {
        textFieldBorder: palette.greyMedium,

        expanderDefault: palette.grey,
        expanderDefaultText: palette.white,
        expanderSelected: palette.grey,
        expanderSelectedText: palette.white,

        buttonPrimaryDefault: palette.grey,
        buttonPrimaryDefaultText: palette.white,
        buttonPrimaryHovered: palette.goldLight,
        buttonPrimaryPressed: palette.goldLight,
        buttonPrimaryDisabled: palette.goldLight,
        buttonPrimaryDisabledText: palette.white,

        buttonSimpleDefault: palette.white,
        buttonSimpleDefaultText: palette.blue,
        buttonSimpleDefaultBorder: palette.blue,
        buttonSimpleHovered: palette.blue,
        buttonSimpleHoveredText: palette.white,
        buttonSimplePressed: palette.blue,
        buttonSimpleDisabled: palette.greyLight,
        buttonSimpleDisabledText: palette.greyMedium,

        buttonSubmitDefault: palette.grey,
        buttonSubmitDefaultText: palette.white,
        buttonSubmitDefaultBorder: palette.blue,
        buttonSubmitHovered: palette.greyMedium,
        buttonSubmitHoveredText: palette.white,
        buttonSubmitPressed: palette.grey,
        buttonSubmitDisabled: palette.greyLight,
        buttonSubmitDisabledText: palette.greyMedium,

        buttonSelectorUnSelected: palette.transparent,
        buttonSelectorUnSelectedBorder: palette.grey,
        buttonSelectorUnSelectedPressed: palette.grey,
        buttonSelectorUnSelectredHovered: palette.grey,

        buttonSelectorSelected: palette.grey,
        buttonSelectorText: palette.white,
        buttonSelectorSelectedHovered: palette.greyMedium,
        buttonSelectorSelectedDisabled: palette.greyLight,
        buttonSelectorSelectedBorder: palette.white,
    },
    blue: {
        textFieldBorder: palette.greyLight,

        expanderDefault: palette.greyLight,
        expanderDefaultText: palette.grey,
        expanderSelected: palette.blue,
        expanderSelectedText: palette.white,

        buttonPrimaryDefault: palette.gold,
        buttonPrimaryDefaultText: palette.white,
        buttonPrimaryHovered: palette.goldLight,
        buttonPrimaryPressed: palette.goldLight,
        buttonPrimaryDisabled: palette.goldLight,
        buttonPrimaryDisabledText: palette.white,

        buttonSimpleDefault: palette.white,
        buttonSimpleDefaultText: palette.blue,
        buttonSimpleDefaultBorder: palette.blue,
        buttonSimpleHovered: palette.blue,
        buttonSimpleHoveredText: palette.white,
        buttonSimplePressed: palette.blue,
        buttonSimpleDisabled: palette.greyLight,
        buttonSimpleDisabledText: palette.greyMedium,

        buttonSubmitDefault: palette.grey,
        buttonSubmitDefaultText: palette.white,
        buttonSubmitDefaultBorder: palette.blue,
        buttonSubmitHovered: palette.greyMedium,
        buttonSubmitHoveredText: palette.white,
        buttonSubmitPressed: palette.grey,
        buttonSubmitDisabled: palette.greyLight,
        buttonSubmitDisabledText: palette.greyMedium,

        buttonSelectorUnSelected: palette.white,
        buttonSelectorUnSelectedBorder: palette.blue,
        buttonSelectorUnSelectedPressed: palette.blue,
        buttonSelectorUnSelectredHovered: palette.blue,

        buttonSelectorSelected: palette.blue,
        buttonSelectorText: palette.white,
        buttonSelectorSelectedHovered: palette.blueLight,
        buttonSelectorSelectedDisabled: palette.greyLight,
        buttonSelectorSelectedBorder: palette.white,
    },
};

themes.UNDERWRITING_PAGE = { ...themes.blue };

export { themes };
