import React from 'react';

import { mergeFields } from '../forms/formFunctions';
import { FormLabel } from '../forms/FormLabel';

export const ContainerStack = ({ formGenerator, field, additional, collectionStack }) => {
    //   const { defaultClass } = additional;

    const rows = field.items.map((fld, sectionIndex) => {
        var newField = mergeFields(field, fld);

        const row = formGenerator.createControls(additional.addToValidationFunc, newField, '', collectionStack);
        if (row == null) return null;

        return row;
    });

    var fieldLabel = { header: field.header, class: field.headerClass, style: field.headerStyle };

    return (
        <>
            {!!field.header && <FormLabel field={fieldLabel} additional={additional} formGenerator={formGenerator} />}
            {rows}
        </>
    );
};
