import * as React from 'react';
import { buildClassName, deepCopy, getFieldFromFormSection, mergeFields } from './formFunctions';
import { createObjectFromFormData, getValueFromObjectUsingPath } from '../../services/formDataServices';
import { substitute } from '../../services/stringsubstitutions';
import { contains } from '../../services/globalMethods';

export class FormCollection extends React.Component {
    render() {
        var { formGenerator, field, collectionStack, additional } = this.props;

        if (contains(field.class, 'debugger')) debugger;

        var controller = formGenerator.props.controller;
        var collection = null;
        if (collectionStack == null) collectionStack = [];

        // TODO needs to loop each item in collection stack
        if (collectionStack.length > 0) {
            var last = collectionStack[collectionStack.length - 1];
            collection = getValueFromObjectUsingPath(last, field.key);
        }

        if (collection == null) {
            collection = getValueFromObjectUsingPath(formGenerator.props.dataStore, field.key);
            if (collection == null) collection = createObjectFromFormData(formGenerator.getFormData(), field.key);
        }

        if (collection == null && Array.isArray(field.key)) collection = field.key;

        if (collection == null) collection = [];

        var copyStack = deepCopy(collectionStack);
        copyStack.reverse();

        field.form = substitute(field.form, controller, copyStack);

        try {
            collection.map((item) => {
                return 1;
            });
        } catch {
            debugger;
            // var d = collection.map((item) => {
            //     return 1;
            // });
        }

        var index = 0;

        var children = collection.map((item) => {
            var formItem;

            var additionReplacements = {
                COLLECTION_ITEM: item,
                COLLECTION_INDEX: index++,
                COLLECTION_COUNTER: index,
                COLLECTION_ALL: collection,
            };

            field.settings.formSection = field.settings.formSection || field.section;
            if (typeof field.settings.formSection === 'string') {
                formItem = {
                    ...field.settings,
                    type: 'formSection',
                    key: field.settings.formSection,
                    replacements: { ...field.originalReplacements, ...field.replacements, ...additionReplacements },
                    form: field.form,
                };

                formItem = getFieldFromFormSection(controller, formItem);
            } else {
                formItem = deepCopy(field.settings.formSection);

                if (Array.isArray(formItem)) {
                    formItem = {
                        type: 'responsive',
                        class: 'ms-sm12',
                        items: formItem,
                        form: field.form,
                        replacements: { ...field.originalReplacements, ...field.replacements, ...additionReplacements },
                    };
                }

                if (formItem == null) debugger;
                if (formItem.formSection != null) {
                    formItem.type = 'formSection';
                    formItem.key = formItem.formSection;
                }

                // allows the actually form section name to be passed in the data collection
                if (formItem.type.toLowerCase() === 'formsection') {
                    formItem.key = substitute(formItem.key, formGenerator.props.controller, [
                        item,
                        ...collectionStack,
                        formGenerator.getFormData(),
                    ]);
                }

                // if (field.settings.formSectionName) {
                //     var name = (formItem = substitute(field.settings.formSectionName, formGenerator.props.controller, [
                //         ...collectionStack,
                //         formGenerator.getFormData(),
                //     ]));
                // }

                formItem = {
                    ...field.settings,
                    type: 'formSection',
                    key: formItem,
                    replacements: { ...field.originalReplacements, ...field.replacements, ...additionReplacements },
                    form: field.form,
                    collection: collection,
                };
            }

            if (formItem.replacements != null) {
                formItem.replacements = {};
            }

            formItem.replacements = {
                ...formItem.originalReplacements,
                ...formItem.replacements,
                ...additionReplacements,
            };

            var collectionStackCopy = deepCopy(collectionStack);
            collectionStackCopy.push(item);

            var newField = mergeFields(field, formItem);

            newField.class = buildClassName(formGenerator, newField, '', formItem.class || '', collectionStackCopy);
            newField.styles = { ...newField.styles, ...formItem.styles };

            const uiItem = formGenerator.createControls(additional.addToValidationFunc, newField, '', collectionStackCopy);
            if (field.settings.childTag == null) return <>{uiItem}</>;

            var childStyles = {
                className: buildClassName(formGenerator, field, '', field.settings.childClass, collectionStackCopy),
                style: field.settings.childStyles,
            };

            var child = React.createElement(field.settings.childTag, childStyles, uiItem);
            return <>{child}</>;
        });

        if (field.settings.parentTag === 'empty') return <>{children}</>;
        if (field.settings.parentTag) {
            var styles = {
                className: buildClassName(formGenerator, field, 'hub-collection', field.class, copyStack),
                style: field.styles,
            };

            var parentElement = field.settings.parentTag;
            let retVal = React.createElement(parentElement, styles, children);
            return retVal;
        }

        // var newClass = buildClassName(formGenerator, field, 'hub-collection', field.class, copyStack);
        // TODO  DO NOT ADD CLASS - screws up pagebuilder editor

        try {
            return (
                <div id={field.id} style={field.styles} className="collection">
                    {children}
                </div>
            );
        } catch (e) {
            debugger;
            return <>ERROR</>;
        }
    }
}
