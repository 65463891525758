import axios from 'axios';
import { getToken, aquireToken, storeRedirectUrl } from '../adal';
import AuthB2C from './AuthB2c';
import { encodeUrl } from './globalMethods';
import { appSettings } from './settings';

axios.defaults.baseURL = appSettings.BASE_URL;

axios.interceptors.response.use(
    (response) => {
        return response;
    },
    async (error) => {
        if (error.response == null) return Promise.reject(error);

        let errorResponse = error.response;
        if (
            error.response != null &&
            errorResponse.status === 401 &&
            errorResponse.config &&
            !errorResponse.config.__isRetryRequest
        ) {
            try {
                // Acquire a new token
                const token = await aquireToken(true);

                // Mark the request as a retry
                errorResponse.config.__isRetryRequest = true;

                // Create a new axios instance with the updated headers
                const newAxiosInstance = axios.create({
                    headers: {
                        ...errorResponse.config.headers,
                        'Authorization': 'Bearer ' + token,
                    },
                });

                // Retry the request with the new axios instance
                return newAxiosInstance(errorResponse.config);
            } catch (error) {
                storeRedirectUrl();
                return Promise.reject(error);
            }
        } else if (errorResponse.status === 401) {
            storeRedirectUrl();
            if (appSettings.ADAL_B2C) {
                // const auth = new AuthB2C();
                // auth.login();
            } else {
                // // authContext.login();
            }
        }

        return Promise.reject(error);
    }
);

const getDefaultHeaders = () => {
    return {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getToken()}`,
    };
};

export const makeAxiosPostRequest = (url, data = null, headers) => makeAxiosRequest('POST', url, data, headers);
export const makeAxiosPutRequest = (url, data = null, headers) => makeAxiosRequest('POST', url, data, headers);
export const makeAxiosPatchRequest = (url, data = null, headers) => makeAxiosRequest('PATCH', url, data, headers);
export const makeAxiosGetRequest = (url, headers) => makeAxiosRequest('GET', url, null, headers);
export const makeAxiosDeleteRequest = (url, data = null, headers) => makeAxiosRequest('DELETE', url, data, headers);
export const makeAxiosRequest = (type, url, data, headers = {}, responseType) => {
    const reqHeaders = { ...getDefaultHeaders(), ...headers };
    url = encodeUrl(url);
    return axios({
        method: type,
        responseType: responseType,
        url,
        headers: reqHeaders,
        data,
    });
};
