// import { ControlledEditor, monaco } from '@monaco-editor/react';

// import monaco from '@monaco-editor/loader';

import React, { useRef, useEffect } from 'react';
import * as monaco from 'monaco-editor/esm/vs/editor/editor.api';
import { substitute } from '../../services/stringsubstitutions';
//import monaco from '@monaco-editor/loader';

export class FormDiffEditor extends React.Component {
    constructor(props) {
        super(props);

        // this.handleEditorValueChange = this.handleEditorValueChange.bind(this);
        this.resize = this.resize.bind(this);
        this.state = {};
    }

    componentDidMount() {
        window.addEventListener('resize', this.resize);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.resize);
    }

    resize(val) {
        if (this.state.diffEditor) {
            this.state.diffEditor.layout();
        }
    }

    componentDidUpdate() {
        var { formGenerator, field } = this.props;
        var form = formGenerator.getFormData();

        var originalText = substitute(field.settings.originalText, formGenerator.props.controller, []);
        var modifiedText = substitute(field.settings.modifiedText, formGenerator.props.controller, []);
        var textType = substitute(field.settings.type, formGenerator.props.controller, []);

        if (form?._state?.dataLoaded !== true) return;
        if (originalText === this.state.originalText && modifiedText === this.state.modifiedText) return;
        // if (this.state.originalModel != null) return;

        var diffEditor = this.state.diffEditor;
        if (diffEditor == null) {
            diffEditor = monaco.editor.createDiffEditor(document.getElementById('diff-container'), {
                enableSplitViewResizing: true,
                renderSideBySide: false,
                readOnly: true,
            });
        }

        // var originalText = substitute(field.settings.original, formGenerator.props.controller, []);
        // var modifiedText = substitute(field.settings.modified, formGenerator.props.controller, []);
        var originalModel = monaco.editor.createModel(originalText, textType);
        var modifiedModel = monaco.editor.createModel(modifiedText, textType);
        diffEditor.setModel({
            original: originalModel,
            modified: modifiedModel,
        });

        this.setState({
            originalText: originalText,
            modifiedText: modifiedText,
            originalModel: originalModel,
            modifiedModel: modifiedModel,
            diffEditor: diffEditor,
        });

        // if (form?._state?.dataLoaded === true) {
        //     if (this.state.originalModel == null) {
        // var diffEditor = monaco.editor.createDiffEditor(document.getElementById('diff-container'), {
        //     // You can optionally disable the resizing
        //     enableSplitViewResizing: true,
        //     // Render the diff inline
        //     renderSideBySide: false,
        // });
        // original: substitute(field.settings.original, formGenerator.props.controller, []),
        // modified: substitute(field.settings.modified, formGenerator.props.controller, []),
        // var originalModel = monaco.editor.createModel(
        //     'This line is removed on the right.\njust some text\nabcd\nefgh\nSome more text',
        //     'text/plain'
        // );
        // var modifiedModel = monaco.editor.createModel(
        //     'just some text\nabcz\nzzzzefgh\nSome more text\nThis line is removed on the left.',
        //     'text/plain'
        // );
        // diffEditor.setModel({
        //     original: originalModel,
        //     modified: modifiedModel,
        // });
    }

    // // this.setState({
    // //     originalModel: originalModel,
    // //     modifiedModel: modifiedModel,
    // // });

    render() {
        var className = 'form-diffeditor ' + this.props.field.class || '';
        return <div id="diff-container" className={className} style={this.props.field.styles}></div>;
    }
}
