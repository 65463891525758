import { handleHttpResponse } from './globalMethods';
import { makeAxiosGetRequest } from './axiosHelper';

export const WebApi = function () {
    var content = {};
    var results = {};

    content.getConfig = (blade, page, success, errored) => {
        var bladeKey = blade;
        var pageKey = blade + page;

        var isAsync = false;
        if (results[blade] == null) {
            isAsync = true;

            // Get blade
            results[blade] = null;
            makeAxiosGetRequest('config/area/' + blade + '/' + blade)
                .then((response) => {
                    results[bladeKey] = handleHttpResponse(response);

                    if (results[pageKey] != null) success(results[bladeKey], results[pageKey]);
                })
                .catch((error) => {
                    errored(error);
                });
        }

        if (results[blade + page] == null) {
            isAsync = true;

            // get page
            results[blade + page] = null;
            makeAxiosGetRequest('config/page/' + blade + '/' + page)
                .then((response) => {
                    results[pageKey] = handleHttpResponse(response);

                    if (results[bladeKey] != null) success(results[bladeKey], results[pageKey]);
                })
                .catch((error) => {
                    errored(error);
                });
        }

        if (!isAsync) success(results[blade], results[blade + page]);
    };

    content.dataGrabber = (page, grabberConfig) => {
        return grabberConfig;
    };

    return content;
};
