import { Alert } from './globalMethods';
import { appSettings } from './settings';

export const diagnosticsService = function () {
    var content = {};
    content.raise = function (error) {
        Alert(error);
    };

    content.notNull = (obj, name) => {
        if (obj == null) {
            debugger;
            content.raise('object:' + name + ' is null or undefined');
        }
        return obj;
    };

    content.log = (loggingLevel, text, obj1, obj2) => {
        //  debugger;

        var log = () => {
            console.log(text);
            if (obj1 != null) console.log(obj1);
            if (obj2 != null) console.log(obj2);
        };

        if (loggingLevel >= appSettings.DIAGNOSTICS.LOGGING_LEVEL) {
            log();
        }
    };

    return content;
};
