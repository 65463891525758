import _ from 'lodash';
//import { DetailsListDocumentsExample } from "./table"
import { CommandBar } from 'office-ui-fabric-react/lib/CommandBar';
import { SearchBox } from 'office-ui-fabric-react/lib/SearchBox';
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { confirmForm, updateForm, pageChanged, setDataStore } from '../../redux/actions';
import HubFormGenerator from './HubFormGenerator';

import { findFunction, findForm, decodeDisabled, Alert, parseBool } from '../../services/globalMethods';
import { substitute } from '../../services/stringsubstitutions';
import { ConfirmDialog } from '../ConfirmDialog';
import { deepCopy } from '../forms/formFunctions';
import { getValueFromObjectUsingPath } from '../../services/formDataServices';
import { SearchService } from '../../services/classes/SearchService';

const commandBarStyles = {
    root: { borderBottom: '1px solid lightgray', paddingLeft: 0, paddingRight: 0, backgroundColor: 'white', height: 35 },
};

const searchboxStyles = {
    root: {
        height: 29,
        paddingTop: 0,
        paddingBottom: 0,
    },
};

// TODO:  Remove this completely and do with FormFunctions
// // TODO Simplify this to work with an onClick property that calls an action
class HubCommandBar extends React.Component {
    constructor(props) {
        super(props);

        this.getItems = this.getItems.bind(this);
        this.onClick = this.onClick.bind(this);

        const { filterOn, filterTo, filterMap } = this.props.layoutItem?.settings;
        this.state = { search: '', localSearch: filterOn != null };

        if (filterOn) this.searchService = new SearchService(this.props.controller, filterOn, filterTo, filterMap);
    }

    getSearchKey() {
        var settings = this.props.layoutItem.settings || {};
        return settings.searchKey || 'default';
    }

    componentDidUpdate() {
        if (!this.state.localSearch && !this.state.searchInfo) {
            var searchKey = this.getSearchKey();

            var ds = this.props.formGenerator.getDataStore();
            if (!ds._searches) return;

            var searchInfo = ds._searches[searchKey];
            if (searchInfo) this.setState({ ...this.state, searchInfo: searchInfo });
            return;
        }

        if (!this.state.localSearch) return;

        this.searchService.search('');

        //

        // const { filterOn, filterTo, filterMap } = settings;

        // if (this.props.dataStore[filterTo]) return;

        // const source = getValueFromObjectUsingPath(this.props.dataStore, filterOn);

        // if (!source) return;

        // if (!filterMap || filterMap === '') {
        //     var p = {};
        //     p[filterTo] = source || [];
        //     debugger;
        //     this.props.dispatch(setDataStore(this.props.controller, { ...p }));
        //     return;
        // }

        // const items = this.props.dataStore.get(filterTo);
        // if (source != null && items == null) {
        //     var p = {};
        //     var existingItems = this.props.dataStore.get(filterOn);
        //     if (existingItems != null) {
        //         var dataStoreItems = [];
        //         if (filterMap) {
        //             var dsPropName = filterTo + 'Mapped';
        //             if (this.props.dataStore.get(dsPropName) == null) {
        //                 var mappedItems = [];

        //                 var items2 = this.props.dataStore.get(filterOn) || [];
        //                 items2.forEach((item2) => {
        //                     var newItem = {};
        //                     Object.keys(filterMap).forEach((key) => {
        //                         newItem[key] = substitute(filterMap[key], this.props.controller, [item2]);
        //                     });

        //                     mappedItems.push(newItem);
        //                 });

        //                 var ds = {};
        //                 ds[dsPropName] = mappedItems;
        //                 this.props.dispatch(setDataStore(this.props.controller, ds));
        //                 dataStoreItems = mappedItems;
        //             } else {
        //                 dataStoreItems = this.props.dataStore.get(filterOn);
        //             }
        //         }

        //         p[filterTo] = dataStoreItems;
        //         this.props.dispatch(setDataStore(this.props.controller, { ...p }));
        //     }
        // }
    }

    handleConfirm = (form) => {
        this.props.dispatch(confirmForm(null));
        form.onYes();
    };

    handleReject = (form) => {
        this.props.dispatch(confirmForm(null));
    };

    render() {
        const { controller, layoutItem } = this.props;

        // HACK - need to find out why page is null when using back button from cases to dashboard
        if (!controller.page) return null;
        var functions = findFunction(controller.page, layoutItem.key);
        var items = functions;

        if (items == null || controller.props.dataStore['get'] == null) {
            return null;
        }

        let form = this.props.confirm;
        if (layoutItem.settings != null && layoutItem.settings.ignoreConfirm) {
            form = null;
        }

        return (
            // <div id={layoutItem.id} className={`commandbar-container hub-commandbar ${layoutItem.class || ''}`}>
            <>
                <CommandBar
                    className="command-bar"
                    styles={commandBarStyles}
                    items={this.getItems()}
                    ariaLabel={'Use left and right arrow keys to navigate between commands'}
                />
                {!!form && (
                    <ConfirmDialog
                        title={form.title}
                        description={form.description}
                        confirmLabel="Yes"
                        rejectLabel="No"
                        data={form}
                        onConfirm={this.handleConfirm}
                        onReject={this.handleReject}
                    />
                )}
            </>
        );
    }

    where(collection, constraint) {
        if (constraint.isSelected != null && !constraint.isSelected && collection.length === 0) {
            return true;
        }

        if (constraint.isSelected != null && constraint.isSelected && collection.length !== 0) {
            return true;
        }

        return collection.filter((collectionItem) => {
            var retVal = Object.keys(constraint).every((key) => {
                var reverse = false;
                var originalKey = key;
                if (key.endsWith('!')) {
                    reverse = true;
                    key = key.replace('!', '');
                }
                var result = collectionItem.hasOwnProperty(key) && constraint[originalKey] === collectionItem[key];
                return result !== reverse;
            });
            return retVal;
        });
    }

    disableButton(selectedItems, buttonItem) {
        if (buttonItem.canExecute == null && buttonItem.disabled == null) return false;

        if (this.props.dataStore == null) return true;

        if (buttonItem.disabled != null) {
            var result = decodeDisabled(
                buttonItem,
                buttonItem.disabled,
                this.props.controller,
                this.props.dataStore,
                this.props.formGenerator.getFormData(),
                this.props.collectionStack
            );
            return result;
        }

        // Can execute last
        var matchingItems = this.where(selectedItems, buttonItem.canExecute);

        if (matchingItems === true) return false;

        return !(selectedItems.length !== 0 && selectedItems.length === matchingItems.length);
    }

    onClick = (ctrl, field) => {
        if (field.item && field.item.settings && field.item.settings.useForm) {
            var formattedData = this.props.getFormattedFormData(field.item.settings.useForm);
            field.controller.callMethod(field.item, formattedData, null);
            return;
        }

        // its for a table row
        var row = this.props.controller.props.selectedItems.length === 1 ? this.props.controller.props.selectedItem : {};

        var callback =
            field.item.request && field.item.request.onSuccess
                ? () => {
                      var copyStack = deepCopy(this.props.collectionStack);
                      copyStack.reverse();

                      var formGenerator = this.props.formGenerator;
                      formGenerator.runActions(
                          field.item,
                          field.item.request.onSuccess,
                          {},
                          formGenerator.getFormData(),
                          copyStack
                      );
                  }
                : () => {}; //field.controller.refresh();

        try {
            if (field.onClick) {
                var copyStack = deepCopy(this.props.collectionStack);
                copyStack.reverse();

                var settings = field.settings || {};
                var formData = this.props.formGenerator.getFormData();
                this.props.formGenerator.runActions(field, field.onClick, settings.value, formData, copyStack);
                return;
            }
        } catch {}
        row = { ...this.props.formGenerator.getFormData(), ...row };
        field.controller.callMethod(field.item, row, field.subItem, () => callback());
    };

    search(txt) {
        this.setState({ ...this.state, search: txt });

        const { filterOn, filterTo } = this.props.layoutItem.settings;
        if (txt.length === 0) {
            var p = {};
            p[filterTo] = this.props.dataStore.get(filterOn);
            this.props.dispatch(setDataStore(this.props.controller, { ...p }));
            return;
        }

        var items = this.props.dataStore.get(filterOn);
        var filteredItems = items.filter((row) => {
            for (var key in row) {
                if (row[key] != null && row[key].toString().toLowerCase().indexOf(txt.toLowerCase()) > -1) return true;
            }

            return false;
        });

        var p = {};
        p[filterTo] = filteredItems;
        this.props.dispatch(setDataStore(this.props.controller, { ...p }));
    }

    // Data for CommandBar
    getItems() {
        var counter = 0;
        var self = this;
        var items = [
            {
                key: 'search',
                onRender: (item) => {
                    if (
                        !parseBool(self.props.layoutItem?.settings?.hideFilter, true) ||
                        !parseBool(self.props.layoutItem?.settings?.search, true)
                    ) {
                        return <span />;
                    }

                    return (
                        <span key={counter++} className="searchbox-wrapper">
                            <SearchBox
                                styles={searchboxStyles}
                                disableAnimation
                                value={
                                    self.state.localSearch
                                        ? self.state.search
                                        : !self.state.searchInfo
                                        ? ''
                                        : self.state.searchInfo.body.search
                                }
                                onChange={(e, txt) => {
                                    if (self.state.localSearch) self.search(txt);
                                }}
                                placeholder="Filter"
                                onSearch={(txt) => {
                                    if (self.state.localSearch) return;
                                    if (self.state.searchInfo) {
                                        var searchObject = {};
                                        searchObject.search = txt;
                                        self.props.formGenerator.updateSearchResults(self.getSearchKey(), searchObject);
                                    }
                                }}
                                iconProps={{ iconName: 'Filter' }}
                            />
                        </span>
                    );
                },
            },
        ];

        const customCommandBarButton = (props) => {
            return (
                <span className="commandbar-separator-container">
                    <span className="commandbar-separator"></span>{' '}
                </span>
            );
        };

        const { layoutItem, formGenerator } = this.props;

        var functionItems = findFunction(this.props.controller.page, layoutItem.key);
        _.each(functionItems, (item) => {
            // debugger;
            // var result = decodeDisabled(
            //     item,
            //     item.visible,
            //     this.props.controller,
            //     this.props.dataStore,
            //     this.props.formGenerator.getFormData(),
            //     this.props.collectionStack
            // );
            // if (!result) {
            //     return;
            // }

            const disabled = this.disableButton(this.props.selectedItems, item);
            var x = {
                id: item.id,
                key: counter++,
                name: item.name,
                iconProps: { iconName: item.image },
                onClick: this.onClick,
                style: { fontWeight: 'bolder', fontSize: '13px' },
                item: item,
                subItem: null,
                controller: this.props.controller,
                disabled,
            };

            if (x.disabled && item.hideIfDisabled) return;

            items.push(x);

            switch (item.type.toLowerCase()) {
                case 'button':
                    break;
                case 'navigateto':
                    x.onClick = () => {
                        var url = substitute(item.key, this.props.controller, []);
                        this.props.controller.props.dispatch(pageChanged(url));
                        this.props.controller.props.history.push(url);
                    };
                    break;
                case 'separator':
                    x.commandBarButtonAs = customCommandBarButton;
                    break;
                case 'customForm':
                    x.onClick = () => {
                        var form = {
                            title: item.settings.title,
                            description: item.settings.subTitle,
                            html: this.props.controller.props.page[item.settings.formContent](this.props.controller, item),
                            open: true,
                        };

                        this.props.dispatch(updateForm(form));
                    };
                    break;

                case 'refresh':
                    x.onClick = () => {
                        this.props.formGenerator.refresh(this.props.controller);
                    };

                    break;
                case 'form':
                    x.onClick = () => {
                        var form = findForm(this.props.controller.props.page, item.key);
                        var formData = {
                            title: item.settings ? substitute(form.title, this.props.controller, [item.settings]) : form.title,
                            description: item.settings
                                ? substitute(form.description, this.props.controller, [item.settings])
                                : form.description,
                            settings: { position: 'right', ...item.settings, ...form.settings },
                            class: form.class,
                            styles: form.styles,
                            html: (
                                <HubFormGenerator
                                    controller={this.props.controller}
                                    config={this.props.config}
                                    layoutItem={item}
                                    parentFormGenerator={this.props.formGenerator}
                                />
                            ),
                            open: true,
                        };

                        this.props.dispatch(updateForm(formData));
                    };
                    break;
                case 'dropdownbutton':
                    x.ariaLabel = 'Use left and right arrow keys to navigate';
                    var subItems = [];

                    if (
                        this.props.dataStore.get(item.propertyName) != null &&
                        Array.isArray(this.props.dataStore.get(item.propertyName))
                    ) {
                        this.props.dataStore.get(item.propertyName).forEach((element) => {
                            subItems.push({
                                key: counter++,
                                name: element.name,
                                iconProps: { iconName: element.image },
                                onClick: this.onClick,
                                item: item,
                                subItem: element,
                                controller: this.props.controller,
                            });
                        });

                        x.subMenuProps = {
                            items: subItems,
                        };
                    }

                    break;

                default:
                    Alert('Unknown function type: ' + item.type);
            }

            if (item.confirm != null) {
                var form = {
                    title: item.confirm.title,
                    description: item.confirm.description,
                    onYes: () => {
                        this.onClick(this.props.controller, x);
                    },
                };

                x.onClick = () => this.props.dispatch(confirmForm(form));
            }
        });

        return items;
    }
}

const mapStateToProps = (state) => ({
    accessPolicies: state.accessPolicies,
    selectedItems: state.selectedItems,
    area: state.area,
    event: state.event,
    dataStore: state.dataStore,
    getFormattedFormData: state.getFormattedFormData,
    confirm: state.confirm,
    refreshMe: state.refreshMe,
    eventType: state.eventType,
});

export default withRouter(connect(mapStateToProps, null)(HubCommandBar));
