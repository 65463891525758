import React from 'react';
import { Doughnut } from 'react-chartjs-2';
import { BpSpinner } from '../BpSpinner';

export const HubGraphDoughnut = ({ controller, config, layoutItem }) => {
    var data = controller.props.dataStore[layoutItem.key];
    if (data == null) return <BpSpinner />;

    if (data.length === 0) return 'No data found';

    var name = 'name';
    var count = 'count';

    var settings = layoutItem.settings || {};
    if (settings.labelKey != null) name = settings.labelKey;
    if (settings.countKey != null) count = settings.countKey;

    var labels = { labels: data.map((p) => p[name]) };
    var dataSetData = { data: data.map((p) => p[count]) };

    const actual = {
        ...labels,
        datasets: [
            {
                ...dataSetData,
                backgroundColor: settings.colors || ['#c8a774', '#36A2EB', '#FFCE56', '#F6384F', '#6A2EBC', '#F41E76'],
                hoverBackgroundColor: settings.hoverColors || ['#ddc7a7', '#36A2EB', '#FCE56F', '#F6384F', '#6A2EB3', '#FCE56F'],
            },
        ],
    };

    return <Doughnut id={layoutItem.id} className={`hub-doughnut ${layoutItem.class || ''}`} data={actual} />;
};
