import React from 'react';
import parse from 'html-react-parser';
import { buildClassName, deepCopy } from './formFunctions';
import { substitute } from '../../services/stringsubstitutions';

export const FormHtml = ({ formGenerator, field, collectionStack, additional }) => {
    var substitutionStack = deepCopy(collectionStack) || [];
    substitutionStack.reverse();

    var controller = formGenerator.props.controller;
    var htmlElement = null;
    if (field.settings) {
        Object.keys(field.settings).forEach((item) => {
            var formItemName = field.settings[item];
            var fi = {
                type: 'formSection',
                key: formItemName,
                replacements: field.replacements,
            };

            htmlElement = formGenerator.createControls(
                additional.addToValidationFunc,
                fi,
                additional.defaultClass,
                collectionStack
            );
        });
    }

    if (htmlElement) return htmlElement;

    var text = substitute(field.header, controller, [field, ...substitutionStack]);
    // var parsedText = parse(text);

    return field.styles || field.class ? (
        <span
            id={field.id}
            style={field.styles}
            className={buildClassName(formGenerator, field, 'hub-html', field.class, substitutionStack)}
            dangerouslySetInnerHTML={{ __html: text }}></span>
    ) : (
        <>{parse(text)}</>
    );
};
