import React from 'react';
import { Label } from 'office-ui-fabric-react';
import { CreateControlFromText, parseBool } from '../../services/globalMethods';
import { buildClassName, deepCopy } from './formFunctions';
import { substitute } from '../../services/stringsubstitutions';

export const FormLabel = ({ field, additional, collectionStack = [], ...props }) => {
    if (!field.header) return null;

    var formGenerator = additional.formGenerator;
    if (formGenerator == null) debugger;

    if (field.settings && field.settings.debugger) debugger;

    var copyStack = deepCopy(collectionStack);
    copyStack.reverse();

    var cls = buildClassName(formGenerator, field, 'form-label', field.class, collectionStack);

    var header = substitute(field.header, formGenerator.props.controller, [...copyStack, formGenerator.getFormData()]);

    header = CreateControlFromText(formGenerator, field, header, additional);
    if (field.settings && parseBool(field.settings.html, false)) {
        header = <span style={field.styles} class={cls} dangerouslySetInnerHTML={{ __html: header }} />;
    }

    return (
        <Label id={field.id} className={cls} {...props} style={field.styles}>
            {header}
        </Label>
    );
};
