import AuthB2C from './AuthB2c';
import { contains, replaceAll } from './globalMethods';
import { appSettings } from './settings';

export const substituteImage = function (str, portalArea) {
    if (str == null) return null;

    var re = /\$\{(.*?)\}/g;
    var match = re.exec(str);

    if (match == null) return str;

    var replacements = [match];
    var match1;
    while ((match1 = re.exec(str))) {
        replacements.push(match1);
    }

    //   var retVal = null;
    replacements.forEach((match) => {
        var variable = match[1];

        var retVal = portalArea.config.icons[variable];

        if (retVal == null) {
            // console.error('Unable to find icon variable: ' + match[0]);
            str = null;
            return;
        }

        str = str.replace('${' + variable + '}', retVal);
    });

    return str;
};

export const substitute = function (obj, controller, additionalObjectsInput) {
    if (obj == null) return obj;

    var additionalObjects = [];
    if (!Array.isArray(additionalObjectsInput)) debugger;
    if (additionalObjectsInput == null) additionalObjectsInput = [];
    additionalObjectsInput.forEach((item) => {
        additionalObjects.push(item);
        // if (item && item['__collectionStack'] != null) {
        //     item['__collectionStack'].forEach((item2) => additionalObjects.push(item2));
        // }
    });

    if (typeof obj === 'string') {
        var r = substituteString(obj, controller, additionalObjects);

        if (r != null && typeof r === 'string' && r.length > 5) {
            // eslint-disable-next-line no-eval
            if (r.startsWith('()=>')) return eval(r.substring(5));
            // eslint-disable-next-line no-eval
            if (r.startsWith('() =>')) return eval(r.substring(6));
        }
        return r;
    }

    //        if(typeof obj === 'array'){
    if (Array.isArray(obj)) {
        for (var index = 0; index < obj.length; index++) {
            obj[index] = substitute(obj[index], controller, additionalObjects);
        }

        return obj;
    }

    try {
        if (typeof obj === 'object') {
            var r2 = substituteObjectRecursive([obj], controller, additionalObjects);
            return r2.pop();
        }
    } catch (error) {
        debugger;
        return null;
    }

    return obj;
};

export const getParameter = function (parameter, controller, additionalObjects = []) {
    var retVal;
    if (parameter.startsWith('APP_SETTINGS.')) {
        retVal = appSettings;

        parameter = parameter.replace('APP_SETTINGS.', '');
        var split = parameter.split('.');
        split.forEach((p) => {
            retVal = retVal[p];
        });

        return retVal;
    } else if (parameter.indexOf('[') > -1) {
        var openBracket = parameter.indexOf('[');
        var closeBracket = parameter.indexOf(']');

        var property = parameter.substring(0, openBracket);
        var index = parseInt(parameter.substring(openBracket + 1, closeBracket));

        var obj = getParameter(property, controller, additionalObjects);
        if (obj == null) return null;

        obj = obj[index];
        var newProp = parameter.substring(closeBracket + 1);

        additionalObjects.unshift({ ___temp: obj });

        obj = getParameter('___temp' + newProp, controller, additionalObjects);
        return obj;
    } else if (parameter.indexOf('.') > -1) {
        // if(controller == null)
        //     return ". notation can only be used for app settings or datastore";

        if (controller != null) {
            additionalObjects.push({ ds: controller.props.dataStore });
        }

        // Hack for form data. Ideally we'd chobnvert form to object and not do this
        retVal = getValue(parameter, controller, additionalObjects);
        if (retVal != null) return retVal;

        var split2 = parameter.split('.');
        var firstTime = true;
        split2.forEach((p) => {
            if (firstTime) {
                retVal = getValue(p, controller, additionalObjects);
                firstTime = false;
                return;
            }

            if (retVal == null) return null;
            retVal = retVal[p];
        });

        return retVal;
    }

    return getValue(parameter, controller, additionalObjects);
};

const getValue = function (parameter, controller, additionalObjects = []) {
    if (controller != null) {
        additionalObjects.push(controller.props.selectedItem || {});
        additionalObjects.push(controller.state.dashboardProperties);

        additionalObjects.push(controller.props);
        additionalObjects.push(controller.state);
        additionalObjects.push(controller.props.dataStore);
        additionalObjects.push(controller.buildAdditionalDataStoreParams());

        // hack! fix error occured when promoting applications
        if (controller.page != null) additionalObjects.push(controller.page.settings);

        // additionalObjects.push(controller.page);
        additionalObjects.push({ page: controller.page });
    }

    if (appSettings.ADAL_B2C) {
        const auth = new AuthB2C();
        additionalObjects.push(auth.userInfo());
    }

    var retVal = additionalObjects[parameter];

    additionalObjects.forEach((element) => {
        if (retVal == null && element != null && element[parameter] != null) {
            retVal = element[parameter];
        }
    });

    // DO NOT MOVE the below to above as row items may contain the property values
    if (retVal == null && parameter === 'areaId') {
        return controller.page.area.id;
    }

    if (retVal == null && parameter === 'areaName') {
        return controller.page.area.name;
    }

    if (retVal == null && parameter === 'pageName') {
        return controller.page.name;
    }

    if (retVal == null && parameter === 'pageTitle') {
        return controller.page.config.layout.title;
    }

    if (retVal == null && parameter === 'pageDescription') {
        return controller.page.description;
    }

    return retVal;
};

const substituteObjectRecursive = function (array, controller, additionalObjects) {
    for (let index = 0; index < array.length; index++) {
        const element = array[index];

        // eslint-disable-next-line no-lone-blocks
        for (var key in element) {
            {
                /// TODO HACK
                if (key == null || key === 'parentFormGenerator') continue;
                //|| key !== 'match')

                // try {
                element[key] = substitute(element[key], controller, additionalObjects);
                // } catch (error) {
                //     debugger;
                //     return array;
                // }
            }
        }

        return array;
    }
};

const substituteString = function (str, controller, additionalObjects) {
    if (!str) return str;

    var first = str.indexOf('${');
    if (first === -1) return str;
    if (
        contains(str, 'Get value') &&
        controller.props.dataStore[
            'https://dev-loki-admin3-kv.vault.azure.net/secrets/Admin-AzureManagementAppId/509538c4e82145839cd18252f713defaValue'
        ]
    )
        var multipleItems = false;
    while (first > -1) {
        first += 2;

        var nextVar = str.indexOf('${', first);
        var nextEndVar = str.indexOf('}', first);

        // nested replacements
        if (nextVar > -1 && nextEndVar > nextVar) {
            multipleItems = true;
            first = nextVar;
            continue;
        }

        var variable2 = str.substring(first, nextEndVar);
        if (variable2.indexOf('format') > -1) debugger;

        var parameter2 = getParameter(variable2, controller, additionalObjects);
        if (parameter2 != null && (typeof parameter2 === 'object' || Array.isArray(parameter2))) {
            //if (parameter2 != null && typeof parameter2 === 'object') {
            str = parameter2;
            return str;
        }

        if (parameter2 != null) parameter2 = parameter2.toString(); // when false nothing returned

        var param2 = replaceAllSafe(parameter2, '"', '\\"');

        str = str.replace('${' + variable2 + '}', param2);

        if (!multipleItems && nextVar === -1) break;
        multipleItems = false;
        first = str.indexOf('${');
    }

    if (str && str.endsWith('^3')) {
        str = replaceAll(str, '^3', '^2');
        str = substituteString(str, controller, additionalObjects);
    }

    if (str && str.endsWith('^2')) {
        str = replaceAll(str, '^2', '');
        str = substituteString(str, controller, additionalObjects);
    }

    return str;
};

const replaceAllSafe = function (str, search, replacement) {
    if (str) {
        return str.toString().replace(new RegExp(search, 'g'), replacement);
    }
    return '';
};

//     var re = /\$\{(.*?)\}/g;
//     var replacements = [];
//     var match1;
//     while ((match1 = re.exec(str.toString()))) {
//         replacements.push(match1);
//     }

//     if (replacements.lastIndexOf === 0) return str;

//     replacements.forEach((match) => {
//         var variable = match[1];
//         var parameter = getParameter(variable, controller, additionalObjects);
//         if (match[0] === str && parameter != null && typeof parameter === 'object') {
//             str = parameter;
//             return;
//         }

//         if (parameter != null) parameter = parameter.toString(); // when false nothing returned

//         // removed for yaml save
//         //var param2 = replaceAll(parameter, '"', '\\"');
//         var param2 = replaceAll(parameter, '"', '\\"');
//         str = str.replace('${' + variable + '}', param2);
//     });

//     return str;
// };
