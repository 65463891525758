import { WebApi } from './WebApi';
import { diagnosticsService } from './diagnosticsService';

export const ConfigGrabber = new WebApi();
export const Diagnostics = new diagnosticsService();

export const LOGGING_LEVEL = {
    ERROR: 1,
    INFO: 2,
    DEBUG: 3,
    TRACE: 4,
};
