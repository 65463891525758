// import { ControlledEditor, monaco } from '@monaco-editor/react';

// import monaco from '@monaco-editor/loader';

import React from 'react';

import ReactMonacoEditor from 'react-monaco-editor';
import { KeyCode, KeyMod } from 'monaco-editor';
import { getValueFromObjectUsingPath } from '../../services/formDataServices';
import { deepCopy } from './formFunctions';
import { substitute } from '../../services/stringsubstitutions';

export class FormEditor extends React.Component {
    constructor(props) {
        super(props);

        this.handleEditorValueChange = this.handleEditorValueChange.bind(this);
        this.resize = this.resize.bind(this);
        this.state = {};
    }

    componentDidMount() {
        window.addEventListener('resize', this.resize);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.resize);
    }

    resize(val) {
        if (this.editor) {
            this.editor.layout();
        }
    }

    componentDidUpdate() {
        var { formGenerator, field } = this.props;
        var page = this.getPage();

        if (this.state.currentPage !== page) {
            if (!field.property) return;

            formGenerator.getFormData()[field.property] = page;
            this.setState({ currentPage: page });

            if (this.editor) {
                // clears the undo stack
                // var editorModal = this.editor.getModel();
                // editorModal.setValue(page);
            }

            var formData = formGenerator.getFormData();
            formData[field.property] = page;
            formData[field.property + 'IsDirty'] = false;
            formGenerator.updateFormData(formData);

            // HACK to get intellisense working correctly
            if (formData?.resourcePath != null) {
                // Community Id
                if (formData?.resourcePath.indexOf('environments') === 0) {
                    this.editor.getModel().id = substitute(
                        // eslint-disable-next-line no-template-curly-in-string
                        'community_dev_${selectedRow.serviceId}',
                        formGenerator.props.controller,
                        [formGenerator.getFormData()]
                    );
                } else {
                    // servive id
                    this.editor.getModel().id =
                        'subdomains_' + formData?.resourcePath.replace('/services/', '_').replace('/websites/', '_');
                }
            }
        }
    }

    getPage() {
        var { formGenerator, field } = this.props;

        var value =
            field.key.indexOf('ds.') === 0
                ? getValueFromObjectUsingPath(formGenerator.props.dataStore, field.key)
                : getValueFromObjectUsingPath(formGenerator.getFormData(), field.key);

        return value;
    }

    handleEditorValueChange(newValue) {
        var { formGenerator, field } = this.props;
        if (field.property) {
            var isDirty = newValue !== this.state.currentPage;
            var formData = formGenerator.getFormData();
            formData[field.property] = newValue;
            formData[field.property + 'IsDirty'] = isDirty;
            formGenerator.updateFormData(formData);
        }
    }

    render() {
        var { formGenerator, field } = this.props;

        var fileType = substitute(field.settings?.type, formGenerator.props.controller, [formGenerator.getFormData()]);
        var page = formGenerator.getFormData()[field.property] || this.getPage();
        if (typeof page !== 'string' && fileType === 'json') page = JSON.stringify(page, null, 1);

        var editorDidMount = (editor, monaco) => {
            // this.setState({ ...this.state, editor });
            var self = this;
            self.editor = editor;

            // // Add save functionality
            editor.addCommand(KeyMod.CtrlCmd | KeyCode.KEY_S, function () {
                if (field.settings && field.settings.onSave) {
                    var editorModal = editor.getModel();

                    var copyStack = deepCopy(self.props.collectionStack);
                    copyStack.reverse();

                    formGenerator.runActions(
                        field,
                        field.settings.onSave,
                        editorModal.getValue(),
                        formGenerator.getFormData(),
                        copyStack
                    );
                }
            });

            // // added as undo didn't force onChange call every time
            editor.onDidChangeModelContent((a, b, c) => {
                var newValue = self.editor.getModel().getValue();
                this.handleEditorValueChange(newValue);
            });
        };

        var settings = field.settings || {};
        var options = { minimap: { enabled: true } };

        if (settings.options) {
            var copyStack = deepCopy(this.props.collectionStack);
            copyStack.reverse();
            options = substitute(settings.options, formGenerator.props.controller, [...copyStack, formGenerator.getFormData()]);
        }

        if (this.editor) this.editor.layout();
        return (
            <ReactMonacoEditor
                id="xyz"
                className="monaco-wrapper"
                width={'100%'}
                height={'100%'}
                language={field.settings.language || fileType || 'json'}
                options={options}
                theme="light"
                value={page}
                key={`${this.editor?.editorWidth}-${this.editor?.editorHeight}`}
                //  onChange={(p) => this.handleEditorValueChange(p)}
                editorDidMount={editorDidMount}
                style={{ minWidth: '100%', maxHeight: '100%' }}></ReactMonacoEditor>
        );
    }
}
