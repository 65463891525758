import * as React from 'react';
import { Checkbox } from 'office-ui-fabric-react';
import { parseBool } from '../../services/globalMethods';
import { substitute } from '../../services/stringsubstitutions';
import { buildClassName, deepCopy, wrapWithDiv } from './formFunctions';

export const FormCheckBox = (props) => {
    var { field, formGenerator, additional, controller, collectionStack } = props;
    var { onChange, decode } = additional;

    var copyStack = deepCopy(collectionStack);
    copyStack.reverse();

    field.property = substitute(field.property, controller, copyStack);

    var fd = formGenerator.getFormData();
    var defaultValue = field.defaultValue;
    if (defaultValue != null) defaultValue = substitute(defaultValue, controller, [fd, ...copyStack]);

    var formData = formGenerator.getFormData();
    if (formData[field.property] == null && defaultValue != null && defaultValue !== '' && defaultValue != null) {
        var val = parseBool(defaultValue);
        formData[field.property] = val;
        onChange(field, val);
        if (field.onClick) {
            formGenerator.runActions(field, field.onClick, field.settings.value, fd, copyStack);
        }
    }

    var settings = field.settings || {};
    return wrapWithDiv(
        formGenerator,
        field,
        collectionStack,
        <Checkbox
            {...settings.props}
            id={field.id}
            className={buildClassName(formGenerator, field, 'form-checkbox', field.class, collectionStack)}
            label={substitute(field.header, controller, [formData, field.settings])}
            defaultChecked={defaultValue}
            disabled={decode(field, field.disabled)}
            checked={parseBool(formGenerator.getFormData()[field.property], false)}
            onChange={(e, b, c) => {
                onChange(field, e.target.checked);
                if (field.onClick)
                    formGenerator.runActions(field, field.onClick, field.settings.value, formGenerator.getFormData(), copyStack);
            }}
        />
    );
};
