import { AuthenticationContext } from 'react-adal';
import AuthB2C from './services/AuthB2c';
import { appSettings } from './services/settings';

// export const authContext = new AuthenticationContext({
//     instance: appSettings.ADAL.INSTANCE,
//     tenant: appSettings.ADAL.TENANT,
//     clientId: appSettings.ADAL.CLIENT_ID,
//     endpoints: appSettings.ADAL.ENDPOINTS,
// });

const redirectlUrl = 'redirectUrlKey';

export const storeRedirectUrl = (path) => {
    const desiredPath = path || window.location.pathname;
    localStorage.setItem(redirectlUrl, desiredPath);
};

export const getRedirectUrl = () => {
    const rUrl = localStorage.getItem(redirectlUrl);
    return rUrl;
};

export const clearStoredUrl = () => {
    localStorage.clear(redirectlUrl);
};
export const getToken = () => {
    return sessionStorage.getItem('token');
};

export const aquireToken = async (is401 = false) => {
    var promise = new Promise((resolve, reject) => {
        try {
            if (appSettings.ADAL_B2C) {
                const auth = new AuthB2C();
                const token = auth.getToken(is401);
                if (token) {
                    sessionStorage.setItem('token', token);
                }
                return resolve(token);
            } else {
                // AD
                // authContext.config.redirectUri = window.location.origin;
                // authContext.acquireToken(appSettings.ADAL.APP_ID, function (item, token, y) {
                //     if (token) {
                //         sessionStorage.setItem('token', token);
                //     }
                //     if (token == null) {
                //         storeRedirectUrl();
                //     }
                //     resolve(token);
                //     // AADSTS16000: Either multiple user identities are available
                // });
            }
        } catch (error) {
            console.log('aquireToken error: ', error);
            resolve(null);
        }
    });

    return promise;
};
