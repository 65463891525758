import React from 'react';
import { NavLink } from 'react-router-dom';
import { addFormEvent, contains, containsSubstitution, parseBool } from '../../services/globalMethods';
import { buildClassName, buildHeader, deepCopy } from './formFunctions';
import { pageChanged } from '../../redux/actions';
import { substitute } from '../../services/stringsubstitutions';

export const FormHyperlink = ({ formGenerator, field, additional, collectionStack }) => {
    var substitutionStack = deepCopy(collectionStack) || [];
    substitutionStack.reverse();
    var substitutions = [...substitutionStack, field, field.settings];
    var contents = buildHeader(formGenerator, field, collectionStack, substitutions);

    var controller = formGenerator.props.controller;
    if (!field.onClick) {
        var url = substitute(field.settings.url, controller, [...substitutionStack, this]);
        url = substitute(url, controller, [...substitutionStack, this]);

        var openInNewTab = parseBool(field.settings.newTab, false);

        if (url.indexOf('http') === 0) {
            return (
                <a
                    className={buildClassName(formGenerator, field, '', field.class, collectionStack)}
                    style={field.styles}
                    target={openInNewTab ? '_blank' : ''}
                    rel="noopener noreferrer"
                    href={url}>
                    {contents}
                </a>
            );
        }

        // console.log(url);
        return (
            <NavLink
                className={buildClassName(formGenerator, field, '', field.class, collectionStack)}
                style={field.styles}
                target={openInNewTab ? '_blank' : ''}
                rel="noopener noreferrer"
                to={url}
                onClick={() => {
                    if (!openInNewTab && controller.props.location.pathname.toLowerCase() !== url.toLowerCase()) {
                        controller.props.dispatch(pageChanged(url, true));
                    }
                }}>
                {contents}
            </NavLink>
        );
    }

    var settings = field.settings || {};
    var val = settings.value;
    if (val === 'null') val = null;

    const onClick = () => {
        // TODO Add collection stack yo all on click events everywhere
        var formData = { ...formGenerator.getFormData() }; //, __collectionStack: collectionStack };
        var settings = field.settings || {};
        if (field.onClick || settings.onClick) {
            var copyStack = deepCopy(collectionStack);
            copyStack.reverse();

            if (field.onClick) formGenerator.runActions(field, field.onClick, val, formData, copyStack);

            if (settings.onClick) formGenerator.runActions(field, settings.onClick, val, formData, copyStack);

            formGenerator.updateFormData(formData, () => {
                formData['formIsValid'] = formGenerator.validateForm(formData);
                formGenerator.updateFormData(formData);
            });

            return;
        }

        formData[field.property] = val;

        var eventData = { property: field.property, type: field.type, value: val };
        addFormEvent(formGenerator.state.form, formData, 'setFormData', eventData, field);

        if (field.onChange != null) {
            formGenerator.onChange(field, val, formData);
        }

        formGenerator.updateFormData(formData, () => {
            formData['formIsValid'] = formGenerator.validateForm(formData);
            formGenerator.updateFormData(formData);
        });
    };

    if (containsSubstitution(field.header)) {
        var copy = deepCopy(field);
        copy.header = substitute(field.header, controller, [...substitutionStack, this]);
        contents = buildHeader(formGenerator, copy, collectionStack, substitutions);
    }

    return (
        <span
            id={field.id}
            style={field.styles}
            className={buildClassName(formGenerator, field, 'form-link', field.class, collectionStack)}
            onClick={onClick}>
            {contents}
        </span>
    );
};

// const ButtonLink = styled.span`
//     cursor: pointer;
//     text-decoration: underline;
//     font-size: 15px;
//     width: 100%;
// `;
