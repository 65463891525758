//X
import * as React from 'react';

import PortalMain from './PortalMain';
import { initializeIcons } from '@uifabric/icons';
import { portalInitialized, setDashboardProperties, setDataStore, SetIntellisense } from '../../redux/actions';
import _ from 'lodash';
import { connect } from 'react-redux';
import { BpSpinner } from '../BpSpinner';
import { withRouter } from 'react-router-dom';
import { makeAxiosGetRequest } from '../../services/axiosHelper';
import SplashScreen from './SplashScreen';
import { handleHttpResponse } from '../../services/globalMethods';

import { registerCompletionItems } from '../../services/intellisense';
import AuthB2C from '../../services/AuthB2c';

// Register icons and pull the fonts from the default SharePoint CDN:
initializeIcons();

class Portal extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            pageLoading: true,
            error: null,
        };

        this.init = this.init.bind(this);
    }

    componentDidUpdate(prevProps) {
        if (this.state.pageLoading || this.eventType === 'PORTAL_INITIALISED') {
            this.setState({ pageLoading: false });
            return;
        }
    }

    shouldComponentUpdate(nextProps, nextState) {
        return (
            nextProps.showModalSpinner !== this.props.showModalSpinner ||
            nextState.error != null ||
            nextProps.eventType === 'PORTAL_INITIALISED' ||
            nextProps.eventType === 'UPDATE_FORM' ||
            nextProps.location !== this.props.location ||
            nextProps.hideMenu !== this.props.hideMenu
        );
    }

    componentDidMount() {
        makeAxiosGetRequest('portal/settings').then((response) => {
            var data = handleHttpResponse(response);
            this.props.dispatch(setDataStore(this, data));

            this.init();
        });
    }

    init = async () => {
        var self = this;
        makeAxiosGetRequest('portal/setup')
            .then((response) => {
                var data = handleHttpResponse(response);
                self.props.dispatch(setDashboardProperties(data.dashboard));

                // set all configs
                var configs = {};

                var areas = {};
                var factory = window['factory'];
                data.configItems.forEach((item) => {
                    var name = item.name;
                    while (name.includes('.')) name = name.replace('.', '_');
                    name = name.toLowerCase();

                    if (item.isAreaConfig) areas[name] = factory.buildArea(name, item.config, item, self.props.state);

                    configs[name] = item.config;
                });

                // Update portal categories
                var portalArea = areas['portal'];
                var initialisePortal = true;

                // setup monaco
                // const b2c = new AuthB2C();
                // var user = b2c.userInfo();
                // if (user.user.extension_CommunityId != null) {
                //     makeAxiosGetRequest('community/setup').then((response) => {
                //         var data2 = handleHttpResponse(response);
                //         data2.configItems.forEach((item) => {
                //             var name = item.name;
                //             while (name.includes('.')) name = name.replace('.', '_');
                //             name = name.toLowerCase();

                //             if (item.isAreaConfig) areas[name] = factory.buildArea(name, item.config, item, self.props.state);

                //             configs[name] = item.config;
                //         });

                //         registerCompletionItems(self, data.completionItems, configs, portalArea.config.includes);
                //         self.props.dispatch(SetIntellisense(self.intellisense));
                //     });
                // } else {
                registerCompletionItems(self, data.completionItems, configs, portalArea.config.includes);
                self.props.dispatch(SetIntellisense(self.intellisense));
                // }

                if (initialisePortal) {
                    self.props.dispatch(portalInitialized(areas, configs, portalArea, data.cssFiles));
                }
            })
            .catch((error) => {
                this.setState({ ...this.state, error: error });
            });
    };

    render() {
        if (this.state.pageLoading) return <SplashScreen />;

        if (this.state.error) {
            if (this.state.error.response == null) {
                var error = `Unable to connect to dashboard backend:<br /> ${this.state.error?.message}<br /> ${this.state.error?.stack}`;
                debugger;
                return <SplashScreen Error={error} />;
            }

            if (this.state.error.response.status === 401 || this.state.error.response.statusCode === 401) {
                return <SplashScreen />;
            }

            if (this.state.error.response.data && this.state.error.response.statusCode === 403) {
                return <SplashScreen Error="403: Sorry, your name's not on the list" />;
            }

            var error = `Oopps we've hit a snag: <br />${this.state.error.message}`;
            return <SplashScreen Error={error} />;
        }

        this.loaded = true;

        // var config =
        //     this.props.areas &&
        //     this.props.areas['portal'] &&
        //     this.props.areas['portal'].config.categories.find((p) => p.key === 'default');

        // const rootClasses = ColorClassNames.themePrimary + ' ' + FontClassNames.mediumPlus;

        return (
            <>
                {this.props.showModalSpinner ? (
                    <div className="modal">
                        <BpSpinner label={this.props.spinnerText || 'Please wait...'} />
                    </div>
                ) : null}

                <PortalMain />
            </>
        );
    }
}

const mapStateToProps = (state, ownProps) => ({
    state: state,
    eventType: state.eventType,
    dataStore: state.dataStore,
    accessPolicies: state.accessPolicies,
    areas: state.areas,
    showModalSpinner: state.showModalSpinner,
    spinnerText: state.spinnerText,
    page: state.page,
});

export default withRouter(connect(mapStateToProps, null)(Portal));
