import { ContextPanel } from './context-panel';
import { updateForm } from '../../../redux/actions';
import { connect } from 'react-redux';
import { getContextPanel } from '../../../redux/selectors/contextPanel';

const mapDispatchToProps = (dispatch) => {
    return {
        closeContextPanel: () => {
            dispatch(updateForm(null));
        },
    };
};
const mapStateToProps = (state) => ({
    contextPanel: getContextPanel(state),
});

export default connect(mapStateToProps, mapDispatchToProps)(ContextPanel);
