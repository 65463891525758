import React from 'react';
import styled from 'styled-components';

export const HoverElement = ({ children, ...props }) => {
    return <Container {...props}>{children}</Container>;
};

const Container = styled.div`
    background-color: transparent;
    transition: background-color 100ms ease-out;
    width: 100%;
    height: 100%;
    &:hover {
        background-color: #323130;
    }
`;
