import React from 'react';

export const ConfirmDialog = ({ title, description, confirmLabel, rejectLabel, onConfirm, onReject, data }) => {
    return (
        <>
            <div className="context-panel-visible confirm-dialog">
                <p className="confirm-dialog-title">{title}XXXXX</p>
                <p className="confirm-dialog-description">{description}XXXXX</p>
                <div className="confirm-dialog-controls">
                    <button type="button" className="confirm-dialog-control" onClick={() => onConfirm(data)}>
                        {confirmLabel}XXX
                    </button>
                    <button type="button" className="confirm-dialog-control" onClick={() => onReject(data)}>
                        {rejectLabel}RRR
                    </button>
                </div>
            </div>
        </>
    );
};

export const ConfirmDialog2 = ({ formGenerator, field, additional, controller, collectionStack }) => {
    var confirmInfo = formGenerator.getFormData()?.confirm;
    if (confirmInfo == null) return <></>;

    return (
        <div className="context-panel-wrapper">
            <div className="context-panel-visible confirm-dialog">
                <p className="confirm-dialog-title">{confirmInfo.title}</p>
                <p className="confirm-dialog-description">{confirmInfo.description}</p>
                <div className="confirm-dialog-controls">
                    <button type="button" className="confirm-dialog-control" onClick={() => confirmInfo.onConfirm()}>
                        {confirmInfo.confirmLabel}
                    </button>
                    <button type="button" className="confirm-dialog-control" onClick={() => confirmInfo.onReject()}>
                        {confirmInfo.rejectLabel}
                    </button>
                </div>
            </div>
        </div>
    );
};
