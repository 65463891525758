import React from 'react';
import { substitute } from '../../services/stringsubstitutions';
import { buildClassName, deepCopy } from '../forms/formFunctions';

export const ContainerDynamic = ({ formGenerator, field, additional, controller, collectionStack }) => {
    var createRows = () => {
        return field.items.map((fld, sectionIndex) => {
            const uiItem = formGenerator.createControls(additional.addToValidationFunc, fld, '', collectionStack);
            if (field.childTag == null) return <>{uiItem}</>;

            var childStyles = {
                className: buildClassName(formGenerator, field, null, field.childClass, collectionStack),
                style: field.childStyles,
                ...field.attributes,
            };

            var child = React.createElement(field.childTag, childStyles, uiItem);
            return <>{child}</>;
        });
    };

    var rows = null;
    if (field.header != null && typeof field.header == 'string') {
        var copyStack = deepCopy(collectionStack);
        copyStack.reverse();

        var styles = {
            className: buildClassName(formGenerator, field, null, field.class, collectionStack),
            style: field.styles,
            ...field.attributes,
        };

        var header = substitute(field.header, formGenerator.props.controller, [...copyStack, formGenerator.getFormData()]);
        var item = <>{header}</>;
        var child = React.createElement(field.tag, styles, item);
        return <>{child}</>;
    } else if (field.content) {
        if (field.content === '') return <></>;

        var fs = {
            type: 'formSection',
            key: field.content,
        };

        // if (formGenerator.props.eventType === 'UPDATE_CONFIGS') field.items = [];
        if (field.items.length === 0) {
            field.items.items = [];
            field.items.push(fs);
        }

        rows = createRows();
    } else {
        if (field.header != null) {
            field.items = [field.header];
            field.header = null;
        }

        if (field.items) {
            rows = createRows();
        } else rows = null;
    }

    var parentStyles = {
        className: buildClassName(formGenerator, field, 'dynamic-' + field.tag, field.class, collectionStack),
        style: field.styles,
        ...field.attributes,
    };

    let retVal = React.createElement(field.tag, parentStyles, rows);
    return retVal;
};
