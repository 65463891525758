import React from 'react';
import { buildClassName, deepCopy, mergeFields } from '../forms/formFunctions';
import { substitute } from '../../services/stringsubstitutions';

export const ContainerDiv = ({ formGenerator, field, additional, controller, collectionStack }) => {
    var rows = null;

    var createRows = () => {
        return field.items.map((fld, sectionIndex) => {
            var newField = mergeFields(field, fld);

            const row = formGenerator.createControls(additional.addToValidationFunc, newField, '', collectionStack);
            return row;
        });
    };

    if (field.content) {
        var fs = {
            type: 'formSection',
            key: field.content,
        };

        if (formGenerator.props.eventType === 'UPDATE_CONFIGS') field.items = [];
        if (field.items.length === 0) {
            field.items.items = [];
            field.items.push(fs);
        }

        rows = createRows();
    } else {
        rows = createRows();
    }

    var copyStack = deepCopy(collectionStack);
    copyStack.reverse();

    if (field.id === 'debugger') debugger;
    var cls = buildClassName(formGenerator, field, '', field.class, copyStack);
    cls = substitute(cls, formGenerator.props.controller, [...copyStack, formGenerator.getFormData()]);

    return (
        <div id={field.id} style={field.styles} className={cls}>
            {rows}
        </div>
    );
};

export const ContainerList = ({ formGenerator, field, additional, controller, collectionStack }) => {
    var settings = field.settings || {};
    var rows = null;
    if (formGenerator != null) {
        rows = field.items.map((fld, sectionIndex) => {
            var newField = mergeFields(field, fld);

            const row = formGenerator.createControls(additional.addToValidationFunc, newField, '', collectionStack);
            return (
                <li style={settings.childStyles} className={settings.childClass}>
                    {row}
                </li>
            );
        });
    } else {
    }

    var copyStack = deepCopy(collectionStack);
    copyStack.reverse();

    if (field.id === 'debugger') debugger;
    var cls = buildClassName(formGenerator, field, '', field.class, copyStack);
    cls = substitute(cls, formGenerator.props.controller, [...copyStack, formGenerator.getFormData()]);

    return (
        <ul id={field.id} style={field.styles} className={cls}>
            {rows}
        </ul>
    );
};
