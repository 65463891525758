import React from 'react';
import { buildClassName, deepCopy, mergeFields } from '../forms/formFunctions';
import { FormLabel } from '../forms/FormLabel';

export const ContainerSwitchStack = ({ formGenerator, field, additional, collectionStack }) => {
    var rowFound = false;
    const rows = field.items.map((fld, sectionIndex) => {
        if (rowFound) return null;
        var newField = mergeFields(field, fld);

        const row = formGenerator.createControls(additional.addToValidationFunc, newField, '', collectionStack);
        if (row == null) return null;

        rowFound = true;
        if (field.class || field.styles) {
            var cls = buildClassName(formGenerator, field, '', cls, collectionStack);
            return (
                // <div class={cls} style={field.styles}>
                <>{row}</>
                // </div>
            );
        } else return row;
    });

    var fieldLabel = { header: field.header, class: field.headerClass, style: field.headerStyle };

    // var wrappedRows = rows;
    // if (field.class || field.styles) {
    //     var copyStack = deepCopy(collectionStack);
    //     copyStack.reverse();

    //     var newClass = buildClassName(formGenerator, field, '', field.class, copyStack);
    //     wrappedRows = (
    //         <div className={newClass} style={field.styles}>
    //             {rows}
    //         </div>
    //     );
    // }

    return (
        <>
            {!!field.header && <FormLabel field={fieldLabel} additional={additional} formGenerator={formGenerator} />}
            {rows}
        </>
    );
};
