import { ReactComponent as CssIcon } from '../assets/icons/svgs/css.svg';
import { ReactComponent as DOCIcon } from '../assets/icons/svgs/doc.svg';
import { ReactComponent as DOCXIcon } from '../assets/icons/svgs/docx.svg';
import { ReactComponent as DOTIcon } from '../assets/icons/svgs/dot.svg';
import { ReactComponent as JSIcon } from '../assets/icons/svgs/js.svg';
import { ReactComponent as JsonIcon } from '../assets/icons/svgs/json.svg';
import { ReactComponent as SQLIcon } from '../assets/icons/svgs/sql.svg';
import { ReactComponent as SQLITEIcon } from '../assets/icons/svgs/sqlite.svg';
import { ReactComponent as YamlIcon } from '../assets/icons/svgs/yaml.svg';
import { ReactComponent as FolderIcon } from '../assets/icons/svgs/folder.svg';

const fileIconSelect = (fileType) => {
    switch(fileType) {
        case 'css':
            return <CssIcon />;
        case 'doc':
            return <DOCIcon />;
        case 'docx':
            return <DOCXIcon />;
        case 'dot':
            return <DOTIcon />;
        case 'folder':
            return <FolderIcon />;
        case 'js':
            return <JSIcon />;
        case 'json':
            return <JsonIcon />;
        case 'sql':
            return <SQLIcon />;
        case 'sqlite':
            return <SQLITEIcon />;
        case 'yaml':
            return <YamlIcon />;
        default:
            return <YamlIcon />;
    }
}

export { fileIconSelect };