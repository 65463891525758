import styled from 'styled-components';
import * as React from 'react';
import { addFormEvent } from '../../services/globalMethods';
import { appSettings } from '../../services/settings';
import AuthB2C from '../../services/AuthB2c';
import { HoverElement } from '../hoverElement';
// // import { authContext } from '../../adal';
import { deepCopy } from './formFunctions';

const FormUserButton = ({ formGenerator, field, additional, theme, formId, collectionStack }) => {
    const onClick = () => {
        var copyStack = deepCopy(collectionStack);
        copyStack.reverse();

        if (field.onClick) {
            var formData = { ...formGenerator.getFormData() };
            formGenerator.runActions(field, field.onClick, field.settings.value, formData, copyStack);
            // formGenerator.setState({ ...formGenerator.state, formData });
            return;
        }

        var eventData = { property: field.property, type: field.type, value: field.settings.value };
        addFormEvent(formGenerator.state.form, formData, 'setFormData', eventData, field);

        formData[field.property] = field.value || field.settings.value;
        if (field.onChange != null) {
            var formData = { ...formGenerator.getFormData() };
            formGenerator.onChange(field, field.settings.value, formData, copyStack);
        }

        //        formGenerator.setState({ ...formGenerator.state, formData });
    };

    var name = '';
    if (appSettings.ADAL_B2C) {
        const auth = new AuthB2C();
        name = auth.currentUser().name;
    } else {
        // // name = authContext.getCachedUser().profile.name;
    }

    return (
        <>
            <Container onClick={onClick} class="user-panel">
                <HoverElement>
                    <ContentContainer>
                        <UserName>{name}</UserName>
                        <AvatarContainer>
                            <Avatar />
                        </AvatarContainer>
                    </ContentContainer>
                </HoverElement>
            </Container>
        </>
    );
};

export { FormUserButton };

const Container = styled.div`
    height: 100%;
    cursor: pointer;
`;

const ContentContainer = styled.div`
    display: flex;
    align-items: center;
    height: 100%;
    padding-left: 10px;
`;

const UserName = styled.p`
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    // font-size: 14px;
    line-height: 1;
    max-width: 160px;
    color: white;
    margin: 0;
`;

const AvatarContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 6px;
    height: 100%;
    width: 40px;
`;

const Avatar = styled.div`
    height: 100%;
    width: 100%;
    background-color: peachpuff;
    border-radius: 50%;
`;
