import * as React from 'react';
import { Spinner, SpinnerSize } from 'office-ui-fabric-react/lib/Spinner';

export const BpSpinner = ({ className, header, styles, ...props }) => (
    <Spinner
        style={{ fontSize: '20px', ...styles }}
        label={props.label == null ? header : props.label}
        className={`spinner stretch ${className || ''}  ${props.class || ''}  `}
        size={SpinnerSize.large}
    />
);
