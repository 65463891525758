import React from 'react';

import { buildClassName, mergeFields } from '../forms/formFunctions';

export const ContainerSpan = ({ formGenerator, field, additional, collectionStack }) => {
    var createRows = () => {
        return field.items.map((fld) => {
            var newField = mergeFields(field, fld);

            const row = formGenerator.createControls(
                additional.addToValidationFunc,
                newField,
                '',
                collectionStack,
                null,
                formGenerator
            );
            if (row == null) return null;

            return row;
        });
    };
    var rows;
    if (field.content) {
        var fs = {
            type: 'formSection',
            key: field.content,
        };

        if (formGenerator.props.eventType === 'UPDATE_CONFIGS') field.items = [];
        if (field.items.length === 0) {
            field.items.items = [];
            field.items.push(fs);
        }

        rows = createRows();
    } else {
        rows = createRows();
    }

    return (
        <span
            id={field.id}
            style={field.styles}
            className={buildClassName(formGenerator, field, `container-span`, field.class, collectionStack)}>
            {rows}
        </span>
    );
};
