/* eslint-disable no-mixed-operators */
/* eslint-disable no-template-curly-in-string */
import * as React from 'react';
import { updateForm, setError, setDataStore, showAlert, allDataLoaded } from '../../redux/actions';
import { substitute } from '../../services/stringsubstitutions';
import { makeAxiosPostRequest, makeAxiosRequest } from '../../services/axiosHelper';
import {
    showModalWrapper,
    decodeDisabled,
    addFormEvent,
    parseBool,
    findForm,
    handleHttpResponse,
    Alert,
    findValidationItem,
    findValidators,
    encodeUrl,
    findState,
} from '../../services/globalMethods';
import 'filepond/dist/filepond.min.css';
import { getLayout } from '../Layouts';
import styled from 'styled-components';
import {
    getFieldFromFormSection,
    getActionsFromKey,
    deepCopy,
    updatePropertyNames,
    buildClassName,
} from '../forms/formFunctions';
import { BpSpinner } from '../BpSpinner';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { getPropertyValue, getPropertyValueCollection, getValueFromObjectUsingPath } from '../../services/formDataServices';
import queryString from 'query-string';
import AuthB2C from '../../services/AuthB2c';
import { appSettings } from '../../services/settings';
import { ContainerDiv, ContainerList } from '../containers/ContainerDiv';
import { ContainerDynamic } from '../containers/ContainerDynamic';
import { ContainerResponsive } from '../containers/ContainerResponsive';
import { ContainerSpan } from '../containers/ContainerSpan';
import { ContainerSwitchStack } from '../containers/ContainerSwitchStack';
import { ContainerStack } from '../containers/ContainerStack';
import { ContainerExpandor } from '../containers/ContainerExpandor';
import { FormTextBox } from '../forms/FormTextBox';
import { FormUserButton } from '../forms/FormUserButton';
import { FormTextArea } from '../forms/FormTextArea';
import { FormSubmitButton } from '../forms/FormSubmitButton';

import { FormSelector } from '../forms/FormSelector';
import { FormRemoveLink } from '../forms/FormRemoveLink';
import { FormParagraph } from '../forms/FormParagraph';
import { FormNotificationBar } from '../forms/FormNotificationBar';
import { FormLink } from '../forms/FormLink';
import { FormLabel } from '../forms/FormLabel';
import { FormImage } from '../forms/formImage';
import { FormHyperlink } from '../forms/FormHyperlink';
import { FormHtml } from '../forms/FormHtml';
import { FormFileUploader } from '../forms/FormFileUploader';
import { FormEditor } from '../forms/FormEditor';
import { FormDropDown } from '../forms/FormDropDown';
import { FormDatePicker } from '../forms/FormDatePicker';
import { FormCollection } from '../forms/FormCollection';
import { FormChoiceGroup } from '../forms/FormChoiceGroup';
import { FormCheckBox } from '../forms/FormCheckBox';
import { FormButton } from '../forms/FormButton';
import FormAddLink from '../forms/FormAddLink';
import { FormSeparator } from '../forms/FormSeparator';
import { ThemeContext } from '../system/ThemeContext';
import { FormListBox } from '../forms/FormListBox';
import { ContextPanel } from '../system/ContextPanel/context-panel';
import { runActions, RunActionsRequest } from '../../services/actions';
import { DataGrabber } from '../../services/DataGrabber';
import { FormInput } from '../forms/FormInput';
import { ConfirmDialog, ConfirmDialog2 } from '../ConfirmDialog';
import { FormDiffEditor } from '../forms/FormDiffEditor';

class HubFormGenerator extends React.Component {
    constructor(props) {
        super(props);

        this.collections = {};
        this.decode = this.decode.bind(this);
        this.onChange = this.onChange.bind(this);
        this.createControls = this.createControls.bind(this);
        this.getFormattedFormData = this.getFormattedFormData.bind(this);
        this.getAreaThemeName = this.getAreaThemeName.bind(this);
        this.getTheme = this.getTheme.bind(this);
        this.clearForm = this.clearForm.bind(this);
        this.updateFormData = this.updateFormData.bind(this);
        this.mergeFormData = this.mergeFormData.bind(this);
        this.getFormData = this.getFormData.bind(this);
        this.loadNextPage = this.loadNextPage.bind(this);
        this.updateSearchResults = this.updateSearchResults.bind(this);

        // new code
        var { controller, layoutItem } = this.props;

        if (!layoutItem.key) {
            Alert('HubFormGenerator: missing layoutItem.key');
        }

        var form1 = findForm(controller.props.page, layoutItem.key);

        var form = JSON.parse(JSON.stringify(form1));

        // merge layout and form settings
        form = { ...layoutItem, ...form, settings: { ...form.settings, ...layoutItem.settings } };
        if (form.settings == null) form.settings = {};
        var formData = { ...form.settings.formData, ...props.formData };

        this.state = {
            layoutItem: layoutItem,
            transformsCompleted: false,
            valid: true,
            ignoreValidation: false,
            formData: formData,
            form: form,
            usesSelectedItem: false,
            stillLoading: false,
            mappedItems: {},
        };

        if (this.state.form.settings == null) this.state.form.settings = {};

        var formData = { ...form.settings.formData, ...props.formData };
        formData = substitute(formData, this.props.controller, [this.getFormData()]);
        this.updateFormData(formData);

        var formIsValid = this.validateForm(this.getFormData());
        this.updateFormData({ ...this.getFormData(), formIsValid });

        var ignoreSelectedItemBool = parseBool(form.settings.ignoreSelectedItem) || parseBool(form.settings.ignoreSelectedItems);
        if (!ignoreSelectedItemBool && controller.props.selectedItem != null) {
            this.state = {
                ...this.state,
                formData: controller.props.selectedItem,
                usesSelectedItem: true,
            };

            if (this.getFormData()['isSelected'] != null) delete this.getFormData().isSelected;
        }

        // override request
        if (this.state.form.settings.request == null) {
            this.state.form.request = { ...this.state.form.request, ...this.state.form.settings.request };
        }

        if (form.settings.allowValidationBypass) {
            var self = this;
            window.onkeyup = function (e) {
                if (e.keyCode !== 17) return;
                self.setState({ ...self.state, ignoreValidation: false });
            };
            window.onkeydown = function (e) {
                if (e.keyCode !== 17) return;
                if (!self.state.ignoreValidation) self.setState({ ...self.state, ignoreValidation: true });
            };
        }

        this.refresh(controller);
        // var controllerProps = controller.props;
        // var params = queryString.parse(controllerProps.location.search);
        // if (controllerProps.match.params.secondaryPageName) {
        //     params['secondaryPageName'] = controllerProps.match.params.secondaryPageName;
        //     params['solutionId'] = controllerProps.match.params.secondarySolutionId;
        // }

        // used when form is loaded from the row in a table
        if (props.formData != null) {
            this.componentDidUpdate();
        }
    }

    refresh(controller) {
        var form = this.state.form;

        var datastore = {};
        form.includes &&
            form.includes.forEach((inc) => {
                var config = this.props.configs[inc];
                if (config && config?.layout?.dataStore) {
                    datastore = { ...datastore, ...config?.layout?.dataStore };
                }
            });

        datastore = { ...datastore, ...form.dataStore };

        if (form?.dataGrabs?.length != 0) {
            this.dataGrabber = new DataGrabber(controller, this);
            this.dataGrabber.createDataStore(form.dataGrabs, datastore);
        }
    }

    loadNextPage(searchKey) {
        this.dataGrabber.loadNextPage(this.state.form.dataGrabs, searchKey);
    }

    updateSearchResults(searchKey, searchObject) {
        this.dataGrabber.updateSearchResults(this.state.form.dataGrabs, searchKey, searchObject);
    }

    AddToRecursive(formData, obj, prefix = '') {
        if (obj == null) return;

        if (Array.isArray(obj)) {
            var counter = 0;
            obj.forEach((item) => {
                this.AddToRecursive(formData, item, prefix + `!${counter++}!`);
            });

            return;
        }

        Object.keys(obj).forEach((key) => {
            var property = obj[key];
            if (Array.isArray(property)) {
                var n = 0;
                property.forEach((item) => {
                    this.AddToRecursive(formData, item, prefix + key + `!${n++}!`);
                });

                return;
            }
            if (typeof property === 'object') {
                this.AddToRecursive(formData, property, prefix + key + '_');

                return;
            }

            var propName = prefix + key;
            if (!formData.hasOwnProperty(propName)) {
                formData[propName] = property;
            }
        });
    }

    AddTo(dataStore, formData, property) {
        var from = dataStore[property];
        if (from != null) {
            var prefix = '';
            if (Array.isArray(from)) prefix = property;

            this.AddToRecursive(formData, from, prefix);
        }

        return formData;
    }

    componentDidUpdate(prevProps, nextProps) {
        var self = this;
        var ds = this.getDataStore();
        var formData = this.getFormData();

        var formSettings = this.state.form.settings || {};
        // if (formSettings.dataStoreTransforms == null) formSettings.dataStoreTransforms = [{ property: 'formData' }];
        // else formSettings.dataStoreTransforms = [{ property: 'formData' }, ...formSettings.dataStoreTransforms];
        if (formSettings.dataStoreTransforms == null || formSettings.dataStoreTransforms.length === 0) {
            formSettings.dataStoreTransforms = [{ property: 'formData' }, { property: 'mergeFormData' }];
        }

        var transformsCompleted = this.state.transformsCompleted;
        if (!transformsCompleted && formSettings.dataStoreTransforms) {
            formSettings.dataStoreTransforms.forEach((transform) => {
                this.AddTo(ds, formData, transform.property);
                var from = ds[transform.property];
                if (from != null) {
                    transformsCompleted = true;
                }
            });
        }

        var step = substitute('${step}', this.props.controller, [formData]);

        if (!this.state.updated && ds.pageInfo && ds.pageInfo.events && ds.pageInfo.events.length > 0) {
            //   if(ds.pageInfo != null && !this.state.updated && ds.pageInfo.dummy == null){

            if (step === this.state.step) return;
            if (!self.state.stillLoading) {
                this.setState({ ...this.state, stillLoading: true });
                return;
            }

            this.formData = formData;
            formData.events = [];
            formData.playBack = true;

            var dataStores = [];

            var settings = this.state.form.settings || {};
            if (settings != null && settings.transforms != null) {
                var flatten = (obj, parent, res = {}) => {
                    var isParentArray = Array.isArray(obj);
                    for (let key in obj) {
                        var keyVal = isParentArray ? `!${key}!` : key;
                        var isArray = Array.isArray(obj[key]);
                        let propName = parent ? parent + keyVal : key;
                        if (typeof obj[key] == 'object') {
                            flatten(obj[key], propName + (isParentArray || isArray ? '' : '_'), res);
                        } else {
                            res[propName] = obj[key] != null ? obj[key].toString() : null;
                        }
                    }

                    return res;
                };

                settings.transforms.forEach((transform) => {
                    try {
                        Object.keys(formData).forEach((key) => {
                            if (key.startsWith(transform.to + '_') || key.startsWith(transform.to + '!')) delete formData[key];
                        });

                        var dsItem = this.props.dataStore.get(transform.from);
                        if (dsItem != null) {
                            flatten(dsItem, transform.to, formData);
                        }
                    } catch {}
                });
            }

            // pass in step as a query parameter
            // var step = substitute('${step}', this.props.controller, [formData]);
            if (step != null) step = parseInt(step);
            var exit = false;
            var n = 0;
            var previousDate = new Date('2000-01-01');

            ds.pageInfo.events.forEach((p) => {
                if (exit) {
                    return;
                }
                n++;

                if (self.state.eventNo && self.state.eventNo > n) {
                    return;
                }
                if (step != null && n > step) return;

                switch (p.eventType) {
                    case 'setFormData':
                        formData[p.property] = p.value;
                        formData[p.property + 'TimeTaken'] = ((p.date - previousDate) / 1000).toFixed(1);
                        previousDate = p.date;
                        break;
                        // case 'reloadPage':
                        //     exit = true;
                        //     this.setState({ ...self.state, eventNo: ++n, stillLoading: true });
                        break;
                    case 'addToCollection':
                        this.collections[p.field.property] = p.value;
                        this.onChange(p.field, p.value, formData, p.collectionStack);
                        break;
                    case 'action':
                        if (!parseBool(p.field.settings.ignoreEvent, false)) {
                            this.runActions(p.field, p.action, p.value, formData, p.collectionStack, true);
                        }
                        break;
                    case 'setDataStore':
                        // formData[p.property +'event'] = p;
                        // formData['time'] = new Date(16143665367270);
                        formData.tempDataStore = { ...formData.tempDataStore, ...p.value };
                        dataStores.push(setDataStore(this.props.controller, p.value));
                        this.props.controller.props.dispatch(setDataStore(this.props.controller, p.value));
                        break;
                    // Current implmentation records  data changes only not clicks . TODO Add click events too.
                    // case 'onClick':
                    //     break;
                    case 'removeLink':
                    case 'removelink':
                        // Delete
                        var prop = `${p.property}!${p.index}!`;
                        Object.keys(formData).forEach((key) => {
                            if (key.indexOf(prop) > -1) {
                                delete formData[key];
                            }
                        });

                        // rename
                        var propIndex = p.index;
                        while (true) {
                            var processed = 0;

                            var propTo = `${p.property}!${propIndex++}!`;
                            var propFrom = `${p.property}!${propIndex}!`;

                            var keys = Object.keys(formData);
                            // eslint-disable-next-line no-loop-func
                            keys.forEach((key) => {
                                if (key.indexOf(propFrom) > -1) {
                                    var val = formData[key];
                                    delete formData[key];
                                    var newKey = key.replace(propFrom, propTo);
                                    formData[newKey] = val;

                                    processed++;
                                }
                            });

                            if (processed === 0) break;
                        }

                        // p.property
                        // p.index
                        // debugger;
                        break;

                    default:
                        debugger;
                        break;
                }
            });

            if (formData.tempDataStore) delete formData.tempDataStore;

            dataStores.forEach((p) => {
                this.props.controller.props.dispatch(p);
            });

            if (exit) return;

            // events seem to pop up a dialog
            this.clearForm();

            // ANOTHER HACK (if counter 2 then General question on lifestyle not shown)
            var counter = 0;
            while (counter++ < 5) {
                formData.formIsValid = this.validateForm(formData);
                formData.playBack = false;
            }

            var state = {
                ...this.state,
                formData: formData,
                updated: true,
                itemsMapped: false,
                stillLoading: false,
                eventNo: null,
                step: step,
                transformsCompleted: transformsCompleted,
            };

            this.setState(state);
        } else {
            if (this.state.updated) {
                debugger;
                return;
            }

            var updateForm = false;
            var embeddedFormItems = [];
            var validationFields = [];
            var mappingFields = [];

            this.getVisibleAndEnabledItems({
                fieldItems: this.state.form.items,
                validationFields,
                embeddedFormItems,
                mappingFields,
            });
            var results = [];
            var result = this.validateFields(formData, validationFields, results);
            if (formData.formIsValid !== result) {
                formData.formIsValid = result;
                formData._formIsValidResults = results;
                updateForm = true;
            }

            if (!result) {
                formData._formIsValidResults = results;
            }

            var mappingUpdates = [];
            var mappedItems = { ...this.state.mappedItems };
            var itemsMapped = false;
            mappingFields.forEach((field) => {
                if (field.mapping != null && !mappedItems[field.property]) {
                    if (formData[field.property] != null) return;

                    // var data = this.props.formData || ds;
                    // if (
                    //     data.primary == null &&
                    //     data.applicant == null &&
                    //     data.application == null &&
                    //     data.request == null &&
                    //     data.additional == null
                    // ) {
                    //     return;
                    // }

                    var mapping = field.mapping;
                    while (mapping.toString().includes('_')) {
                        mapping = mapping.replace('_', '.');
                    }

                    var data = getValueFromObjectUsingPath(this.props.formData, mapping);
                    if (data == null) {
                        data = getValueFromObjectUsingPath(ds, mapping);
                    }

                    if (data == null) {
                        var queryParams = this.props.location ? queryString.parse(this.props.location.search) : {};
                        data = getValueFromObjectUsingPath(queryParams, field.mapping);
                    }

                    itemsMapped = true;
                    mappedItems[field.property] = true;
                    formData[field.property] = data;
                    updateForm = true;

                    if (data != null) {
                        // this is missing a collectionstack parameter
                        this.onChange(field, data, formData);
                    }
                }
            });

            embeddedFormItems.forEach((item) => {
                var newVF = [];

                this.getVisibleAndEnabledItems({ fieldItems: item.items, validationFields: newVF, embeddedFormItems });
                var fResult = this.validateFields(formData, newVF, [results]);
                var fieldName = `_${item.form}FormIsValid`;

                if (formData[fieldName] !== fResult) {
                    updateForm = true;
                    formData[fieldName] = fResult;
                }
            });

            // WARNING: if datagrabs occur after page load then events storage will be messed up
            // HACK HACK HACK REWORK!!!!
            if (itemsMapped && this.props.eventType === 'SET_DATASTORE' && formData.events && formData.events.length !== 0) {
                formData.events = [];
                updateForm = true;
                // events may include a dialogupdate
            }

            if (updateForm || transformsCompleted !== this.state.transformsCompleted) {
                var state1 = {
                    ...this.state,
                    itemsMapped: true,
                    formData: formData,
                    mappedItems,
                    transformsCompleted: transformsCompleted,
                };
                this.setState(state1, () => {
                    mappingUpdates.forEach((p) => p());
                });
            }

            this.validateForm(formData);

            if (this.props.eventType === 'ALL_DATA_LOADED') {
                if (!this.state.onLoadRun && this.state.form.onLoad) {
                    var field = { onClick: this.state.form.onLoad };
                    this.runActions(field, this.state.form.onLoad, null, this.getFormData(), []);
                    this.setState({ ...this.state, onLoadRun: true });
                }
            }

            // if (this.props.eventType === 'UPDATE_CONFIGS') {
            //     this.runActions({ property: '' }, [{ type: 'refreshpage' }], null, this.getFormData(), []);
            //     this.setState({ ...this.state, onLoadRun: true });
            // }
        }

        // TODO: this runs after every datagrabber call runs . Need to rethink this and setup AllDataLoaded. Unsure why i changed theis from ALL_DATA_LOADED
        // ALSO all events probably all renaming and sorting out.
        //     if (this.props.eventType === 'ALLOW_FORM_REDRAW') {
        //         if (this.state.onLoadRun && !this.state.allowRedraw && this.state.form.onLoad) {
        //             var field = { onClick: this.state.form.onLoad };
        //             this.runActions(field, this.state.form.onLoad, null, this.getFormData(), []);
        //             this.setState({ ...this.state, allowRedraw: true });
        //         }
        //     }
        // }
    }

    clearForm() {
        if (this.props.form != null) this.props.dispatch(updateForm(null));
    }

    validateForm(formData, formItems = null, results = []) {
        // try {
        if (formItems == null) formItems = this.state.form.items;

        var validationFields = [];
        var embeddedFormItems = [];

        this.getVisibleAndEnabledItems({ fieldItems: this.state.form.items, validationFields, embeddedFormItems });

        embeddedFormItems.reverse().forEach((field) => {
            var validationFields2 = [];
            var embeddedFormItems2 = [];
            this.getVisibleAndEnabledItems({
                fieldItems: field.items,
                validationFields: validationFields2,
                embeddedFormItems: embeddedFormItems2,
            });
            var formResults = [];
            var formResult = this.validateFields(formData, validationFields2, formResults);
            formData[`_${field.form}FormIsValid`] = formResult;
            formData[`_${field.form}FormIsValidResults`] = formResults;
            if (formResult) {
                var data = (formData[`_${field.form}FormData`] = {});
                validationFields2.forEach((p) => {
                    data[p.property] = formData[p.property];
                });
            } else {
                formData[`_${field.form}FormData`] = null;
            }
        });

        var result = this.validateFields(formData, validationFields, results);
        return result;
        // } catch (exception) {
        //     this.setState({ ...this.state, exception });
        // }
    }

    render() {
        var state = this.state;
        if (state.exception) {
            return <span>{this.state.exception}</span>;
        }

        var cls = 'hub-form';
        if (state.form.class) cls += ` ${state.form.class}`;

        try {
            var { controls } = this.generateForm();
            return (
                <div id={this.state.form.id} className={cls} style={this.state.form.styles}>
                    {controls}
                </div>
            );
        } catch (exception) {
            return <div>XXX</div>;
        }
    }

    generateForm() {
        var form = this.state.form;
        var self = this;

        var validationFields = [];
        // fieldItems, validationFields, embeddedFormItems, mappingFields

        this.getVisibleAndEnabledItems({ fieldItems: form.items, validationFields: validationFields, firstPass: true });
        var addToValidationFunc = (p) => {};
        const controls = form.items.map((currentField) => {
            var field = { ...currentField };
            return self.createControls(addToValidationFunc, field);
        });

        return {
            validationFields,
            controls,
        };
    }

    getDataStore() {
        if (this.getFormData().tempDataStore != null) {
            var retVal = { ...this.props.controller.props.dataStore, ...this.getFormData().tempDataStore };
            return retVal;
        }

        // tempDataStore
        return this.props.controller.props.dataStore;
    }

    // getPropertyValue(formObject, propName) {
    //     var indexOfProp = propName.indexOf('_');
    //     //   while(indexOfProp > -1){// || indexOfColl > -1){

    //     // Get first property name from key
    //     var remaining = propName.substring(indexOfProp + 1);
    //     propName = propName.substring(0, indexOfProp);

    //     if (formObject[propName] == null) formObject[propName] = {};

    //     formObject = formObject[propName];
    //     propName = remaining;

    //     indexOfProp = propName.indexOf('_');

    //     return { formObject, propName };
    // }

    // getPropertyValueCollection(formObject, propName) {
    //     var things = propName.split('!');
    //     var collectionName = things[0];
    //     var collectionIndex = parseInt(things[1]);
    //     var propertyName = propName.replace(`${collectionName}!${collectionIndex}!`, '');

    //     if (formObject[collectionName] == null) formObject[collectionName] = [];

    //     var collection = formObject[collectionName];
    //     var prop = collection[collectionIndex];
    //     if (prop == null) {
    //         prop = {};
    //         collection.push(prop);
    //     }

    //     return { formObject: prop, propName: propertyName };
    // }

    primaryButtonClick(field, isSimple, collectionStack = []) {
        var request = field.request;

        var self = this;
        var { controller } = this.props;

        // var form = this.state.form;

        showModalWrapper(this.props.controller, (closeModal) => {
            // var button = form.button;
            // var request = button.request;

            if (self.props.settings != null && self.props.settings.form != null && self.props.settings.form.request != null)
                request = { ...request, ...self.props.settings.form.request };

            var url = substitute(request.url, controller, [this.getFormData()]);
            url = encodeUrl(url);

            const usesfiles = parseBool(self.state.form.settings.usesFiles, false);
            var files = this.state.files;
            if (usesfiles || (files != null && files.length > 0)) {
                if (files == null) files = [];

                var formData = new FormData();
                isSimple = true;
                files.forEach((file) => formData.append('image', file.file));

                var headers = { ...request.headers };
                headers['Content-Type'] = 'multipart/form-data';
                headers = substitute(headers, self.props.controller, [self.getFormData(), this.props.settings]);

                makeAxiosPostRequest(url, formData, headers)
                    .then((response) => {
                        controller.props.dispatch(allDataLoaded(false));

                        var data = handleHttpResponse(response);
                        if (data != null && (request.storeAt != null || request.appendToDataStore)) {
                            if (request.storeAt != null) {
                                var obj = {};
                                obj[request.storeAt] = data;
                                controller.props.dispatch(setDataStore(controller, obj));
                            } else {
                                controller.props.dispatch(setDataStore(controller, data));
                            }
                        }

                        // show alert if required
                        debugger;
                        self.showAlert(request.alert, data);
                        if (request.onSuccess) {
                            controller.props.dispatch(allDataLoaded());
                            self.runActions(field, request.onSuccess, data, self.getFormData(), collectionStack);
                        } else {
                            controller.props.dispatch(updateForm(null));
                            //                            controller.props.dispatch(allDataLoaded());
                            //controller.refresh();
                        }
                    })
                    .catch((error) => {
                        if (request.onFailure) {
                            self.runActions(field, request.onFailure, error, self.getFormData(), collectionStack);
                            controller.props.dispatch(allDataLoaded());
                        } else {
                            //     self.props.controller.props.dispatch(setError(error));
                            controller.props.dispatch(allDataLoaded());
                        }
                    })
                    .finally(() => {
                        closeModal();
                    });
            } else {
                if (request.body == null) var body = {};
                if (
                    request.body === '${form}' ||
                    request.body === '${formData}' ||
                    (request.body == null && request.method === 'POST') ||
                    (request.body == null && request.method === 'DELETE') ||
                    (request.body == null && request.method === 'PUT')
                ) {
                    body = this.getFormattedFormData();
                } else if (typeof request.body === 'string' && request.body.includes('${form.')) {
                    // debugger;
                    var formName = request.body.replace('${form.', '').replace('}', '');
                    body = this.getFormattedFormData(formName);

                    // TODO we need to be able to drill down to a specific property
                    // var tmp = request.body.replace('$', '').replace('{', '').replace('}', '');
                    // tmp.split('.').forEach((item) => {
                    //     if (body == null || item === 'form') return;

                    //     body = body[item];
                    // });
                } else if (request.body == null) {
                    body = null;
                } else {
                    body = substitute(deepCopy(request.body), self.props.controller, [
                        self.getFormData(),
                        self.state.form,
                        self.state.form.settings,
                    ]);

                    // Json parsing
                    if (
                        typeof request.body === 'string' &&
                        (request.headers == null ||
                            request.headers['Content-Type'] == null ||
                            request.headers['Content-Type'] === 'application/json')
                    ) {
                        body = body.split('\n').join('\\n').split('\t').join('\\t').split('\r').join('\\r');
                        body = JSON.parse(body);
                    }
                }

                if (body != null) delete body['events'];

                if (!isSimple) {
                    body = {
                        // id: substitute('${id}', this.props.controller, [this.props, this.state]),
                        id: substitute('${id}', this.props.controller, [
                            this.props,
                            this.state,
                            // self.getFormData(),
                            // self.state.form,
                            // self.state.form.settings,
                        ]),
                        pageId: self.state.form.id,
                        data: body,
                        events: self.getFormData().events,
                    };
                }

                var newHeaders = substitute(request.headers, self.props.controller, [
                    self.getFormData(),
                    self.state.form,
                    self.state.form.settings,
                ]);
                makeAxiosRequest(request.method, url, body, newHeaders)
                    .then((response) => {
                        var data = handleHttpResponse(response);

                        controller.props.dispatch(allDataLoaded(false));
                        if (response.statusCode === 204 || data == null || !parseBool(request.appendToDataStore, true)) {
                            // Intentionally blank
                        } else if (request.storeAt != null) {
                            var obj = {};
                            obj[request.storeAt] = data;
                            controller.props.dispatch(setDataStore(controller, obj));
                        } else {
                            controller.props.dispatch(setDataStore(controller, data));
                        }
                        //  }

                        // show alert if required
                        self.showAlert(request.alert, data);
                        if (request.onSuccess) {
                            controller.props.dispatch(allDataLoaded());
                            self.runActions(field, request.onSuccess, data, self.getFormData(), collectionStack);
                        } else {
                            controller.props.dispatch(updateForm(null));
                            controller.props.dispatch(allDataLoaded());
                            //controller.refresh();
                        }
                    })
                    .catch((error) => {
                        if (request.onFailure) {
                            controller.props.dispatch(allDataLoaded());
                            self.runActions(request, request.onFailure, error, self.getFormData(), collectionStack);
                        } else {
                            self.props.controller.props.dispatch(setError(error));
                            controller.props.dispatch(allDataLoaded());
                        }
                    })
                    .finally(() => {
                        closeModal();
                    });
            }
        });
    }

    getFormattedFormData(formName = null) {
        var self = this;
        var visibleFields = [];
        this.getVisibleItems(this.state.form.items, visibleFields);

        // add fields that can be hidden
        var vFields = { ...self.state.form.settings.formData, events: '' };
        visibleFields.forEach((p) => {
            if (p.property != null) vFields[p.property] = '';
        });

        var formDataStripped = {};

        var keys = formName != null ? Object.keys(self.getFormData()[`_${formName}FormData`]) : Object.keys(self.getFormData());

        keys.forEach((propName) => {
            if (propName[0] === '_') return;
            if (propName === 'formIsValid') return;

            if (vFields[propName] == null) {
                var allow = true;
                //Urrgghh!!
                if (self.state.form.settings && self.state.form.settings.sendHiddenDataPrefixes) {
                    self.state.form.settings.sendHiddenDataPrefixes.forEach((p) => {
                        if (propName.indexOf(p) > -1) allow = true;
                    });
                }

                if (self.state.form.settings && self.state.form.settings.ignoreProperties) {
                    self.state.form.settings.ignoreProperties.forEach((p) => {
                        if (propName.indexOf(p) > -1) allow = false;
                    });
                }

                if (!allow) {
                    return;
                }
            }

            var value = this.getFormData()[propName];
            var formObject = formDataStripped;

            // Remove  array names from prop name
            if (formName) {
                var indexOfEx = formName.lastIndexOf('!');
                if (indexOfEx > -1) {
                    var prefix = formName.substring(0, indexOfEx + 1);
                    propName = propName.replace(prefix, '');
                }
            }

            var indexOfUnderscore = parseBool(this.state.form.settings.flattenHierarchy, false) ? -1 : propName.indexOf('_');
            var indexOfExclamation = propName.indexOf('!');

            if (indexOfUnderscore === -1 && indexOfExclamation === -1) {
                formObject[propName] = value;
                return;
            }

            while (indexOfUnderscore > -1 || indexOfExclamation > -1) {
                var hasUnderscore = indexOfUnderscore > -1;
                var hasExclamation = indexOfExclamation > -1;

                if ((hasUnderscore && !hasExclamation) || (hasUnderscore && indexOfUnderscore < indexOfExclamation)) {
                    var obj = getPropertyValue(formObject, propName);
                    formObject = obj.formObject;
                    propName = obj.propName;
                } else {
                    var obj2 = getPropertyValueCollection(formObject, propName);
                    formObject = obj2.formObject;
                    propName = obj2.propName;
                }

                indexOfUnderscore = propName.indexOf('_');
                indexOfExclamation = propName.indexOf('!');
            }

            formObject[propName] = value;
        });

        // var eventSettings = getPageEventSettings(self.props.controller);
        // if (eventSettings.store) {
        //     formDataStripped.events = self.getFormData().events;
        // }

        return formDataStripped;
    }

    getVisibleAndEnabledItems(request) {
        var { fieldItems, validationFields, embeddedFormItems, mappingFields } = request;

        if (fieldItems == null || fieldItems.length === 0) {
            return;
        }

        for (var n = 0; n < fieldItems.length; n++) {
            var field = fieldItems[n];

            if (field == null) debugger;

            // case hack as form section not set in panels forms
            if (field.formSection != null) {
                field.formsection = field.formSection;
            }

            if (field.type == null && field.formSection != null) {
                field.type = 'formsection';
                field.key = field.formSection;
            }

            if (field.type == null) debugger;
            if (field.type.toLowerCase() === 'placeholder') {
                var formItem = this.getDataStore()[field.settings.dataField];
                if (Array.isArray(formItem)) formItem = { type: 'stack', items: formItem };

                var newField;
                if (formItem != null) {
                    newField = deepCopy(formItem);
                    if (field.container != null)
                        formItem.replacements = { ...newField.replacements, ...field.container.replacements };
                    else formItem.replacements = { ...newField.replacements };

                    formItem.settings = { ...field.settings, ...formItem.settings };

                    if (!newField.type) debugger;
                    if (newField.type.toLowerCase() === 'formsection') {
                        newField = getFieldFromFormSection(this.props.controller, formItem);
                        newField.container = field.container;
                    }

                    if (newField.items == null) newField.items = [];

                    newField.replacements = { ...newField.replacements, ...formItem.replacements, ...field.replacements };
                    if (newField.container) {
                        newField.container = field.container;
                        newField.containerIndex = field.container.items.length - 1;
                        updatePropertyNames(newField, newField.container, newField.containerIndex, this.props.controller);
                    }
                    fieldItems[n] = newField;
                    field = newField;
                }
            }

            if (field.type.toLowerCase() === 'formsection') {
                const newField = getFieldFromFormSection(this.props.controller, field);
                if (field.container) {
                    newField.container = field.container;
                    newField.containerIndex = field.container.items.length - 1;
                }

                field = newField;
                fieldItems[n] = field;
                if (field == null) {
                    return;
                }

                if (field.container != null) {
                    updatePropertyNames(field, field.container, field.containerIndex, this.props.controller);
                }
            }

            if (field.visible != null) {
                var result = decodeDisabled(field, field.visible, this.props.controller, this.getDataStore(), this.getFormData());
                if (!result) {
                    continue;
                }
            }

            if (embeddedFormItems != null && field.form != null && field.form.length !== 0) {
                embeddedFormItems.push(field);
            }

            if (
                (field.property != null && field.property !== '' && field.property[0] !== '_') ||
                field.type === 'required' ||
                field.required
            ) {
                validationFields.push(field);
            }

            if (mappingFields && field.mapping) {
                mappingFields.push(field);
            }

            this.getVisibleAndEnabledItems({ fieldItems: field['items'], validationFields, embeddedFormItems, mappingFields });
        }
    }

    getVisibleItems(fieldItems, validationFields) {
        if (fieldItems == null || fieldItems.length === 0) {
            return;
        }

        for (var n = 0; n < fieldItems.length; n++) {
            var field = fieldItems[n];

            if (field == null) debugger;

            if (field.type == null) debugger;

            if (field.type.toLowerCase() === 'formsection') {
                Alert("WE SHOULDN'T HAVE ANY FORM SECTIONS");
                debugger;
            }

            if (field.visible != null) {
                var result = decodeDisabled(field, field.visible, this.props.controller, this.getDataStore(), this.getFormData());
                if (!result) {
                    continue;
                }
            }

            if ((field.property != null && field.property !== '' && field.property[0] !== '_') || field.type === 'required') {
                validationFields.push(field);
            }

            this.getVisibleItems(field['items'], validationFields);
        }
    }

    getAreaThemeName() {
        const themeName =
            (this.props.area &&
                this.props.area.config &&
                this.props.area.config.settings &&
                this.props.area.config.settings.theme) ||
            'default';
        return themeName;
    }

    getTheme() {
        return this.context[this.getAreaThemeName()];
    }

    createControls(addToValidationFunc, customField, defaultClass = '', collectionStack = []) {
        var field = customField;

        if (field == null) debugger;

        // HACK

        if (field.type == null && field.formSection) {
            field.type = 'formsection';
            field.key = field.formSection;
        }

        //    if (collectionStack == null) collectionStack = [];

        if (field.type == null) debugger;
        var type = field.type.toLowerCase();

        if (type === 'formsection') {
            field = getFieldFromFormSection(this.props.controller, customField);
            if (field == null) {
                debugger;
                return [<>`formSection not found: ${customField.key}`</>];
            }

            type = field.type.toLowerCase();
        }

        const items = [];
        var self = this;
        var { controller } = this.props;

        if (field.settings == null) field.settings = {};

        if (field.type === 'file') field.property = 'files';

        var defaultValue = !this.state.usesSelectedItem ? field.defaultValue : null;

        var setStateWithProperty = (field, value, collectionStack, obj) => {
            var obj = {};

            if (field.settings.maskStrip && value != null) {
                field.settings.maskStrip.split('|').forEach((p) => {
                    if (value == null) debugger;

                    while (value.toString().includes(p)) {
                        value = value.replace(p, '');
                    }
                });
            }

            var originalFormData = self.getFormData();
            var beforeFormDataString = JSON.stringify(originalFormData);
            var oldValue = originalFormData[field.property];
            obj[field.property] = value;
            var formData = { ...self.getFormData(), ...obj };
            var eventData = { property: field.property, type: field.type, value, oldValue, collectionStack };

            addFormEvent(this.state.form, formData, 'setFormData', eventData, field);

            // var newState = { ...self.state, formData: formData };

            var updateFunc = () => {
                if (field.onPreChange != null) {
                    field.onPreChange(field, value, formData, collectionStack);
                }

                this.updateFormData(formData);

                if (field.onChange != null) {
                    this.onChange(field, value, formData, collectionStack);
                }

                if (field.onPostChange != null) {
                    formData = field.onPostChange(field, value, formData, collectionStack);
                }

                // Torturous way to figure out if form has returned to its original state
                var _formUpdated = false;
                if (formData._formUpdated == null) {
                    var formDataString = beforeFormDataString;
                    this.setState({ ...this.state, formDataString });
                    _formUpdated = true;
                } else {
                    var copyFormData = deepCopy(formData);
                    delete copyFormData._formUpdated;
                    var copyFormDataString = JSON.stringify(copyFormData);
                    var data = this.state.formDataString;
                    if (data === copyFormDataString) {
                        _formUpdated = false;
                    } else {
                        _formUpdated = true;
                    }
                }
                var formIsValid = this.validateForm(formData);
                formData = { ...formData, formIsValid, _formUpdated };
                self.updateFormData(formData);
            };

            if (!field.settings.onChangeDebounce) {
                updateFunc();
            } else {
                var timeout = parseInt(field.settings.onChangeDebounce);
                this.debounceInternal(field, timeout, () => updateFunc())();
            }
        };

        var validation = (p, field1) => {
            var msg = this.validateField(p, field1);
            return msg.errorMessage;
        };

        var additional = {
            formGenerator: self,
            controller: controller,
            decode: this.decode,
            validation: (p) => validation(p, field),
            onChange: setStateWithProperty,
            defaultValue: defaultValue,
            addToValidationFunc: addToValidationFunc,
            defaultClass,
        };

        if (field.visible != null) {
            var result = decodeDisabled(
                field,
                field.visible,
                this.props.controller,
                this.getDataStore(),
                this.getFormData(),
                collectionStack
            );
            if (!result) {
                items.push(null);
                return;
            }
        }

        if (field.property != null && field.property !== '' && field.property[0] !== '_') {
            addToValidationFunc(field);
        }

        switch (type.toLowerCase()) {
            // validators
            case 'required':
                return null;

            case 'confirmdialog':
                items.push(<ConfirmDialog2 controller={self.props.controller} formGenerator={self} field={field} />);
                break;

            case 'contextpanel':
                if (this.props.form == null) break;
                items.push(
                    <ContextPanel
                        controller={self.props.controller}
                        contextPanel={this.props.form}
                        closeContextPanel={() => this.props.controller.props.dispatch(updateForm(null))}
                    />
                );
                break;
            case 'spacer':
                items.push(
                    <div className={buildClassName(this, field, '', field.class, collectionStack)} style={field.styles}></div>
                );
                break;
            case 'ratecheckerresults':
                //                 items.push(<FormRateCheckerResults formGenerator={self} field={field}  additional={additional} defaultClass={defaultClass } />);
                break;
            case 'submitbutton':
                items.push(
                    <FormSubmitButton
                        controller={self.props.controller}
                        formGenerator={self}
                        field={field}
                        additional={additional}
                        defaultClass={defaultClass}
                        isSimple={false}
                        theme={this.getTheme}
                        collectionStack={collectionStack}
                    />
                );
                break;
            case 'requestbutton':
            case 'submitbuttonsimple':
                items.push(
                    <FormSubmitButton
                        controller={self.props.controller}
                        formGenerator={self}
                        field={field}
                        additional={additional}
                        defaultClass={defaultClass}
                        isSimple={true}
                        theme={this.getTheme}
                        collectionStack={collectionStack}
                    />
                );
                break;
            case 'html':
                items.push(
                    <FormHtml
                        controller={self.props.controller}
                        formGenerator={self}
                        field={field}
                        additional={additional}
                        defaultClass={defaultClass}
                        collectionStack={collectionStack}
                    />
                );
                break;
            case 'label':
                items.push(
                    <FormLabel
                        controller={self.props.controller}
                        formGenerator={self}
                        field={field}
                        additional={additional}
                        defaultClass={defaultClass}
                        collectionStack={collectionStack}
                    />
                );
                break;
            case 'image':
                items.push(
                    <FormImage
                        controller={self.props.controller}
                        formGenerator={self}
                        field={field}
                        additional={additional}
                        defaultClass={defaultClass}
                        collectionStack={collectionStack}
                    />
                );
                break;
            case 'p':
                items.push(
                    <FormParagraph
                        controller={self.props.controller}
                        formGenerator={self}
                        field={field}
                        additional={additional}
                        defaultClass={defaultClass}
                        collectionStack={collectionStack}
                    />
                );
                break;

            case 'hyperlink':
                items.push(
                    <FormHyperlink
                        controller={self.props.controller}
                        formGenerator={self}
                        field={field}
                        additional={additional}
                        defaultClass={defaultClass}
                        collectionStack={collectionStack}
                    />
                );
                break;
            case 'notificationbar':
                items.push(
                    <FormNotificationBar
                        controller={self.props.controller}
                        formGenerator={self}
                        field={field}
                        additional={additional}
                        defaultClass={defaultClass}
                        collectionStack={collectionStack}
                    />
                );
                break;

            case 'separator':
                items.push(
                    <FormSeparator
                        controller={self.props.controller}
                        formGenerator={self}
                        field={field}
                        additional={additional}
                        defaultClass={defaultClass}
                        collectionStack={collectionStack}
                    />
                );
                break;
            case 'textbox':
                items.push(
                    <FormTextBox
                        controller={self.props.controller}
                        formGenerator={self}
                        field={field}
                        additional={additional}
                        defaultClass={defaultClass}
                        theme={this.getTheme}
                        collectionStack={collectionStack}
                    />
                );
                break;
            case 'input':
                items.push(
                    <FormInput
                        controller={self.props.controller}
                        formGenerator={self}
                        field={field}
                        additional={additional}
                        defaultClass={defaultClass}
                        theme={this.getTheme}
                        collectionStack={collectionStack}
                    />
                );
                break;
            case 'button':
                items.push(
                    <FormButton
                        controller={self.props.controller}
                        theme={this.getTheme}
                        formGenerator={self}
                        field={field}
                        additional={additional}
                        defaultClass={defaultClass}
                        collectionStack={collectionStack}
                    />
                );
                break;
            // case 'simplebutton':
            //     items.push(
            //         <FormSimpleButton
            //             theme={this.getTheme}
            //             formGenerator={self}
            //             field={field}
            //             additional={additional}
            //             defaultClass={defaultClass}
            //             collectionStack={collectionStack}
            //         />
            //     );
            //     break;
            case 'textarea':
                items.push(
                    <FormTextArea
                        controller={self.props.controller}
                        formGenerator={self}
                        field={field}
                        additional={additional}
                        defaultClass={defaultClass}
                        collectionStack={collectionStack}
                    />
                );
                break;
            case 'checkbox':
                items.push(
                    <FormCheckBox
                        controller={self.props.controller}
                        formGenerator={self}
                        field={field}
                        additional={additional}
                        defaultClass={defaultClass}
                        collectionStack={collectionStack}
                    />
                );
                break;
            case 'dropdown':
                items.push(
                    <FormDropDown
                        controller={self.props.controller}
                        formGenerator={self}
                        field={field}
                        additional={additional}
                        defaultClass={defaultClass}
                        theme={this.getTheme}
                        collectionStack={collectionStack}
                    />
                );
                break;
            case 'listbox':
                items.push(
                    <FormListBox
                        controller={self.props.controller}
                        formGenerator={self}
                        field={field}
                        additional={additional}
                        defaultClass={defaultClass}
                        theme={this.getTheme}
                        collectionStack={collectionStack}
                    />
                );
                break;
            case 'userpanel':
                items.push(
                    <FormUserButton
                        controller={self.props.controller}
                        formGenerator={self}
                        field={field}
                        additional={additional}
                        defaultClass={defaultClass}
                        collectionStack={collectionStack}
                    />
                );
                break;
            case 'choicegroup':
                items.push(
                    <FormChoiceGroup
                        controller={self.props.controller}
                        formGenerator={self}
                        field={field}
                        additional={additional}
                        defaultClass={defaultClass}
                        collectionStack={collectionStack}
                    />
                );
                break;
            case 'date':
                items.push(
                    <FormDatePicker
                        controller={self.props.controller}
                        formGenerator={self}
                        field={field}
                        additional={additional}
                        defaultClass={defaultClass}
                        collectionStack={collectionStack}
                    />
                );
                break;
            case 'spinner':
                items.push(
                    <BpSpinner
                        header={field.header}
                        class={buildClassName(this, field, 'form-spinner', field.class, collectionStack)}
                        styles={field.styles}
                    />
                );
                break;
            case 'file':
                items.push(
                    <FormFileUploader
                        controller={self.props.controller}
                        formGenerator={self}
                        field={field}
                        additional={additional}
                        defaultClass={defaultClass}
                        collectionStack={collectionStack}
                    />
                );
                break;
            case 'selector':
                items.push(
                    <FormSelector
                        controller={self.props.controller}
                        key={field.property}
                        formGenerator={self}
                        field={field}
                        additional={additional}
                        theme={this.getTheme}
                        collectionStack={collectionStack}
                    />
                );
                break;
            case 'expandable':
                items.push(
                    <ContainerExpandor
                        controller={self.props.controller}
                        formGenerator={self}
                        field={field}
                        additional={additional}
                        theme={this.getTheme}
                        themeName={this.props.themeName}
                        collectionStack={collectionStack}
                    />
                );
                break;
            case 'stack':
                items.push(
                    <ContainerStack
                        controller={self.props.controller}
                        formGenerator={self}
                        field={field}
                        additional={additional}
                        collectionStack={collectionStack}
                    />
                );
                break;
            case 'switch':
                items.push(
                    <ContainerSwitchStack
                        controller={self.props.controller}
                        formGenerator={self}
                        field={field}
                        additional={additional}
                        collectionStack={collectionStack}
                    />
                );
                break;
            case 'wrapper':
                items.push(
                    <ContainerList
                        controller={self.props.controller}
                        formGenerator={self}
                        field={field}
                        additional={additional}
                        collectionStack={collectionStack}
                    />
                );
                break;

            case 'dynamic':
                items.push(
                    <ContainerDynamic
                        controller={self.props.controller}
                        formGenerator={self}
                        field={field}
                        additional={additional}
                        collectionStack={collectionStack}
                    />
                );
                break;
            case 'div':
                items.push(
                    <ContainerDiv
                        controller={self.props.controller}
                        formGenerator={self}
                        field={field}
                        additional={additional}
                        collectionStack={collectionStack}
                    />
                );
                break;
            case 'span':
                items.push(
                    <ContainerSpan
                        controller={self.props.controller}
                        formGenerator={self}
                        field={field}
                        additional={additional}
                        collectionStack={collectionStack}
                    />
                );
                break;
            case 'responsive':
            case 'horizontal':
                items.push(
                    <ContainerResponsive
                        controller={self.props.controller}
                        formGenerator={self}
                        field={field}
                        additional={additional}
                        collectionStack={collectionStack}
                    />
                );
                break;
            case 'placeholder':
                items.push(<BpSpinner />);
                // items.push(<>{field.container.items.length}</>);
                break;
            case 'toggler':
                if (field.visible == null) {
                    field.visible = {};
                    field.visible[field.property] = 'show';
                }
                field.rvalue = '';
            // eslint-disable-next-line no-fallthrough
            case 'toggle':
                if (field.icon) field.header = '';
                field.settings = {
                    ...field.settings,
                    text: field.header,
                    icon: field.icon,
                    value: field.rvalue != null ? '' : 'show',
                };
                if (field.visible == null) {
                    field.visible = {};
                    field.visible[field.property + '!'] = 'show';
                }

            // eslint-disable-next-line no-fallthrough
            case 'buttonlink':
            case 'link':
                items.push(
                    <FormLink
                        controller={self.props.controller}
                        formGenerator={this}
                        field={field}
                        additional={additional}
                        collectionStack={collectionStack}
                    />
                );

                break;
            case 'addlink':
            case 'addlinkcombo':
                items.push(
                    <FormAddLink
                        controller={self.props.controller}
                        formGenerator={this}
                        field={field}
                        additional={additional}
                        collectionStack={collectionStack}
                    />
                );
                break;
            case 'removelink':
                items.push(
                    <FormRemoveLink
                        controller={self.props.controller}
                        formGenerator={this}
                        field={field}
                        additional={additional}
                        collectionStack={collectionStack}
                    />
                );
                break;
            case 'collection':
                items.push(
                    <FormCollection
                        controller={self.props.controller}
                        formGenerator={this}
                        field={field}
                        additional={additional}
                        collectionStack={collectionStack}
                    />
                );
                break;
            case 'editor':
            case 'editor2':
                items.push(
                    <FormEditor
                        controller={self.props.controller}
                        formGenerator={this}
                        field={field}
                        additional={additional}
                        collectionStack={collectionStack}
                    />
                );
                break;
            case 'diffeditor':
                items.push(
                    <FormDiffEditor
                        controller={self.props.controller}
                        formGenerator={this}
                        field={field}
                        additional={additional}
                        collectionStack={collectionStack}
                    />
                );
                break;
                
            case 'action':
                this.runActions(field, field.settings.action, field.value, this.getFormData(), collectionStack);
                break;
            case 'empty':
            case 'blank':
            case 'dummy':
                break;
            default:
                var item = getLayout(controller, field, false, null, collectionStack, null, this);
                items.push(item);
                break;
        }

        return items;
    }
    validateField(text, field) {
        if (!parseBool(field.required) && (field.validators == null || field.validators.length === 0))
            return { isValid: true, errorMessage: '' };

        if (parseBool(field.required) && (text == null || text.length === 0)) {
            return { isValid: false, errorMessage: '' };
        }

        var fieldValue = this.getFormData()[field.property];

        if (fieldValue == null || fieldValue === '') {
            if (text == null || text.length === 0) return { isValid: true, errorMessage: '' };
        }

        if (field.settings && field.settings.maskStrip && text != null) {
            field.settings.maskStrip.split('|').forEach((p) => {
                if (p.length === 0) return;

                while (text.toString().includes(p)) {
                    text = text.replace(p, '');
                }
            });

            if (parseBool(field.required) && (text == null || text.length === 0)) {
                return { isValid: false, errorMessage: '' };
            }
        }

        var self = this;
        var retVal = '';
        if (field.validators) {
            var foundError = false;

            var fieldValidators = findValidators(this.props.controller.props.page, field.validators);

            fieldValidators.forEach((validatorItem) => {
                if (retVal) return;

                if (foundError) return;

                var validator = findValidationItem(this.props.controller.props.page, validatorItem);

                switch (validator.type) {
                    case 'callMethod': {
                        retVal = window[validator.method](this, this.getFormData(), field, text, validator);
                        if (retVal == null) retVal = '';

                        foundError = retVal !== '';
                        break;
                    }
                    case 'regex': {
                        // needed when deleting a child and there is an error in a box
                        if (text == null) break;

                        var regExText = text;
                        if (validator.property != null) regExText = this.getFormData()[validator.property];
                        if (!regExText) break;

                        var expression = validator.condition;
                        var result = regExText.toString().match(expression);
                        retVal = result ? '' : validator.errorMessage;

                        foundError = retVal.length !== 0;
                        break;
                    }
                    case 'assert': {
                        foundError = decodeDisabled(
                            validator,
                            validator.condition,
                            this.props.controller,
                            this.getDataStore(),
                            this.getFormData()
                        );
                        retVal = foundError ? '' : validator.errorMessage;

                        break;
                    }
                    case 'numericassert': {
                        var addValue = (str) => {
                            var val = 0;
                            var additions = {};
                            if (field.originalPropertyName != null) {
                                additions[field.originalPropertyName] = self.getFormData()[field.property];
                            }

                            str = substitute(str, null, [additions, self.getFormData()]);
                            str.split(',').forEach((p) => (val += parseInt(p)));
                            return val;
                        };

                        // TODO: add user validation

                        var additions = addValue(validator.additions);
                        var subtractions = addValue(validator.subtractions || '0');
                        var value = addValue(validator.value);

                        var lhs = additions - subtractions;

                        var isValid = true;
                        if (validator.comparator === '<') {
                            isValid = lhs < value;
                        } else {
                            isValid = lhs > value;
                        }

                        if (!isValid) {
                            foundError = true;
                            retVal = validator.errorMessage;
                        }

                        return;
                    }
                    case 'range': {
                        const value = parseInt(text);
                        var minValue = validator.minValue ? parseInt(validator.minValue) : null;
                        var maxValue = validator.maxValue ? parseInt(validator.maxValue) : null;

                        if (minValue == null && maxValue == null) {
                            return ' A minValue and/or maxValue must be set when using a range validator';
                        }

                        if (minValue && !maxValue) {
                            if (value < minValue) {
                                foundError = true;
                                retVal = validator.errorMessage;
                                return;
                            }
                        } else if (!minValue && maxValue) {
                            if (value > maxValue) {
                                foundError = true;
                                retVal = validator.errorMessage;
                                return;
                            }
                        } else if (minValue && maxValue) {
                            if (value > maxValue) {
                                foundError = true;
                                retVal = validator.errorMessage;
                                return;
                            }

                            if (value < minValue) {
                                foundError = true;
                                retVal = validator.errorMessage;
                                return;
                            }
                        } else {
                            foundError = true;
                            retVal = validator.errorMessage;
                            return;
                        }

                        break;
                    }
                    case 'url': {
                        //TODO this should wait a few seconds before calling the request to ensure the user has completed time and therefore possible save money
                        var previousVal = self.state[field.property + 'Url'];
                        var previousError = self.state[field.property + 'UrlError'];
                        var currentVal = self.getFormData()[field.property];

                        if (currentVal === previousVal) {
                            retVal = previousError || '';
                            return;
                        }

                        retVal = new Promise((resolve) => {
                            var request = { ...validator.condition };
                            request.method = request.method == null ? 'POST' : request.method;
                            request.url = substitute(request.url, this.props.controller, [
                                this.getFormData(),
                                { PROPERTY: currentVal },
                            ]);
                            request.url = encodeUrl(request.url);

                            if (request.body === '${form}') request.body = JSON.stringify(this.getFormData());
                            else if (request.body != null)
                                request.body = substitute(request.body, this.props.controller, [this.getFormData()]);

                            return resolve(
                                new Promise((resolve2) => {
                                    this.props.controller.makeRequest(
                                        { request },
                                        false,
                                        {},
                                        (data) => {
                                            var result = parseBool(data);

                                            newState[field.property + 'UrlError'] = result ? '' : validator.errorMessage;
                                            this.setState(newState);

                                            // ds[key] = result;
                                            // this.props.controller.props.dispatch(setDataStore(this.props.controller, ds));
                                            // foundError = !result;
                                            //    this.setState(this.props.state);
                                            return result ? resolve2('') : resolve2(validator.errorMessage);
                                        },
                                        () => {
                                            foundError = true;
                                            return resolve2(validator.errorMessage);
                                        }
                                    );
                                })
                            );
                        });

                        var newState = self.state;
                        newState[field.property + 'Url'] = self.getFormData()[field.property];
                        self.setState(newState);

                        break;
                    }
                    default:
                        break;
                }
            });
        }

        return { isValid: retVal.length === 0, errorMessage: retVal };
    }

    validateFields(formData, validationFields, results) {
        var valid = true;
        var required = true;
        validationFields.forEach((field) => {
            if (!valid) return;

            // If its disabled we don't need a value
            if (this.decode(field, field.disabled)) {
                return;
            }

            if (
                field.visible != null &&
                !decodeDisabled(field, field.visible, this.props.controller, this.getDataStore(), this.getFormData())
            ) {
                return;
            }

            if (field.type === 'required') {
                var additional = {};
                if (field.originalPropertyName != null) {
                    Object.keys(formData).forEach((key) => {
                        if (key.startsWith(field.row)) {
                            additional[key.replace(field.row, '')] = formData[key];
                        }
                    });
                }

                if (typeof field.value === 'string') {
                    var value = substitute(field.value, null, [additional, formData]);
                    if (value == null || value === '') {
                        valid = false;
                        results.push({ property: field.property || field.value, message: 'Is required' });
                        return;
                    }

                    if (value.length === 0) {
                        valid = false;
                        required = true;
                        results.push({ property: field.property || field.value, message: 'Is required' });
                        return;
                    }
                } else {
                    valid = decodeDisabled(field, field.value, this.props.controller, this.getDataStore(), this.getFormData());
                    if (!valid) {
                        required = true;
                        results.push({ property: field.value, message: 'Is required' });
                        return;
                    }
                }
            }

            var val = formData[field.property];
            if ((val == null || val.length === 0) && parseBool(field.required)) {
                valid = false;
                required = false;
                results.push({ property: field.property, message: 'Is required' });
                return;
            }

            if (!this.validateField(val, field).isValid) {
                results.push({ property: field.property, message: 'Validation failed' });

                valid = false;
            }
        });

        return (this.state.ignoreValidation && required) || valid;
    }

    // Additional used for request response handlers
    decode(field, constraints, bypassExistsCheck2 = false, additional = {}, collectionStack = []) {
        constraints = substitute(constraints, additional.controller, collectionStack) || null;

        if (constraints == null) return false;
        if (parseBool(constraints, false)) return true;

        var reverseDecision = false;
        if (typeof constraints === 'string') {
            var state = findState(this.props.page, constraints);
            if (state != null) constraints = state;
            reverseDecision = true;
        }

        if (!Array.isArray(constraints)) {
            constraints = [constraints];
        }

        var copyStack = deepCopy(collectionStack) || [];
        copyStack.reverse();

        var myField = field;
        var disable = false;
        constraints.forEach((constraint) => {
            if (disable) {
                return;
            }

            var bypassExistsCheck = false;
            var useTitleCase = false;

            var counter = Object.keys(constraint).length;

            var collectionItem = { ...this.getFormData(), ...additional };

            Object.keys(constraint).forEach((key) => {
                if (disable) return;

                if (key === 'debugger') {
                    counter--;
                    debugger;
                    return;
                }

                if (key === 'bypassExistsCheck') {
                    bypassExistsCheck = constraint[key] || bypassExistsCheck2;
                    counter--;
                    return;
                }

                if (key === 'useTitleCase') {
                    useTitleCase = constraint[key];
                    counter--;
                    return;
                }

                var val = constraint[key];
                if (val === 'null') {
                    val = undefined;
                }

                //                val = parseBool(val);

                var realKey = key;
                if (key[key.length - 1] === '!') {
                    realKey = key.substr(0, key.length - 1);
                }

                // HACK TODO FIX: If the field is found in the formdata then don't add the 'collection!0!' prefix. E.g. formIsValid
                if (
                    myField.originalPropertyName != null &&
                    this.getFormData().hasOwnProperty(realKey) === false &&
                    key.indexOf('ds.') !== 0
                ) {
                    realKey = (myField.row || '') + realKey;
                }

                if (useTitleCase) {
                    var upperCaseKey = realKey.toUpperCase();
                    upperCaseKey = upperCaseKey[0] + key.substring(1);
                    if (upperCaseKey[upperCaseKey.length - 1] === '!') upperCaseKey = upperCaseKey.substr(0, key.length - 1);

                    realKey = upperCaseKey;
                }

                if (val === 'invalid' || val === 'valid') {
                    var field = this.GetFieldFromPropertyName(this.state.form.items, realKey);
                    if (!field) return;

                    var isValid = this.validateField(this.getFormData()[realKey], field).isValid;
                    if ((val === 'invalid' && !isValid) || (val === 'valid' && isValid)) {
                        counter--;
                    }

                    return;
                }

                realKey = substitute(realKey, this.props.controller, [...copyStack, this.getFormData(), additional]);

                var queryParams = this.props.location ? queryString.parse(this.props.location.search) : null;
                var comparator = getValueFromObjectUsingPath(queryParams, realKey);

                if (comparator == null) {
                    comparator = collectionItem[realKey];
                    if (comparator) comparator = comparator.toString();

                    if (key.indexOf('ds.') === 0) {
                        comparator = getValueFromObjectUsingPath(this.getDataStore(), realKey.substring(3));
                    }

                    // hack. TODO. use search collection stack correctly?
                    if (comparator == null) {
                        comparator = substitute('${' + realKey + '}', this.props.controller, [
                            ...copyStack,
                            this.getFormData(),
                            additional,
                        ]);
                        if (comparator === '') comparator = null;
                    }
                }

                if (comparator != null) {
                    try {
                        comparator = comparator.toString();
                    } catch (ex) {
                        debugger;
                        throw ex;
                    }
                }

                // var contains = null;
                if (val != null && typeof val === 'object') {
                    // if (val.operator.toLowerCase() === 'contains') {
                    //     contains = true;
                    //     val = val.value;
                    // }

                    if (val.operator.toLowerCase() === 'regex') {
                        var result = (comparator || '').toString().match(val.value) != null;
                        if (key[key.length - 1] === '!') result = !result;

                        if (result) counter--;

                        return;
                    }
                }

                if (typeof val === 'string') {
                    val = substitute(val, this.props.controller, [...copyStack, this.getFormData(), additional]);
                }

                val = parseBool(val, val);
                if (val != null) val = val.toString();

                if (key[key.length - 1] === '!') {
                    // eslint-disable-next-line eqeqeq
                    if (
                        //removed as xxx!: null diodn';t come back as true and disabled the thing. Wht do we need bypassExistsCheck
                        // (key.indexOf('ds.') === 0 ||
                        //     collectionItem.hasOwnProperty(realKey) ||
                        //     bypassExistsCheck ||
                        //     comparator != null) &&
                        val !== comparator ||
                        (comparator === '' && val != null && val !== '')
                    ) {
                        counter--;
                    }
                    return;
                }

                // eslint-disable-next-line eqeqeq
                if (
                    (key.indexOf('ds.') === 0 || collectionItem.hasOwnProperty(key) || bypassExistsCheck || comparator != null) &&
                    (val === comparator || (comparator === '' && val != null))
                ) {
                    counter--;
                }
            });

            if (counter === 0) disable = true;
            // });
        });

        return reverseDecision ? !disable : disable;
    }

    GetFieldFromPropertyName(fieldItems, propName) {
        if (fieldItems == null || fieldItems.length === 0) return null;

        for (var n = 0; n < fieldItems.length; n++) {
            var field = fieldItems[n];

            if (field.visible != null) {
                var result = decodeDisabled(field, field.visible, this.props.controller, this.getDataStore(), this.getFormData());
                if (!result) {
                    continue;
                }
            }

            if (field.property === propName) {
                return field;
            }

            field = this.GetFieldFromPropertyName(field['items'], propName);
            if (field) return field;
        }

        return null;
    }

    showAlert(alert, data) {
        if (alert) {
            var msg = substitute(alert.message, this.props.controller, [this.state, data]);

            this.props.controller.props.dispatch(showAlert(alert.type, msg, alert.title));
        }
    }

    getActionsFromProperty(event) {
        if (typeof event === 'string') {
            var actions = getActionsFromKey(this.props.controller, event);
            if (actions == null) {
                Alert(`Unable to find action: ${event}`);
                return [];
            }

            if (!Array.isArray(actions)) return [actions];

            return actions;
        } else if (!Array.isArray(event)) {
            return [event];
        } else
            return event.map((item) => {
                if (item.action != null) {
                    return getActionsFromKey(this.props.controller, item.action)[0];
                }

                return item;
            });
    }

    onChange(field, value, formData, collectionStack) {
        this.runActions(field, field.onChange, value, formData, collectionStack);
    }

    runActions(field, action, value, formData, collectionStack = [], loadingEvents = false) {
        const request = new RunActionsRequest(this, field, action, value, formData, collectionStack, loadingEvents);
        runActions(request);
    }

    updateFormData(fd, action) {
        // var newFd = { ...this.state.formData, ...fd };
        // this.setState({ ...this.state, formData: newFd });
        if (!action) action = () => {};

        this._formData = fd; //{ ...this._formData, ...fd };
        this.setState({ ...this.state, toggle: !this.state.toggle }, () => action());
    }

    setFormState(state, action) {
        // var newFd = { ...this.state.formData, ...fd };
        // this.setState({ ...this.state, formData: newFd });
        if (!action) action = () => {};
        this._formData._state = { ...this.getFormData()._state, ...state };
        this.setState({ ...this.state, toggle: !this.state.toggle }, () => action());
    }

    mergeFormData(fd, action) {
        // var newFd = { ...this.state.formData, ...fd };
        // this.setState({ ...this.state, formData: newFd });
        if (!action) action = () => {};
        this._formData = { ...this.getFormData(), ...fd };
        this.setState({ ...this.state, toggle: !this.state.toggle }, () => action());
    }

    getFormData() {
        //     return this.state.formData;
        return this._formData;
    }

    debounceInternal(field, timeout, func) {
        return () => {
            clearTimeout(field.timer);
            field.timer = setTimeout(() => {
                func();
            }, timeout);
        };
    }

    sleep(milliseconds) {
        const date = Date.now();
        let currentDate = null;
        do {
            currentDate = Date.now();
        } while (currentDate - date < milliseconds);
    }
}

const mapStateToProps = (state) => ({
    allDataLoaded: state.allDataLoaded,
    dataStore: state.dataStore,
    form: state.form,
    page: state.page,
    configs: state.configs,
    area: state.area,
    areas: state.areas,
    eventType: state.eventType,
    themeName: (state.area && state.area.config && state.area.config.settings && state.area.config.settings.theme) || 'default',
});

HubFormGenerator.contextType = ThemeContext;

export default withRouter(connect(mapStateToProps, null)(HubFormGenerator));

const FormContainer = styled.div`
    width: 100%;
    height: 100%;
`;
