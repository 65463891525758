import React from 'react';
import styled from 'styled-components';
import { substitute } from '../../services/stringsubstitutions';
import { CreateControlFromText, parseBool } from '../../services/globalMethods';
import { ThemeContext } from '../system/ThemeContext';
import { areaThemeColor } from '../../services/globalMethods';
import { deepCopy } from '../forms/formFunctions';

class ContainerExpandor extends React.Component {
    constructor(props) {
        super(props);

        this.props.field.settings = this.props.field.settings || {};
        this.state = {
            id: this.props.field.settings.id || 'expandor_' + Date.now().toString(),
        };

        if (!this.props.field.settings.id) {
            var hub = this.props.formGenerator;

            var formData = hub.getFormData();
            for (var key in formData) {
                if (key.startsWith('expandor_')) formData[key] = false;
            }

            formData[this.state.id] = true;
            hub.updateFormData(formData);
        }
    }

    render() {
        const { formGenerator, field, additional, collectionStack } = this.props;
        var { compact } = field.settings;
        const isCompact = parseBool(compact);

        var expandedKey = this.state.id;
        var formData = this.props.formGenerator.getFormData();

        var copyStack = deepCopy(collectionStack);
        copyStack.reverse();

        var expanded = true;
        var disabled = formGenerator.decode(this.props.field, this.props.field.disabled);
        if (!disabled || this.props.field.settings.expanded != null) {
            expanded =
                formData[expandedKey] != null
                    ? formData[expandedKey]
                    : formGenerator.decode(this.props.field, this.props.field.settings.expanded);
        }

        if (disabled) {
            expanded = true;
        }
        const { defaultClass } = additional;
        const columns = field.items.map((fld, sectionIndex) => {
            var newField = deepCopy(fld);
            newField.replacements = { ...field.replacements, ...fld.replacements };

            const column = formGenerator.createControls(additional.addToValidationFunc, newField, defaultClass, collectionStack);
            if (column == null) return null;
            return (
                <div key={`${sectionIndex}`} className="expander-content-items">
                    {column}
                </div>
            );
        });

        var isExpanded = disabled || formData[expandedKey];

        var self = this;
        const headerProps = disabled
            ? {}
            : {
                  onClick: () => {
                      for (var key in formData) {
                          if (key.startsWith('expandor_')) formData[key] = false;
                      }

                      var hub = self.props.formGenerator;
                      if (formData[expandedKey] == null) {
                          formData[expandedKey] = !expanded;
                      } else {
                          formData[expandedKey] = !formData[expandedKey];
                      }

                      hub.updateFormData(formData);
                      //   hub.setState({ ...hub.state, formData: formData });
                      //   self.setState({ ...this.state }); // needed?
                  },
              };

        var header = CreateControlFromText(formGenerator, field, field.header, additional);
        header = substitute(header, formGenerator.props.controller, [...copyStack, formGenerator.getFormData()]);

        var headerControl;
        if (typeof header !== 'string') {
            headerControl = header;
        } else {
            headerControl = (
                <ExpandorLabel
                    className="container-expandor-header-label"
                    theme={this.context}
                    themeName={this.props.themeName}
                    expanded={isExpanded}
                    compact={isCompact}
                    style={field.headerStyle}>
                    {header}
                </ExpandorLabel>
            );
        }

        return (
            <Expandor className="container-expandor">
                <ExpandorHeader
                    className={
                        expanded
                            ? 'container-expandor-header expanded ' + field.headerClass
                            : 'container-expandor-header' + field.headerClass
                    }
                    compact={isCompact}
                    {...headerProps}
                    theme={this.context}
                    themeName={this.props.themeName}>
                    {headerControl}
                    {!disabled && (
                        <IconContainer compact={isCompact}>
                            <Icon
                                theme={this.context}
                                themeName={this.props.themeName}
                                expanded={expanded}
                                className="fas fa-chevron-up container-expandor-icon"></Icon>
                        </IconContainer>
                    )}
                    {/* <span>{ CreateControlFromText(formGenerator,field,"#{removeApplicant}",additional)}</span> */}
                </ExpandorHeader>
                <div>
                    <ExpandorContent
                        className={expanded ? 'container-expandor-body expanded' : 'container-expandor-body'}
                        style={field.styles}>
                        <div className={`expander-content ${field.columnClass} stack`}>{columns}</div>
                    </ExpandorContent>
                </div>
            </Expandor>
        );
    }
}

ContainerExpandor.contextType = ThemeContext;

export { ContainerExpandor };

const Expandor = styled.div`
    width: 100%;
`;

const ExpandorHeader = styled.div`
    border-radius: 5px;
    background-color: ${(props) => areaThemeColor(props.theme, props.themeName)('expanderDefault')}

    padding: ${(props) => (props.compact ? ' 0px 18px 0px 18px' : '18px 90px 18px 15px;')}
    height: ${(props) => (props.compact ? '' : '60px')}
   
    cursor: pointer;
    position: relative;
    &:hover {
        background-color:  ${(props) => areaThemeColor(props.theme, props.themeName)('expanderSelected')};
        color:  ${(props) => areaThemeColor(props.theme, props.themeName)('expanderSelectedText')}
    }
    &.expanded {
        background-color:  ${(props) => areaThemeColor(props.theme, props.themeName)('expanderSelected')}
        i {
            transform: rotate(180deg);
        }
    }
`;

// font-size: 18px;

const ExpandorLabel = styled.label`
    font-weight: 400;
    color: ${(props) =>
        props.expanded
            ? areaThemeColor(props.theme, props.themeName)('expanderSelectedText')
            : areaThemeColor(props.theme, props.themeName)('expanderDefaultText')}
    text-transform: uppercase;
    pointer-events: none;
    margin-top: ${(props) => (props.compact ? '5px' : '')}
`;

const ExpandorContent = styled.div`
    max-height: 0;
    opacity: 0;
    overflow: hidden;
    transition: max-height 250ms ease-in-out, opacity 250ms ease-in-out;
    margin: 0;
    &.expanded {
        max-height: 2000px;
        opacity: 1;
    }
`;

const IconContainer = styled.div`
    position: absolute;
    top: 0;
    right: 0;
    width:${(props) => (props.compact ? 'unset' : '60px')}
    height:${(props) => (props.compact ? 'unset' : '60px')}

    padding: ${(props) => (props.compact ? '10px 25px 0 0' : '18px 18px 18px 18px')}

    display: flex;
    justify-content: center;
    align-items: center;
`;

const Icon = styled.i`
    color: ${(props) =>
        props.expanded
            ? areaThemeColor(props.theme, props.themeName)('expanderSelectedText')
            : areaThemeColor(props.theme, props.themeName)('expanderDefaultText')}
    transform: rotate(0deg);
    transition: transform 250ms ease-in-out;
`;
