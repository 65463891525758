import React from 'react';
import { buildClassName, deepCopy } from './formFunctions';
import { substitute } from '../../services/stringsubstitutions';
import { parseBool } from '../../services/globalMethods';

export const FormParagraph = ({ formGenerator, field, collectionStack }) => {
    var substitutionStack = deepCopy(collectionStack) || [];
    substitutionStack.reverse();

    var controller = formGenerator.props.controller;
    var text = substitute(field.header, controller, [...substitutionStack, this]);
    var cls = buildClassName(formGenerator, field, '', field.class, collectionStack);

    var inner = parseBool(field.settings?.html, false) ? <span dangerouslySetInnerHTML={{ __html: text }} /> : text;

    return (
        <p className={cls} style={field.styles}>
            <>{inner}</>
        </p>
    );
};
