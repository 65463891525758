import React from 'react';
import { MessageBar, MessageBarType } from 'office-ui-fabric-react';
import { substitute } from '../../services/stringsubstitutions';
import { buildClassName } from './formFunctions';

export const FormNotificationBar = ({ field, additional, collectionStack = [], ...props }) => {
    var formGenerator = additional.formGenerator;
    if (formGenerator == null) debugger;

    var key = field.id || 'notificationBar';
    var pastMessage = localStorage.getItem(key);

    var header = substitute(field.header, formGenerator.props.controller, [formGenerator.getFormData()]);

    if (pastMessage === header) return null;

    var onDismiss = () => {
        localStorage.setItem(key, header);
    };

    return (
        <MessageBar
            messageBarType={MessageBarType.warning}
            isMultiline={true}
            className={buildClassName(formGenerator, field, 'notification-bar', field.class, collectionStack)}
            onDismiss={onDismiss}
            dismissButtonAriaLabel="Close">
            <span dangerouslySetInnerHTML={{ __html: header }} />
        </MessageBar>
    );
};
