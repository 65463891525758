import React from 'react';
import { deepCopy } from '../forms/formFunctions';
import { getLayout } from '../Layouts';

export const ContainerBlank = ({ controller, config, layoutItem, collectionStack, formGenerator }) => {
    // DELETEME
    const rows = layoutItem.items.map((item) => {
        var newItem = deepCopy(item);
        newItem.data = { ...newItem.settings, item };
        var uiItem = getLayout(controller, newItem, false, false, collectionStack, null, formGenerator);
        return uiItem;
    });

    return <>{rows}</>;
};
