export const createObjectFromFormData = function (formData, path) {
    var formDataStripped = null;

    Object.keys(formData).forEach((propName) => {
        if (propName[0] === '_') return;
        if (propName === 'formIsValid') return;

        if (propName.indexOf(path) !== 0) return;

        if (formDataStripped == null) formDataStripped = {};

        var value = formData[propName];
        var formObject = formDataStripped;

        var indexOfUnderscore = propName.indexOf('_');
        var indexOfExclamation = propName.indexOf('!');

        if (indexOfUnderscore === -1 && indexOfExclamation === -1) {
            formObject[propName] = value;
            return;
        }

        while (indexOfUnderscore > -1 || indexOfExclamation > -1) {
            var hasUnderscore = indexOfUnderscore > -1;
            var hasExclamation = indexOfExclamation > -1;

            if ((hasUnderscore && !hasExclamation) || (hasUnderscore && indexOfUnderscore < indexOfExclamation)) {
                var obj = getPropertyValue(formObject, propName);
                formObject = obj.formObject;
                propName = obj.propName;
            } else {
                var obj2 = getPropertyValueCollection(formObject, propName);
                formObject = obj2.formObject;
                propName = obj2.propName;
            }

            indexOfUnderscore = propName.indexOf('_');
            indexOfExclamation = propName.indexOf('!');
        }

        formObject[propName] = value;
    });

    if (formDataStripped == null) return null;

    var name = '';
    var val = formDataStripped;
    var inArray = false;
    [...path].forEach((letter) => {
        if (val == null) return;

        if (letter === '!') {
            if (inArray) {
                inArray = false;
                name = '';
                return;
            }

            inArray = true;
            val = val[name][0];
            return;
        }

        if (inArray) return;

        if (letter === '_') {
            val = val[name];
            name = '';
            return;
        }

        name = name + letter;
    });

    return val[name];
};

export const getPropertyValue = function (formObject, propName) {
    var indexOfProp = propName.indexOf('_');
    //   while(indexOfProp > -1){// || indexOfColl > -1){

    // Get first property name from key
    var remaining = propName.substring(indexOfProp + 1);
    propName = propName.substring(0, indexOfProp);

    if (formObject[propName] == null) formObject[propName] = {};

    formObject = formObject[propName];
    propName = remaining;

    indexOfProp = propName.indexOf('_');

    return { formObject, propName };
};

export const getPropertyValueCollection = function (formObject, propName) {
    var things = propName.split('!');
    var collectionName = things[0];
    var collectionIndex = parseInt(things[1]);
    var propertyName = propName.replace(`${collectionName}!${collectionIndex}!`, '');

    if (formObject[collectionName] == null) formObject[collectionName] = [];

    var collection = formObject[collectionName];
    var prop = collection[collectionIndex];
    if (prop == null) {
        prop = {};
        collection.push(prop);
    }

    return { formObject: prop, propName: propertyName };
};

// Generic method for global serach and retrieve
export const getValueFromStoresUsingPath = function ({ formData, dataStore, session }, path) {
    if (path == null) return null;

    return path.indexOf('ds.') === 0 ? getValueFromObjectUsingPath(dataStore, path) : getValueFromObjectUsingPath(formData, path);
};

export const getValueFromObjectUsingPath = function (obj, path) {
    if (obj == null || path == null) return null;

    if (typeof path === 'object') return path;

    // allows actual values ( not those found in stores) to be used as the LHS values
    if (path.indexOf('@') === 0) {
        return path.substring(1);
    }

    if (path && path.indexOf('ds.') === 0) path = path.substring(3); // auto-correct user errors
    path.split('.').forEach((p) => {
        try {
            if (obj == null) return null;
            if (p === '') {
                return obj;
            }

            var lhs = p;
            var bracket = lhs.indexOf('[');
            if (bracket > -1) {
                lhs = lhs.substring(0, bracket);
            }

            obj = obj[lhs];

            if (bracket > -1) {
                if (p.indexOf('=') > -1) {
                    var str = p.substring(bracket + 1);
                    str = str.replace(lhs + '[', '').replace(']', '');
                    var split = str.split('=');

                    if (!Array.isArray(obj)) debugger; // needs to be an array
                    obj = obj.find((item) => item[split[0]] === split[1]);
                    return;
                }

                var indexString = p.replace(lhs + '[', '').replace(']', '');
                var index = parseInt(indexString);
                obj = obj[index];
            }
        } catch (exception) {
            debugger;
            throw exception;
        }
    });

    return obj;
};
