import { deepCopy } from '../controls/forms/formFunctions';
import { getValueFromObjectUsingPath } from './formDataServices';
import { substitute } from './stringsubstitutions';

export const objectMapper = function (controller, object, mapping) {
    if (!mapping) return object;

    var array = mapping.property ? getValueFromObjectUsingPath(object, mapping.property) : object;
    var items = [];
    array.forEach((element) => {
        var map = deepCopy(mapping.map);
        map = substitute(map, controller, [element]);
        var mappedObject = {};

        Object.keys(map).forEach((key) => {
            mappedObject[key] = getValueFromObjectUsingPath(element, map[key]);
        });
        items.push(mappedObject);
    });

    if (!mapping.property) return items;

    var retVal = {};
    retVal[mapping.property] = items;

    return retVal;
};
