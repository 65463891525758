import {
    //     SET_AREA,
    //     ADD_AREAS,
    //     PAGE_CHANGED,
    UPDATE_PAGE,
    //     UPDATE_AREA,
    //     UPDATE_CURRENT_PAGE,
    //     UPDATE_MAIN_MENU,
    //     UPDATE_TITLE,
    //     UPDATE_SIDE_MENU,
    //     UPDATE_BREADCRUMBS,
    //     UPDATE_COMMANDBAR,
    //     UPDATE_TABLE,
    //     UPDATE_SELECTED_ITEMS,
    //     UPDATE_CUSTOM_AREA
} from './types';

import { Diagnostics } from '../services/services';

export const ALERT = { INFO: 0, WANRING: 1, ERROR: 2 };

export function showAlert(type, message, title = null) {
    return {
        type: 'SET_ALERT',
        payload: {
            eventType: 'SET_ALERT',
            type,
            title,
            message,
        },
    };
}

export function showModalSpinner(text = null) {
    return {
        type: 'SHOW_MODAL_SPINNER',
        payload: { text },
    };
}

export function RedrawPage() {
    return {
        type: 'REDRAW_PAGE',
        payload: {},
    };
}

export function hideModalSpinner() {
    return {
        type: 'HIDE_MODAL_SPINNER',
        payload: {},
    };
}

export function hideAlert() {
    return {
        type: 'HIDE_ALERT',
        payload: {
            eventType: 'HIDE_ALERT',
        },
    };
}

export function setDashboardProperties(properties) {
    return {
        type: 'SET_DASHBOARD_PROPERTIES',
        payload: {
            eventType: 'SET_DASHBOARD_PROPERTIES',
            properties,
        },
    };
}
export function SetIntellisense(intellisense) {
    return {
        type: 'SET_INTELLISENSE',
        payload: {
            eventType: 'SET_INTELLISENSE',
            intellisense,
        },
    };
}

export function updateConfigFile(name, contents) {
    return {
        type: 'UPDATE_CONFIGS',
        payload: {
            eventType: 'UPDATE_CONFIGS',
            name,
            contents,
        },
    };
}

export function portalInitialized(areas, configs, area, cssFiles) {
    return {
        type: 'PORTAL_INITIALISED',
        payload: {
            eventType: 'PORTAL_INITIALISED',
            areas: areas,
            configs: configs,
            area,
            cssFiles,
        },
    };
}

export function updateForm(form) {
    return {
        type: 'UPDATE_FORM',
        payload: { eventType: 'UPDATE_FORM', form: form },
    };
}

export function clearForm() {
    return {
        type: 'UPDATE_FORM',
        payload: { eventType: 'UPDATE_FORM', form: null },
    };
}

export function confirmForm(confirm) {
    return {
        type: 'CONFIRM_FORM',
        payload: { eventType: 'CONFIRM_FORM', confirm: confirm },
    };
}

export function AllowFormRedraw() {
    return {
        type: 'ALLOW_FORM_REDRAW',
        payload: { eventType: 'ALLOW_FORM_REDRAW', stopFormGeneratorUpdating: false },
    };
}

export function setDataStore(controller, dataStore, clean = false, mergeResult = false) {
    Diagnostics.notNull(controller, 'controller');
    Diagnostics.notNull(dataStore, 'dataStore');

    if (dataStore == null)
        console.log('SetDataStore value is null . have you missed passing the controller as the first parameter?');
    var currentUrl = controller.props.location.pathname + controller.props.location.search;
    var page = controller.props.page;

    return {
        type: 'SET_DATASTORE',
        payload: {
            eventType: 'SET_DATASTORE',
            currentUrl: currentUrl,
            page,
            dataStore,
            clean,
            stopFormGeneratorUpdating: true,
            mergeResult,
        },
    };
}
export function setDataStoreFromGrabber(currentUrl, dataStore, clean = true) {
    return {
        type: 'SET_DATASTORE',
        payload: { eventType: 'SET_DATASTORE', currentUrl: currentUrl, dataStore, clean, stopFormGeneratorUpdating: true },
    };
}

export function setDataStoreFromGrabber2(currentUrl, dataStore, clean, stillLoading) {
    return {
        type: 'SET_DATASTORE',
        payload: {
            eventType: 'SET_DATASTORE',
            currentUrl: currentUrl,
            dataStore,
            clean,
            stillLoading,
            stopFormGeneratorUpdating: true,
        },
    };
}

export function allDataLoaded(allDataLoaded = true) {
    return {
        type: 'ALL_DATA_LOADED',
        payload: { eventType: 'ALL_DATA_LOADED', allDataLoaded, state: { allDataLoaded: allDataLoaded } },
    };
}

export function setPage(page) {
    return {
        type: 'SET_PAGE',
        payload: { eventType: 'SET_PAGE', page },
    };
}

export function cleanDataStore(page) {
    return {
        type: 'CLEAN_DATASTORE',
        payload: { eventType: 'CLEAN_DATASTORE', page },
    };
}

export function setError(error, title) {
    return {
        type: 'SET_ERROR',
        payload: { eventType: 'SET_ERROR', error, title },
    };
}

export function pageChanged(currentPage, clearDataStore = false) {
    return {
        type: 'PAGE_CHANGED',
        payload: { currentPage: currentPage, clearDataStore, allDataLoaded: false },
    };
}

export function setSelectedItems(items) {
    return {
        type: 'SET_SELECTED_ITEMS',
        payload: { items },
    };
}

export function updateResource(settings) {
    return {
        type: 'UPDATE_RESOURCES',
        payload: { settings },
    };
}

export function renderPage() {
    return {
        type: 'RENDER_PAGE',
        payload: {},
    };
}

export function updatePageItems(area, page) {
    return {
        type: UPDATE_PAGE,
        payload: { eventType: UPDATE_PAGE, area: area, page: page },
    };
}
